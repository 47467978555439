import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Button, CssBaseline, Grid, Paper, TextField, Typography} from '@material-ui/core';
import {AppBar, DrawerMenu} from '..';
import i18n from '../../i18n/i18n';
import {ErrorView, RoleList, SnackBarMessage, SuccessView} from '../../components';
import {Connector} from '../../helpers/Connector';
import {appBar} from '../../styles';
import {SnackBarType} from '../../components/SnackBarMessage';
import {errorMessages} from '../../helpers/types';

const styles = theme => ({
    root: {
        display: 'flex'
    },
    content: {
        marginTop: theme.spacing(3),
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    paper: {
        width: '35%',
        marginTop: appBar.height,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2),
    },
});

class UserCreation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: '',
            firstNameError: false,
            lastName: '',
            lastNameError: false,
            errorList: [],
            coach: null,
            requestFailed: false,
            failMessage: '',
            isMenuOpen: false,
        };
    }

    componentDidMount() {
        if (!this.props.token.access) {
            this.props.history.push('/login');
        }
    }

    handleLogout = () => {
        this.props.history.push('/login');
    };

    handleFirstNameChange = e => this.setState({firstName: e.target.value});
    handleLastNameChange = e => this.setState({lastName: e.target.value});

    handleFirstNameFocus = () => this.setState({firstNameError: false});
    handleLastNameFocus = () => this.setState({lastNameError: false});

    isFirstNameValid = () => (/^[a-zA-Z0-9\u00C0-\u024F\u1E00-\u1EFF \-'.(),]+$/g.test(this.state.firstName));
    isLastNameValid = () => (/^[a-zA-Z0-9\u00C0-\u024F\u1E00-\u1EFF \-'.(),]+$/g.test(this.state.lastName));

    handleFirstNameBlur = () => this.setState({firstNameError: !this.isFirstNameValid()});
    handleLastNameBlur = () => this.setState({lastNameError: !this.isLastNameValid()});

    handleCancelClick = () => this.props.history.push('/home');
    handleSubmitClick = () => {
        const {firstName, lastName} = this.state;
        let errorList = [];

        if (!this.isFirstNameValid()) {
            this.setState({firstNameError: true});
            errorList.push('FIRST_NAME_ERROR');
        }
        if (!this.isLastNameValid()) {
            this.setState({lastNameError: true});
            errorList.push('LAST_NAME_ERROR');
        }
        const selectedRoles = this.roleList.getSelectedRoles();
        if (selectedRoles.length === 0) {
            errorList.push('NO_ROLE_CHOSEN');
        }
        if (errorList.length === 0) {
            this.setState({requestFailed: false, failMessage: ''}, () => {
                Connector.getInstance().createCoach(firstName, lastName, selectedRoles)
                    .then(coach => {
                        if (coach && !errorMessages.includes(coach))
                            this.setState({coach: coach});
                        else
                            this.setState({failMessage: coach || 'UNKNOWN_ERROR', requestFailed: true});
                    })
                    .catch(() => {
                        errorList.push('UNKNOWN_ERROR');
                        this.setState({errorList: errorList});
                    });
            });
        }
        this.setState({errorList: errorList});
    };

    handleRequestFailed = (message) => this.setState({requestFailed: true, failMessage: message});

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                <CssBaseline/>
                <AppBar login={this.props.login} onBurgerClick={() => {
                    this.setState({isMenuOpen: !this.state.isMenuOpen});
                }}/>
                <DrawerMenu isOpen={this.state.isMenuOpen}/>
                <main className={classes.content}>
                    <Paper className={classes.paper}>
                        <Typography component="h3" variant="h4">
                            {i18n.t('USER_CREATION_TITLE')}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="firstName"
                                    label={i18n.t('FIRST_NAME_FIELD')}
                                    value={this.state.firstName}
                                    onChange={this.handleFirstNameChange}
                                    error={this.state.firstNameError}
                                    onFocus={this.handleFirstNameFocus}
                                    onBlur={this.handleFirstNameBlur}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="lastName"
                                    label={i18n.t('LAST_NAME_FIELD')}
                                    value={this.state.lastName}
                                    onChange={this.handleLastNameChange}
                                    error={this.state.lastNameError}
                                    onFocus={this.handleLastNameFocus}
                                    onBlur={this.handleLastNameBlur}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography component="h5" variant="h6">
                                    {i18n.t('ROLE_CHOICE_TITLE')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <RoleList
                                    logout={this.handleLogout} ref={ref => this.roleList = ref}
                                    onRequestFailed={this.handleRequestFailed}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button variant="contained" color="default" onClick={this.handleCancelClick} fullWidth>
                                    {i18n.t('CANCEL_BUTTON')}
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.gridItem}>
                                <Button variant="contained" color="primary" onClick={this.handleSubmitClick} fullWidth>
                                    {i18n.t('SUBMIT_BUTTON')}
                                </Button>
                            </Grid>
                        </Grid>
                        <ErrorView errorList={this.state.errorList}/>
                        <SuccessView shown={this.state.coach} coach={this.state.coach}/>
                        <SnackBarMessage
                            content={i18n.t(this.state.failMessage)}
                            type={SnackBarType.ERROR} isOpen={this.state.requestFailed}/>
                    </Paper>
                </main>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {token, login} = state.login;
    return {token, login};
};

export default withStyles(styles)(withRouter(connect(mapStateToProps, null)(UserCreation)));
