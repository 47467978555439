import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Container, CssBaseline, Typography, TextField, FormControlLabel, Button, Checkbox} from '@material-ui/core';
import {CheckBoxOutlineBlank} from '@material-ui/icons';
import {connect} from 'react-redux';
import {login} from '../redux/login/actions';
import i18n from '../i18n/i18n';
import {SnackBarMessage} from '../components';
import {SnackBarType} from '../components/SnackBarMessage';

const styles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            login: '',
            password: '',
            loginError: false,
            passwordError: false,
            remember: true,
        };
    }

    componentDidMount() {
        document.addEventListener('keyup', e =>
            e.code === 'Enter' && this.handleLoginSubmit()
        );
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.request.requested && this.props.request.succeeded) {
            this.props.handleLoggedIn();
        }
    };

    handleLoginChange = evt => {
        this.setState({login: evt.target.value});
    };

    handleLoginBlur = () => {
        this.setState({loginError: !this.isLoginValid()});
    };

    isLoginValid = () => {
        const {login} = this.state;
        return login !== '';
    };

    handlePasswordChange = evt => {
        this.setState({password: evt.target.value});
    };

    handlePasswordBlur = () => {
        this.setState({passwordError: !this.isPasswordValid()});
    };

    isPasswordValid = () => {
        const {password} = this.state;
        return password !== '';
    };

    handleRememberChange = evt => {
        this.setState({remember: evt.target.checked});
    };

    handleLoginSubmit = () => {
        let error = false;
        this.setState({
            loginError: !this.isLoginValid(),
            passwordError: !this.isPasswordValid(),
        }, () => {
            if (!error) {
                this.props.requestLogin(this.state.login, this.state.password);
            }
        });
    };

    render() {
        const {classes} = this.props;
        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <div className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        {i18n.t('LOGIN_TITLE')}
                    </Typography>
                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="login"
                            label={i18n.t('USERNAME_PLACEHOLDER')}
                            name="login"
                            autoComplete="username"
                            autoFocus
                            placeholder={i18n.t('USERNAME_PLACEHOLDER')}
                            value={this.state.login}
                            onChange={this.handleLoginChange}
                            onBlur={this.handleLoginBlur}
                            error={this.state.loginError}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={i18n.t('PASSWORD_PLACEHOLDER')}
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            placeholder={i18n.t('PASSWORD_PLACEHOLDER')}
                            value={this.state.password}
                            onChange={this.handlePasswordChange}
                            onBlur={this.handlePasswordBlur}
                            error={this.state.passwordError}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={this.state.remember} value="remember"
                                icon={<CheckBoxOutlineBlank color="primary"/>}
                                color="primary" onChange={this.handleRememberChange}/>}
                            label="Remember me"
                        />
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={this.handleLoginSubmit}
                            disabled={this.props.request.requested || this.props.request.succeeded}
                        >
                            {i18n.t('LOGIN_TITLE')}
                        </Button>
                    </form>
                </div>
                <SnackBarMessage content={i18n.t('LOGIN_FAILED')} type={SnackBarType.ERROR}
                    isOpen={this.props.request.failed}/>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    const {login, password, request, token} = state.login;
    return {login, password, request, token};
};

const mapDispatchToProps = dispatch => ({
    requestLogin: (loginValue, password) => dispatch(login(loginValue, password))
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(LoginForm));
