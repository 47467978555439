import {Role, Token} from '../../helpers/responseTypes';

export type RequestState = {
    requested: boolean;
    succeeded: boolean;
    failed: boolean;
    failMessage: string;
}

export type LoginState = {
    login: string,
    password: string,
    isAdmin: boolean,
    isCoach: boolean,
    isSectionManager: boolean,
    token: Token,
    roles: Role[],
    request: RequestState,
}

export enum LOGIN_ACTIONS {
    LOGIN_REQUEST = 'LOGIN_REQUEST',
    LOGIN_SUCCESS = 'LOGIN_SUCCESS',
    LOGIN_FAILURE = 'LOGIN_FAILURE',
    LOGOUT = 'LOGOUT',
}

export type LoginRequest = {
    type: LOGIN_ACTIONS
    login: string,
    password: string,
}

export type LoginSuccess = {
    type: LOGIN_ACTIONS,
    token: Token,
    roles: Role[],
}

export type LoginFailure = {
    type: LOGIN_ACTIONS,
    failMessage: string,
}

export type LogoutRequest = {
    type: LOGIN_ACTIONS,
}

export type LoginActions =
    LoginRequest
    | LoginSuccess
    | LoginFailure
    | LogoutRequest
