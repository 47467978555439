import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Modal, Typography, Button} from '@material-ui/core';
import i18n from '../i18n/i18n';

const styles = theme => ({
    root: {
        position: 'absolute',
        width: 400,
        height: 200,
        top: '50%',
        marginTop: -100,
        left: '50%',
        marginLeft: -200,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 4),
        display: 'flex',
        flexDirection: 'column',
        outline: 0,
    },
    title: {
        width: '100%',
        flex: 0.3,
    },
    message: {
        width: '100%',
        flex: 0.4
    },
    buttonView: {
        width: '100%',
        flex: 0.3,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
});

class ConfirmationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
    }

    handleClose = () => {
        this.setState({open: false});
    };

    open = () => {
        this.setState({open: true});
    };

    handleConfirm = () => {
        this.setState({open: false});
        this.props.onConfirm();
    };

    render() {
        const {classes} = this.props;
        return (
            <Modal open={this.state.open} onClose={this.handleClose}>
                <div className={classes.root}>
                    <Typography component="h3" variant="h6" className={classes.title}>
                        {i18n.t('CONFIRMATION_TITLE')}
                    </Typography>
                    <p className={classes.message}>
                        {this.props.message}
                    </p>
                    <div className={classes.buttonView}>
                        <Button variant="contained" color="default" onClick={this.handleClose}>
                            {i18n.t('CANCEL_BUTTON')}
                        </Button>
                        <Button variant="contained" color="primary" onClick={this.handleConfirm}>
                            {i18n.t('SUBMIT_BUTTON')}
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default withStyles(styles)(ConfirmationModal);