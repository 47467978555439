import React from 'react';
import {
    CircularProgress,
    Container,
    CssBaseline,
    Grid,
    TextField,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';

import i18n from '../i18n/i18n';
import ErrorView from './ErrorView';

const styles = theme => ({
    form: {
        width: '100%', // Fix IE 11 issue (apparently)
        marginBottom: theme.spacing(3)
    },
});

class StudentAddressForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            address: this.props.address || '',
            addressComp: this.props.addressComp || '',
            city: this.props.city || '',
            zipCode: this.props.zipCode || '',
            country: this.props.country || '',
            addressError: false,
            addressCompError: false,
            cityError: false,
            zipCodeError: false,
            countryError: false,
            errorList: [],
            loaded: false,
        };
    }

    componentDidMount() {
        if (!this.state.loaded && Object.keys(this.props.data).length !== 0) {
            this.setState({
                address: this.props.data.subscription.address || '',
                city: this.props.data.subscription.city || '',
                zipCode: this.props.data.subscription.zipCode || '',
                country: this.props.data.subscription.country || '',
                loaded: true});
        }
    }

    isAddressValid = () => /^[a-zA-Z0-9\u00C0-\u024F\u1E00-\u1EFF \-'.(),]+$/.test(this.state.address);
    isAddressCompValid = () =>
        /^[a-zA-Z0-9\u00C0-\u024F\u1E00-\u1EFF \-'.(),]+$/
            .test(this.state.addressComp) || this.state.addressComp === '';
    isCityValid = () => /^[a-zA-Z0-9\u00C0-\u024F\u1E00-\u1EFF \-'.(),]+$/.test(this.state.city);
    isZipCodeValid = () => /^[0-9]{5}$/.test(this.state.zipCode);
    isCountryValid = () => /^[a-zA-Z0-9\u00C0-\u024F\u1E00-\u1EFF \-'.(),]+$/.test(this.state.country);

    handleAddressChange = e => this.setState({address: e.target.value});
    handleAddressCompChange = e => this.setState({addressComp: e.target.value});
    handleCityChange = e => this.setState({city: e.target.value});
    handleZipCodeChange = e => this.setState({zipCode: e.target.value});
    handleCountryChange = e => this.setState({country: e.target.value});

    handleAddressBlur = () => this.setState({addressError: !this.isAddressValid()});
    handleAddressCompBlur = () => this.setState({addressCompError: !this.isAddressCompValid()});
    handleCityBlur = () => this.setState({cityError: !this.isCityValid()});
    handleZipCodeBlur = () => this.setState({zipCodeError: !this.isZipCodeValid()});
    handleCountryBlur = () => this.setState({countryError: !this.isCountryValid()});

    submitContent = () => {
        const {address, addressComp, city, zipCode, country} = this.state;
        let errorList = [];
        if (this.isAddressValid()
            && this.isAddressCompValid()
            && this.isCityValid()
            && this.isZipCodeValid()
            && this.isCountryValid()) {
            return {address, addressComp, city, zipCode, country};
        }
        if (!this.isAddressValid()) {
            this.setState({addressError: true});
            errorList.push('ADDRESS_ERROR');
        }
        if (!this.isAddressCompValid()) {
            this.setState({addressCompError: true});
            errorList.push('ADDRESS_COMP_ERROR');
        }
        if (!this.isCityValid()) {
            this.setState({cityError: true});
            errorList.push('CITY_ERROR');
        }
        if (!this.isZipCodeValid()) {
            this.setState({zipCodeError: true});
            errorList.push('ZIP_CODE_ERROR');
        }
        if (!this.isCountryValid()) {
            this.setState({countryError: true});
            errorList.push('COUNTRY_ERROR');
        }
        this.setState({errorList: errorList});
        return null;
    };

    render() {
        const {classes} = this.props;

        return (
            <Container component="main" maxWidth="sm">
                <CssBaseline/>
                {this.state.loaded ? <form className={classes.form} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                name="addressLine1"
                                required
                                fullWidth
                                id="addressLine1"
                                label={i18n.t('ADDRESS_FIELD')}
                                value={this.state.address}
                                onChange={this.handleAddressChange}
                                onBlur={this.handleAddressBlur}
                                error={this.state.addressError}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="addressLine2"
                                fullWidth
                                id="addressLine2"
                                label={i18n.t('ADDRESS_FIELD')}
                                value={this.state.addressComp}
                                onChange={this.handleAddressCompChange}
                                onBlur={this.handleAddressCompBlur}
                                error={this.state.addressCompError}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="city"
                                required
                                fullWidth
                                id="city"
                                label={i18n.t('CITY_FIELD')}
                                value={this.state.city}
                                onChange={this.handleCityChange}
                                onBlur={this.handleCityBlur}
                                error={this.state.cityError}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                name="zipCode"
                                required
                                fullWidth
                                id="zipCode"
                                label={i18n.t('ZIPCODE_FIELD')}
                                value={this.state.zipCode}
                                onChange={this.handleZipCodeChange}
                                onBlur={this.handleZipCodeBlur}
                                error={this.state.zipCodeError}
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <TextField
                                name="country"
                                required
                                fullWidth
                                id="country"
                                label={i18n.t('COUNTRY_FIELD')}
                                value={this.state.country}
                                onChange={this.handleCountryChange}
                                onBlur={this.handleCountryBlur}
                                error={this.state.countryError}
                            />
                        </Grid>
                    </Grid>
                    <ErrorView errorList={this.state.errorList}/>
                </form> : <CircularProgress/>}
            </Container>
        );
    }
}

export default withStyles(styles)(StudentAddressForm);
