import React, {ChangeEvent, FunctionComponent, useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {appBar} from '../../styles';
import {
    Button,
    CircularProgress,
    CssBaseline,
    Grid,
    IconButton,
    MenuItem,
    Paper,
    Select,
    TextField,
    Theme,
    Typography
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {AppState, CoachResponse, errorMessages, Token} from '../../helpers/types';
import {GroupSummary, SectionDetailsData} from '../../helpers/responseTypes';
import AppBar from '../AppBar';
import DrawerMenu from '../DrawerMenu';
import {Connector} from '../../helpers/Connector';
import i18n from '../../i18n/i18n';
import {createStyles} from '@material-ui/styles';
import {AddCircleOutlineOutlined, RemoveCircleOutlineOutlined} from '@material-ui/icons';
import {SnackBarType} from '../../components/SnackBarMessage';
import {SnackBarMessage} from '../../components';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
    },
    buttonView: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    button: {
        margin: theme.spacing(2)
    },
    content: {
        flex: 1,
        width: '100%',
        marginTop: appBar.height,
        paddingTop: theme.spacing(2),
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        flex: 1,
        marginTop: appBar.height,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(2),
    },
    input: {
        width: '90%',
        flex: 0.2,
        marginTop: 5,
        marginBottom: 5,
    },
    groupList: {
        width: '90%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        textAlignVertical: 'center',
    },
    groupName: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        textAlignVertical: 'center',
    },
    addIcon: {
        flex: 0.2
    },
    addCoachDiv: {
        flex: 0.3,
        width: '90%',
        display: 'flex',
        flexDirection: 'row',
    },
    buttonRow: {
        display: 'flex',
        flexDirect: 'row',
        width: '90%',
    },
    managerSelect: {
        padding: theme.spacing(2),
    }
});

type StateProps = {
    token: Token;
    login: string;
};

type Props = {
    classes: { [key: string]: string };
} & StateProps & RouteComponentProps;

const SectionEdit: FunctionComponent<Props> = ({history, token, login, classes, location}: Props) => {
    const [sectionId] = useState(parseInt(location.pathname.replace(/\/section\/([0-9]+)/, '$1')));
    const [sectionDetails, setSectionDetails] = useState<SectionDetailsData | null>(null);
    const [allCoaches, setAllCoaches] = useState<CoachResponse[]>([]);
    const [groups, setGroups] = useState<GroupSummary[]>([]);
    const [isWorking, setIsWorking] = useState(false);
    const [failed, setFailed] = useState(false);
    const [succeeded, setSucceeded] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [failMessage, setFailMessage] = useState('');
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(false);

    const [manager, setManager] = useState<CoachResponse | null>(null);

    const [groupToAdd, setGroupToAdd] = useState(0);

    useEffect(() => {
        if (!token.access) {
            history.push('/login');
        }
    }, [history, token.access]);

    useEffect(() => {
        if (allCoaches) {
            Connector.getInstance().getAllGroupsSummaries()
                .then(response => {
                    if (!errorMessages.includes(response as string)) {
                        setGroups(response as GroupSummary[]);
                        setGroupToAdd((response as GroupSummary[])[0].id);
                        setIsWorking(false);
                    }
                });
        }
    }, [allCoaches]);

    useEffect(() => {
        if (sectionDetails) {
            setName(sectionDetails.name);
            Connector.getInstance().getAllCoaches()
                .then(coachResponse => {
                    if (!errorMessages.includes(coachResponse as string)) {
                        setAllCoaches(coachResponse as CoachResponse[]);
                        setManager((coachResponse as CoachResponse[])
                            .filter(coach => coach.id === sectionDetails.manager.id)[0]);
                    } else {
                        setFailed(true);
                        setFailMessage(coachResponse as string);
                    }
                });
        }
    }, [sectionDetails]);

    const handleNameBlur = () => setNameError(!name);

    const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => setName(e.currentTarget.value);

    const handleManagerChange = (e: ChangeEvent<{ name?: string | undefined; value: unknown; }>): void => {
        setManager(allCoaches.filter(coach => coach.id === (e.target.value as number))[0]);
    };

    const handleGroupToAddChange = (e: ChangeEvent<{ name?: string | undefined; value: unknown; }>): void => {
        setGroupToAdd(e.target.value as number);
    };

    const fetchSectionDetails = useRef(() => {
        setIsWorking(true);
        Connector.getInstance().getSectionDetails(sectionId)
            .then(response => {
                if (!errorMessages.includes(response as string)) {
                    setSectionDetails(response as SectionDetailsData);
                } else {
                    setFailed(true);
                    setFailMessage(response as string);
                    setIsWorking(false);
                }
            });
    });
    useEffect(() => {
        fetchSectionDetails.current();
    }, []);

    const handleRemoveClick = (id: number) => {
        if (sectionDetails) {
            Connector.getInstance().moveGroup(id, 0)
                .then((response) => {
                    if (!errorMessages.includes(response as string)) {
                        setSectionDetails(Object.assign({}, sectionDetails, {
                            groups: sectionDetails.groups.filter(group => group.id !== id),
                        }));
                        setSuccessMessage('SECTION_REMOVE_GROUP_SUCCESS');
                        setSucceeded(true);
                        setFailed(false);
                    }
                    else {
                        setFailMessage('SECTION_REMOVE_GROUP_FAILURE');
                        setFailed(true);
                        setSucceeded(false);
                    }
                });
        }
    };

    const handleAddClick = () => {
        if (sectionDetails) {
            setIsWorking(true);
            Connector.getInstance().moveGroup(groupToAdd, sectionDetails.id)
                .then(response => {
                    if (!errorMessages.includes(response as string)) {
                        fetchSectionDetails.current();
                    }
                });
        }
    };

    const handleCancelClick = () => {
        history.goBack();
    };

    const handleSubmitClick = () => {
        if (sectionDetails && manager) {
            Connector.getInstance().patchSection(sectionDetails.id, name, manager.id)
                .then(response => {
                    if (errorMessages.includes(response as string)) {
                        setFailed(true);
                        setFailMessage(response as string);
                    } else {
                        setSucceeded(true);
                        setSuccessMessage('UPDATE_SECTION_SUCCESS');
                        setTimeout(() => {
                            history.goBack();
                        }, 5000);
                    }
                });
        }
    };

    return (<div className={classes.root}>
        <CssBaseline/>
        <AppBar login={login} currentRoute={location.pathname} onBurgerClick={() => {
            setIsMenuOpen(!isMenuOpen);
        }}/>
        <DrawerMenu isOpen={isMenuOpen}/>
        <main className={classes.content}>
            {!isWorking && sectionDetails && allCoaches && manager
                ? <Paper className={classes.paper}>
                    <Typography component="h3" variant="h6">
                        {i18n.t('SECTION_EDIT_TITLE')}
                    </Typography>
                    <TextField
                        className={classes.input}
                        variant="outlined"
                        required
                        fullWidth
                        id="name"
                        label={i18n.t('SECTION_NAME_LABEL')}
                        name="name"
                        placeholder={i18n.t('SECTION_NAME_LABEL')}
                        value={name}
                        onChange={handleNameChange}
                        onBlur={handleNameBlur}
                        error={nameError}
                    />
                    <Grid container spacing={2} style={{width: '90%'}}>
                        <Grid item xs={6} sm={3}>
                            <Typography component="h4" variant="h6">{i18n.t('SECTION_MANAGER_TITLE')}</Typography>
                            <Select
                                value={manager.id}
                                onChange={handleManagerChange}
                                className={classes.managerSelect}
                            >
                                {allCoaches.map(coach => <MenuItem key={coach.id} value={coach.id}>
                                    {coach.firstName} {coach.lastName}
                                </MenuItem>)}
                            </Select>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{width: '90%'}}>
                        <Grid item xs={12} sm={12}>
                            <Typography component="h4" variant="h6">{i18n.t('GROUPS_DEFINITION')}</Typography>
                            <Grid container spacing={2} className={classes.groupList}>
                                {sectionDetails.groups.map(group =>
                                    <Grid item xs={6} sm={3} className={classes.groupName} key={group.id}>
                                        <Typography>
                                            {group.name}
                                        </Typography>
                                        {sectionDetails.name !== 'Aucune'
                                            ? <IconButton onClick={() => {
                                                handleRemoveClick(group.id);
                                            }}>
                                                <RemoveCircleOutlineOutlined/>
                                            </IconButton>
                                            : null}
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{width: '90%'}}>
                        <Grid item xs={6} sm={3}>
                            <Typography component="h4" variant="h6">{i18n.t('ADD_GROUP_TITLE')}</Typography>
                            <Grid container spacing={2} style={{width: '100%'}}>
                                <Grid item xs={12} sm={6}>
                                    <Select
                                        value={groupToAdd}
                                        onChange={handleGroupToAddChange}
                                        className={classes.managerSelect}
                                    >
                                        {groups.filter(group =>
                                            !sectionDetails.groups
                                                .map(sectionGroup => sectionGroup.id).includes(group.id))
                                            .map(group => <MenuItem key={group.id} value={group.id}>
                                                {group.name}
                                            </MenuItem>)}
                                    </Select>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <IconButton className={classes.addIcon} onClick={handleAddClick}>
                                        <AddCircleOutlineOutlined/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div className={classes.buttonView}>
                        <Button
                            variant="contained"
                            color="default"
                            className={classes.button}
                            onClick={handleCancelClick}
                        >
                            {i18n.t('CANCEL_BUTTON')}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={handleSubmitClick}
                        >
                            {i18n.t('SUBMIT_BUTTON')}
                        </Button>
                    </div>
                </Paper>
                : <Paper className={classes.paper}>
                    <CircularProgress/>
                </Paper>}
        </main>
        <SnackBarMessage
            content={succeeded
                ? i18n.t(successMessage)
                : i18n.t(failMessage)} type={succeeded
                ? SnackBarType.SUCCESS : SnackBarType.ERROR
            } isOpen={succeeded || failed}/>
    </div>);
};

const mapStateToProps = (state: AppState): StateProps => {
    const {token, login} = state.login;
    return {token, login};
};

export default withStyles(styles)(withRouter(connect(mapStateToProps, null)(SectionEdit)));
