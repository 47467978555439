import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {FormControl, Grid, InputLabel, List, ListItem, MenuItem, Select} from '@material-ui/core';
import i18n from '../i18n/i18n';
import {Connector} from '../helpers/Connector';

const styles = theme => ({
    groupList: {
        display: 'flex',
        flexDirection: 'row',
        maxWidth: '100%',
        overflow: 'auto',
    },
    groupName: {
        minWidth: '25%',
        marginRight: theme.spacing(1),
        height: '100%',
        minHeight: '100%',
        padding: 'auto',
        textAlign: 'center',
        border: '1px solid black',
        borderRadius: 4,
    },
    formSelect: {
        width: '100%'
    }
});

class PossibleGroupList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sectionList: [],
            selectedSection: 0,
            groups: [],
            selectedGroups: [],
        };
    }

    componentDidMount() {
        const connector = Connector.getInstance();
        connector.getSections().then(sections => {
            if (sections !== undefined && sections !== 'UNKNOWN_ERROR') {
                connector.getGroups(sections[0].id).then(groups => {
                    if (groups && !['BAD_REQUEST', 'UNKNOWN_ERROR'].includes(groups))
                        this.setState({sectionList: sections, selectedSection: sections[0].id, groups: groups});
                    else
                        this.props.onRequestFailed(groups || 'UNKNOWN_ERROR');
                }).catch(e => e.message === 'LOGOUT' && this.props.logout());
            } else {
                this.props.onRequestFailed(sections || 'UNKNOWN_ERROR');
            }
        });
    }

    renderSections = () => {
        return this.state.sectionList.map(item => (<MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>));
    };

    fetchGroups = () => {
        const connector = Connector.getInstance();
        connector.getGroups(this.state.selectedSection).then(groups => {
            if (groups && !['BAD_REQUEST', 'UNKNOWN_ERROR'].includes(groups))
                this.setState({groups: groups});
            else
                this.props.onRequestFailed(groups || 'UNKNOWN_ERROR');
        }).catch(e => e === 'LOGOUT' && this.props.logout());
    };

    handleSectionChange = e => {
        return this.setState({selectedSection: e.target.value}, () => {
            this.fetchGroups();
        });
    };

    setPossibleGroups = groups => {
        this.setState({selectedGroups: groups});
    };

    renderGroupList = () => {
        return this.state.groups.map(item => (
            <ListItem
                button selected={this.state.selectedGroups.includes(item.id)} key={item.id}
                className={this.props.classes.groupName} onClick={() => {
                    this.toggleSelected(item.id);
                }}>{item.name}</ListItem>));
    };

    getSelectedGroups = () => this.state.selectedGroups;

    toggleSelected = id => {
        let newSelectedGroups;
        if (this.state.selectedGroups.includes(id)) {
            newSelectedGroups = this.state.selectedGroups.filter(item => id !== item);
        } else {
            newSelectedGroups = this.state.selectedGroups.concat([id]);
        }

        this.setState({selectedGroups: newSelectedGroups});
    };

    render() {
        const {classes} = this.props;
        return (
            <React.Fragment>
                <Grid item xs={12} sm={12}>
                    <FormControl className={classes.formSelect}>
                        <InputLabel htmlFor="maxBirthYear">{i18n.t('SECTION_LABEL')}</InputLabel>
                        <Select
                            value={this.state.selectedSection}
                            onChange={this.handleSectionChange}
                            inputProps={{
                                name: 'section',
                                id: 'section'
                            }}
                            fullWidth
                        >
                            {this.renderSections()}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <List className={classes.groupList}>
                        {this.renderGroupList()}
                    </List>
                </Grid>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(PossibleGroupList);
