import React, {FunctionComponent, ReactElement, useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {appBar} from '../../styles';
import {CircularProgress, Container, CssBaseline, Theme} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {AllGroupsBySection, AppState, errorMessages, Token} from '../../helpers/types';
import {AllGroupsResponse, GroupOverView} from '../../helpers/responseTypes';
import AppBar from '../AppBar';
import DrawerMenu from '../DrawerMenu';
import {SHOW_ALL_GROUPS_ROUTE} from './routes';
import {Connector} from '../../helpers/Connector';
import SectionOverview from '../../components/SectionOverview';
import {ConfirmModal, SnackBarMessage} from '../../components';
import i18n from '../../i18n/i18n';
import {SnackBarType} from '../../components/SnackBarMessage';
import {createStyles} from '@material-ui/styles';
import GroupOverview from '../../components/GroupOverview';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
    },
    content: {
        flex: 1,
        width: '100%',
        marginTop: appBar.height,
        paddingTop: theme.spacing(2),
        alignItems: 'center',
        justifyContent: 'center',
    }
});

type StateProps = {
    token: Token
    login: string;
    isAdmin: boolean;
    isCoach: boolean;
    isSectionManager: boolean;
};

type Props = {
    classes: { [key: string]: string };
} & StateProps & RouteComponentProps;


const AllGroups: FunctionComponent<Props> = ({
    classes,
    history,
    token,
    login,
    isAdmin,
    isCoach,
    isSectionManager
}: Props) => {
    const [groups, setGroups] = useState<AllGroupsBySection[]>([]);
    const [isRequestInProgress, setIsRequestInProgress] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [groupToDelete, setGroupToDelete] = useState(0);
    const [failMessage, setFailMessage] = useState('');
    const [requestFailed, setRequestFailed] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        if (!token.access) {
            history.push('/login');
        }
    }, [history, token.access]);

    const orderGroupsBySection = (groupList: AllGroupsResponse): void => {
        const orderedGroupList: AllGroupsBySection[] = [];
        (groupList as GroupOverView[]).map(group => {
            if (orderedGroupList.filter(filteredGroup =>
                filteredGroup.section === group.Section.name).length === 0) {
                orderedGroupList.push({
                    section: group.Section.name,
                    groups: [group],
                });
            } else {
                const existingSection = orderedGroupList.filter(filteredGroup =>
                    filteredGroup.section === group.Section.name)[0];
                existingSection.groups.push(group);
            }
            return group;
        });
        setGroups(orderedGroupList.sort((a, b) =>
            a.section < b.section ? -1 : a.section > b.section ? 1 : 0));
        setIsRequestInProgress(false);
    };

    const fetchGroups = useRef(async () => {
        setIsRequestInProgress(true);
        setRequestFailed(false);
        setFailMessage('');
        const connector = Connector.getInstance();
        const groupsResponse = [];
        if (isAdmin || isSectionManager) {
            const response = await connector.getAllGroups();

            if (Array.isArray(response as GroupOverView[])) {
                groupsResponse.push(...(response as GroupOverView[]));
            } else {
                setRequestFailed(true);
                setFailMessage(response as string);
            }
        }

        if (isCoach) {
            const response = await connector.getGroupsForCoach(login);
            
            if (Array.isArray(response as GroupOverView[]))
                groupsResponse.push(...(response as GroupOverView[]));
            else {
                setRequestFailed(true);
                setFailMessage(response as string);
            }
        }
        setIsRequestInProgress(false);

        orderGroupsBySection(groupsResponse);
    });

    useEffect(() => {
        fetchGroups.current();
    }, []);

    const goToGroupDetails = (id: number) => history.push(`/group/${id}`, {id});

    const handleDeleteClick = (id: number) => {
        setGroupToDelete(id);
        setIsModalOpen(true);
    };

    const goToEditGroup = (id: number) => history.push(`/group/${id}/edit`, {id});

    const handleNewSessionClick = (id: number) => history.push(`/group/${id}/session/new`, {id});

    const handleChooseSessionForSubGroupClick = (id: number) =>
        history.push(`/group/${id}/subgroup/session/new`, {id});

    const handleMailClick = (id: number) => history.push(`/group/${id}/mail`, {id});

    const handleSubGroupsClick = (id: number) => history.push(`/group/${id}/subgroups`, {id});

    const renderSectionList = (): ReactElement[] => groups.map(orderedGroups =>
        <SectionOverview
            isAdmin={isAdmin}
            isSectionManager={isSectionManager}
            section={orderedGroups}
            key={orderedGroups.section}
            goToGroupDetails={goToGroupDetails}
            goToEditGroup={goToEditGroup}
            onDeleteClick={handleDeleteClick}
            goToNewSession={handleNewSessionClick}
            goToChooseSessionForSubGroup={handleChooseSessionForSubGroupClick}
            goToMailSend={handleMailClick}
            goToSubGroups={handleSubGroupsClick}
        />
    );

    const handleModalClose = (): void => {
        setGroupToDelete(0);
        setIsModalOpen(false);
    };

    const handleConfirmDelete = (): void => {
        setIsRequestInProgress(true);
        setRequestFailed(false);
        setFailMessage('');
        if (groupToDelete > 0) {
            Connector.getInstance().deleteGroup(groupToDelete)
                .then((ok: boolean | string) => {
                    if (!errorMessages.includes(ok as string)) {
                        fetchGroups.current();
                        setGroupToDelete(0);
                        setIsModalOpen(false);
                    } else {
                        setFailMessage(ok as string);
                        setRequestFailed(true);
                    }
                });
        } else {
            setGroupToDelete(0);
            setIsModalOpen(false);
        }
    };

    return (
        <div className={classes.root}>
            <ConfirmModal
                isOpen={isModalOpen}
                onClose={handleModalClose}
                message={i18n.t('CONFIRM_GROUP_DELETE')}
                onConfirm={handleConfirmDelete}
            />
            <CssBaseline/>
            <AppBar currentRoute={SHOW_ALL_GROUPS_ROUTE} login={login}
                onBurgerClick={() => setIsMenuOpen(!isMenuOpen)}/>
            <DrawerMenu isOpen={isMenuOpen}/>
            <Container component="main" className={classes.content}>
                {isRequestInProgress ? <CircularProgress/>
                    : groups.length > 0 ? renderSectionList() : <span/>}
            </Container>
            <SnackBarMessage content={i18n.t(failMessage)} type={SnackBarType.ERROR} isOpen={requestFailed}/>
        </div>
    );
};

const mapStateToProps = (state: AppState): StateProps => {
    const {token, login, isAdmin, isCoach, isSectionManager} = state.login;
    return {token, login, isAdmin, isCoach, isSectionManager};
};

export default withStyles(styles)(withRouter(connect(mapStateToProps, null)(AllGroups)));
