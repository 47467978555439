import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {
    CssBaseline,
    Fade,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Typography
} from '@material-ui/core';
import {Create, Delete, MoreHoriz, PlayArrow} from '@material-ui/icons';
import i18n from '../i18n/i18n';
import ConfirmationModal from './ConfirmationModal';
import {Connector} from '../helpers/Connector';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(0.5),
        padding: 0,
        flex: 0.3,
        minWidth: '24%'
    },
    iconView: {
        flex: 0.2,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    testInfo: {
        flex: 0.8,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 0,
    }
});

class TestSessionOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            anchor: null,

        };
    }

    handleClick = e => {
        this.setState({open: !this.state.open, anchor: e.currentTarget});
    };

    onEditTestClick = () => {
        this.props.onEditClicked(this.props.testId);
        this.setState({open: false});
    };

    onDeleteTestClick = () => {
        this.confirmModal.open();
        this.setState({open: false});
    };

    onStartTestClick = () => {
        this.props.onStartTestClick(this.props.testId);
        this.setState({open: false});
    };

    handleDeleteConfirm = async () => {
        this.confirmModal.handleClose();
        const connector = Connector.getInstance();
        connector.deleteTest(this.props.testId)
            .then(ok => {
                if (ok)
                    this.props.onUpdate();
                else
                    this.props.onRequestFailed(ok);
            })
            .catch(e => e.message === 'LOGOUT' && this.props.logout());
    };

    render() {
        const {classes} = this.props;
        return (
            <React.Fragment>
                <ConfirmationModal
                    ref={ref => this.confirmModal = ref}
                    message={i18n.t('TEST_DELETE_CONFIRMATION_MESSAGE')}
                    onConfirm={this.handleDeleteConfirm}
                />
                <Popper open={this.state.open} anchorEl={this.state.anchor} placement="bottom-end" transition>
                    {({TransitionProps}) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper>
                                <List>
                                    <ListItem button onClick={this.onEditTestClick}>
                                        <ListItemIcon><Create/></ListItemIcon>
                                        <ListItemText primary={i18n.t('EDIT_TEST_ITEM')}/>
                                    </ListItem>
                                    <ListItem button onClick={this.onDeleteTestClick}>
                                        <ListItemIcon><Delete/></ListItemIcon>
                                        <ListItemText primary={i18n.t('DELETE_TEST_ITEM')}/>
                                    </ListItem>
                                    <ListItem button onClick={this.onStartTestClick}>
                                        <ListItemIcon><PlayArrow/></ListItemIcon>
                                        <ListItemText primary={i18n.t('START_TEST_ITEM')}/>
                                    </ListItem>
                                </List>
                            </Paper>
                        </Fade>
                    )}
                </Popper>
                <CssBaseline/>
                <Paper className={classes.root}>
                    <div className={classes.iconView}>
                        <IconButton onClick={this.handleClick}><MoreHoriz/></IconButton>
                    </div>
                    <div className={classes.testInfo}>
                        <Typography paragraph>
                            {i18n.t('TEST_TYPE', {type: this.props.type ? i18n.t(this.props.type.label) : ''})}
                        </Typography>
                        <Typography paragraph>
                            {i18n.t('TEST_DATE_TIME', {date: this.props.date, time: this.props.time})}
                        </Typography>
                        <Typography paragraph>
                            {i18n.t('BIRTH_YEAR_SPAN_DESCR', {
                                minBirthYear: this.props.minBirthYear,
                                maxBirthYear: this.props.maxBirthYear
                            })}
                        </Typography>
                        <Typography paragraph>
                            {i18n.t('TEST_REMAINING_SLOTS',
                                {remaining: this.props.remaining, capacity: this.props.capacity}
                            )}
                        </Typography>
                    </div>
                </Paper>
            </React.Fragment>
        );

    }
}

export default withStyles(styles)(TestSessionOverview);
