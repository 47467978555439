export type RequestState = {
    requested: boolean;
    succeeded: boolean;
    failed: boolean;
    failMessage: string;
}

export type SubscribeState = {
    fname: string,
    lname: string,
    gender: string,
    birthDate: string,
    birthPlace: string,
    nationality: string,
    guardianFname: string,
    guardianLname: string,
    guardianEmail: string,
    guardianPhone: string,
    instagramHandle: string,
    secondGuardianEmail: string,
    secondGuardianPhone: string,
    address: string,
    city: string,
    zipCode: string,
    country: string,
    request: RequestState,
    id: number,
}

export type FormContent = {
    address: string,
    addressComp: string,
    birthDate: string,
    birthPlace: string,
    city: string,
    country: string,
    fname: string,
    gender: string,
    guardianEmail: string,
    guardianFname: string,
    guardianLname: string,
    guardianPhone: string,
    instagramHandle: string,
    lname: string,
    nationality: string,
    secondGuardianEmail: string | undefined,
    secondGuardianPhone: string | undefined,
    shirtSizeId: number,
    shortSizeId: number,
    zipCode: string,
    school: string,
    schoolLevel: string,
}

export enum SUBSCRIBE_ACTION {
    SUBSCRIPTION_REQUEST = 'SUBSCRIPTION_REQUEST',
    SUBSCRIPTION_SUCCESS = 'SUBSCRIPTION_SUCCESS',
    SUBSCRIPTION_FAILURE = 'SUBSCRIPTION_FAILURE',
}

export type SubscriptionRequest = {
    type: SUBSCRIBE_ACTION,
    formContent: FormContent,
}

export type SubscriptionSuccess = {
    type: SUBSCRIBE_ACTION,
    id: number,
}

export type SubscriptionFailure = {
    type: SUBSCRIBE_ACTION,
    failMessage: string,
}

export type SubscribeActions =
    SubscriptionRequest
    | SubscriptionFailure
    | SubscriptionSuccess
