import React, {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {createStyles, withStyles} from '@material-ui/core/styles';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {
    CircularProgress,
    Container,
    CssBaseline,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme
} from '@material-ui/core';
import AppBar from '../AppBar';
import {SHOW_ALL_GROUPS_ROUTE} from './routes';
import DrawerMenu from '../DrawerMenu';
import {RequestState} from '../../redux/session/types';
import {appBar} from '../../styles';
import {AppState, errorMessages, Token} from '../../helpers/types';
import {Connector} from '../../helpers/Connector';
import i18n from '../../i18n/i18n';
import {SnackBarType} from '../../components/SnackBarMessage';
import {SnackBarMessage} from '../../components';
import {Session} from '../../helpers/responseTypes';
import moment, {Moment} from 'moment';
import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from 'redux';
import {connect} from 'react-redux';
import {Edit} from '@material-ui/icons';
import {setSession} from '../../redux/session/actions';
import {KeyboardDatePicker} from '@material-ui/pickers';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
    },
    content: {
        flex: 1,
        width: '100%',
        marginTop: appBar.height,
        paddingTop: theme.spacing(2),
        alignItems: 'center',
        justifyContent: 'center',
    },
});

type StateProps = {
    token: Token
    login: string;
    isAdmin: boolean;
    isCoach: boolean;
    isSectionManager: boolean;
    request: RequestState;
};

type DispatchProps = {
    setSession: (session: Session) => void;
}

type Props = {
    classes: { [key: string]: string };
} & StateProps & DispatchProps & RouteComponentProps;

const AllSessions: FunctionComponent<Props> = ({classes, token, login, request, history, setSession}: Props) => {
    const today = new Date();
    const weekStart = new Date(today.setDate(today.getDate() - today.getDay() + 1));
    const weekEnd = new Date(today.setDate(today.getDate() + (7 - today.getDay())));
    weekStart.setHours(0, 0, 0, 0);
    weekEnd.setHours(23, 59, 59, 999);
    const [requestFailed, setRequestFailed] = useState(false);
    const [failMessage, setFailMessage] = useState('');
    const [sessions, setSessions] = useState<Session[]>([]);
    const [startDate, setStartDate] = useState<Date>(weekStart);
    const [endDate, setEndDate] = useState(weekEnd);
    const [rangeStartError, setRangeStartError] = useState(false);
    const [rangeEndError, setRangeEndError] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        if (!token.access) {
            history.push('/login');
        }
    }, [history, token.access]);

    useEffect(() => {
        if (!rangeStartError && !rangeEndError) {
            Connector.getInstance()
                .getSessionsByDateRangeForCoach(startDate.getTime(), endDate.getTime(), login)
                .then(sessionResponse => {
                    if (errorMessages.includes(sessionResponse as string)) {
                        setRequestFailed(true);
                        setFailMessage(sessionResponse as string);
                        return;
                    }
                    setSessions(sessionResponse as Session[]);
                })
                .catch(e => {
                    if (e.message === 'LOGOUT') {
                        history.push('/login');
                    }
                });
        }
    }, [endDate, history, login, rangeEndError, rangeStartError, startDate]);

    const onEditClick = (date: string,
        groupId: number | null,
        subGroupId: number | null): void => {
        const sessionToEdit = sessions.filter(session =>
            session.date.toString() === date && session.groupId === groupId && session.subGroupId === subGroupId);
        if (sessionToEdit.length) {
            setSession(sessionToEdit[0]);
            history.push('/session/edit/', {session: sessionToEdit[0]});
        }
    };

    const fetchSessions = useCallback((): void => {
        if (!rangeStartError && !rangeEndError) {
            Connector.getInstance()
                .getSessionsByDateRangeForCoach(startDate.getTime(), endDate.getTime(), login)
                .then(sessionResponse => {
                    if (errorMessages.includes(sessionResponse as string)) {
                        setRequestFailed(true);
                        setFailMessage(sessionResponse as string);
                        return;
                    }
                    setSessions(sessionResponse as Session[]);
                })
                .catch(e => {
                    if (e.message === 'LOGOUT') {
                        history.push('/login');
                    }
                });
        }
    }, [endDate, history, login, rangeEndError, rangeStartError, startDate]);

    useEffect(() => {
        fetchSessions();
    }, [fetchSessions, startDate]);

    const handleRangeStartChange = (newDate: Moment | null): void => {
        if (newDate) {
            setRangeStartError(!newDate || (newDate.toDate() && isNaN(newDate.toDate().getTime())));
            setStartDate(newDate.toDate());
        } else {
            setStartDate(new Date('Invalid'));
            setRangeStartError(true);
        }
    };

    const handleRangeStartBlur = (): void => {
        setRangeStartError(!startDate || (startDate && isNaN(startDate.getTime())));
    };

    useEffect(() => {
        setRangeEndError(!endDate || (endDate && endDate.toString() === 'Invalid Date'));
    }, [endDate]);

    const handleRangeEndChange = (newDate: Moment | null): void => {
        if (newDate) {
            setEndDate(newDate.toDate());
            fetchSessions();
        } else {
            setEndDate(new Date('Invalid'));
        }
    };

    const handleRangeEndBlur = (): void => {
        setRangeEndError(!endDate || (endDate && endDate.toString() === 'Invalid Date'));
    };

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar currentRoute={SHOW_ALL_GROUPS_ROUTE} login={login} onBurgerClick={() => {
                setIsMenuOpen(!isMenuOpen);
            }}/>
            <DrawerMenu isOpen={isMenuOpen}/>
            {request.requested ?
                <Container component="main" className={classes.content}>
                    <CircularProgress/>
                </Container> :
                <Container component="main" className={classes.content}>
                    <div>
                        <Paper>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <KeyboardDatePicker
                                                id="startDate"
                                                label={i18n.t('RANGE_START')}
                                                value={startDate}
                                                onChange={handleRangeStartChange}
                                                error={rangeStartError}
                                                format="DD/MM/YYYY"
                                                onBlur={handleRangeStartBlur}
                                                invalidDateMessage={i18n.t('INVALID_DATE_MESSAGE')}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <KeyboardDatePicker
                                                id="endDate"
                                                label={i18n.t('RANGE_END')}
                                                value={endDate}
                                                onChange={handleRangeEndChange}
                                                error={rangeEndError}
                                                format="DD/MM/YYYY"
                                                onBlur={handleRangeEndBlur}
                                                invalidDateMessage={i18n.t('INVALID_DATE_MESSAGE')}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{i18n.t('DATE_TITLE')}</TableCell>
                                        <TableCell>{i18n.t('GROUP_TITLE')}</TableCell>
                                        <TableCell>{i18n.t('SUB_GROUP_TITLE')}</TableCell>
                                        <TableCell>{i18n.t('PARTICIPANTS_TITLE')}</TableCell>
                                        <TableCell>{i18n.t('ABSENCE_TITLE')}</TableCell>
                                        <TableCell>{i18n.t('LATE_TITLE')}</TableCell>
                                        <TableCell>{i18n.t('ACTIONS')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sessions.map(session => session
                                        ? <TableRow key={new Date(session.date).getTime()}>
                                            <TableCell>{moment(session.date).format('DD/MM/YYYY HH:mm')}</TableCell>
                                            <TableCell>{session.groupName}</TableCell>
                                            <TableCell>{session.subGroupName}</TableCell>
                                            <TableCell>
                                                {session.students.filter(student => !student.isAbsent).length}
                                            </TableCell>
                                            <TableCell>
                                                {session.students.filter(student => student.isAbsent).length}
                                            </TableCell>
                                            <TableCell>
                                                {session.students.filter(student => student.isLate).length}
                                            </TableCell>
                                            <TableCell>
                                                <IconButton onClick={() => {
                                                    onEditClick(
                                                        session.date.toString(),
                                                        session.groupId,
                                                        session.subGroupId
                                                    );
                                                }}>
                                                    <Edit/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow> : null
                                    )}
                                </TableBody>
                            </Table>
                        </Paper>
                    </div>
                </Container>}
            <SnackBarMessage
                content={i18n.t(failMessage)}
                type={SnackBarType.ERROR}
                isOpen={requestFailed}/>
        </div>
    );
};

const mapStateToProps = (state: AppState): StateProps => {
    const {token, login, isAdmin, isCoach, isSectionManager} = state.login;
    const {request} = state.session;
    return {token, login, isAdmin, isCoach, isSectionManager, request};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, unknown, AnyAction>): DispatchProps => ({
    setSession: (session: Session): void => {
        dispatch(setSession({
            groupId: session.groupId,
            name: '',
            subGroupId: session.subGroupId,
            date: session.date,
            students: session.students,
            request: {
                requested: false,
                succeeded: false,
                failed: false,
                failMessage: '',
            },
            isTransmitted: false,
        }));
    }
});

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(AllSessions)));
