const fr = {
    LOGIN: 'Se connecter',
    CHANGE_LOCALE: 'Changer la langue',
    FORM_TITLE_PART_1: 'Informations sur l\'hadérent',
    FIRST_NAME_FIELD: 'Prénom',
    LAST_NAME_FIELD: 'Nom',
    FORM_PERSONAL_INFO: 'Informations personnelles',
    FORM_GENDER_TITLE: 'Sexe',
    FORM_BIRTH_DATE: 'Date de naissance',
    NATIONALITY_FIELD: 'Nationalité',
    BIRTH_PLACE_FIELD: 'Lieu de naissance',
    NEXT_STEP_BUTTON: 'Suivant',
    BACK_BUTTON: 'Précédent',
    RESET_FORM_BUTTTON: 'Réinitialiser',
    FORM_CONTACT: 'Contact',
    FORM_ADDRESS: 'Adresse',
    GENDER_RADIO_F: 'Femme',
    GENDER_RADIO_M: 'Homme',
    EMAIL_FIELD: 'Email',
    EMAIL_TOOLTIP: 'Dans le cas d\'une personne mineure, saisir les coordonnées d\'un responsable légal',
    PHONE_NUMBER_FIELD: 'Numéro de téléphone',
    ADD_CONTACT_TOOLTIP: 'Ajouter un contact',
    GUARDIAN_CONTACT_LABEL: 'Responsable légal / Contact',
    CONTACT_LABEL: 'Contact',
    GUARDIAN_LABEL: 'Responsable légal',
    ADDRESS_FIELD: 'Adresse',
    CITY_FIELD: 'Ville',
    ZIPCODE_FIELD: 'Code Postal',
    COUNTRY_FIELD: 'Pays',
    SUBMIT_BUTTON: 'Valider',
    WELCOME: 'Bienvenue, {{login}}',
    GO_TO_PERSONAL_SPACE: 'Mon Compte',
    LOGOUT: 'Déconnexion',
    GO_TO_TEST_CREATION: 'Créer des tests',
    TEST_DATETIME_LABEL: 'Date et heure du test',
    TEST_CAPACITY_LABEL: 'Nombre de membres',
    TEST_CREATION_TITLE: 'Créer une nouvelle séance de test',
    TEST_DRAWER_ITEM: 'Gestion des tests',
    TEST_DATE_TIME: '{{date}} à {{time}}',
    TEST_REMAINING_SLOTS: '{{remaining}} places disponibles sur {{capacity}}',
    EDIT_TEST_ITEM: 'Modifier',
    DELETE_TEST_ITEM: 'Supprimer',
    EDIT_TEST_TITLE: 'Modifier un test',
    CANCEL_BUTTON: 'Annuler',
    SHOW_ALL_TESTS_TITLE: 'Voir toutes les sessions',
    TEST_DELETE_CONFIRMATION_MESSAGE: 'Êtes-vous sûr de vouloir supprimer cette session de test ?',
    CONFIRMATION_TITLE: ' Confirmation',
    STUDENT_DRAWER_ITEM: 'Membres',
    STUDENT_CREATION_TITLE: 'Créer un membre',
    BIRTH_YEAR_LABEL: 'Année de naissance',
    BIRTH_YEAR_INFO: 'Année de naissance : {{birthYear}}',
    TEST_ASSIGNATION_TITLE: 'Assignation à un test',
    MIN_BIRTH_YEAR_LABEL: 'Age maximum',
    MAX_BIRTH_YEAR_LABEL: 'Age minimum',
    BIRTH_YEAR_SPAN_DESCR: '{{minBirthYear}} à {{maxBirthYear}}',
    TEST_SESSION_LIST_ITEM: 'Test {{type}} : {{scheduledDate}} à {{scheduledTime}}, entre {{minBirthYear}} ' +
        'et {{maxBirthYear}}. {{capacity}} place restantes.',
    STUDENT_ASSIGN_TITLE: 'Modifier les assignations aux séances de test',
    FNAME_ERROR: 'Prénom invalide',
    LNAME_ERROR: 'Nom invalide',
    NATIONALITY_ERROR: 'Nationalité invalide',
    BIRTH_DATE_ERROR: 'Date de naissance invalide',
    EMAIL_ERROR: 'Email invalide',
    PHONE_ERROR: 'Numéro de téléphone invalide',
    SECOND_EMAIL_ERROR: 'Email secondaire invalide',
    SECOND_PHONE_ERROR: 'Numéro de téléphone secondaire invalide',
    SECTION_LABEL: 'Section',
    START_TEST_ITEM: 'Commencer la session',
    START_TEST_SESSION_TITLE: 'Session de test, {{date}}',
    STUDENT_LIST_HEADER: 'Membre',
    FAILED_TEST_HEADER: 'Recalé',
    GROUP_DRAWER_ITEM: 'Gestion des groupes',
    GROUP_CREATION_TITLE: 'Créer un groupe',
    SHOW_ALL_GROUPS_TITLE: 'Voir tous les groupes',
    GROUP_NAME_LABEL: 'Nom',
    GROUP_PRICE_LABEL: 'Prix',
    GROUP_RESUB_PRICE_LABEL: 'Prix réinscription',
    COACH_LABEL: 'Entraineur',
    GROUP_NAME_ERROR: 'Nom de groupe invalide',
    GROUP_PRICE_ERROR: 'Prix d\'inscription invalide',
    GROUP_RESUB_PRICE_ERROR: 'Prix de réinscription invalide',
    SECTION_DRAWER_ITEM: 'Gestion des sections',
    SECTION_CREATION_TITLE: 'Créer une section',
    SHOW_ALL_SECTIONS_TITLE: 'Voir toutes les sections',
    SECTION_NAME_LABEL: 'Nom',
    SECTION_NAME_ERROR: 'Nom de section invalide',
    USER_DRAWER_ITEM: 'Utilisateurs',
    USER_CREATION_TITLE: 'Créer un utilisateur',
    SHOW_ALL_USERS_TITLE: 'Voir tous les utilisateurs',
    ROLE_CHOICE_TITLE: 'Assigner des rôles',
    FIRST_NAME_ERROR: 'Le prénom saisi est invalide',
    LAST_NAME_ERROR: 'Le nom saisi est invalide',
    NO_ROLE_CHOSEN: 'Aucun rôle n\'a été choisi. Veuillez choisir au moins un rôle.',
    CREATED_COACH_EXPLANATION: 'Le nouvel utilisateur {{firstName}} {{lastName}} a bien été créé.\n' +
        'Le mot de passe par défaut est nom.prenom, pensez à le changer rapidement.',
    ADDRESS_ERROR: 'L\'adresse saisie est invalide.',
    ADDRESS_COMP_ERROR: 'Le complément d\'adresse saisi est invalide.',
    CITY_ERROR: 'La ville saisie est invalide.',
    ZIP_CODE_ERROR: 'Le code postal est invalide.',
    COUNTRY_ERROR: 'Le pays saisi est invalide.',
    CREATED_SUBSCRIPTION_EXPLANATION: 'Les informations ont bien été prises en compte.\n' +
        'Pour terminer l\'inscription, merci de télécharger les documents ci-dessous, les imprimer,\n' +
        'les signer le cas échéant et les rapporter' +
        ' avec le reste des pièces demandées au guichet.',
    SETTINGS_ITEM_TITLE: 'Préférences',
    PLEASE_ENTER_PASSWORD: 'Veuillez saisir votre mot de passe.',
    PASSWORD_LABEL: 'Mot de passe',
    PASSWORD_ERROR: 'Veuillez saisir votre mot de passe',
    WRONG_PASSWORD: 'Le mot de passe saisi est incorrect',
    PASSWORD_CONFIRMATION_LABEL: 'Confirmation',
    CONFIRMATION_ERROR: 'Veuillez confirmer votre nouveau mot de passe.',
    CONFIRMATION_MISMATCH: 'Le mot de passe et la confirmation ne correspondent pas.',
    CHANGE_PASSWORD_SUCCESS: 'Le mot de passe a été mis à jour avec succès',
    NO_TESTS_TITLE: 'Aucun test disponible.',
    GROUP_COACH_DEFINITION: 'Entraineurs : {{coachList}}',
    STUDENT_COUNT_DEFINITION: 'Nombre de membres : {{studentCount}}',
    ROLE_LIST: 'Roles : {{roleList}}',
    POSSIBLE_NEXT_YEAR_GROUPS: 'Groupes suivants potentiels',
    ASSIGN_NEXT_YEAR_GROUP: 'Assigner aux prochains groupes',
    ADMIN: 'Admin.',
    COACH: 'Entraineur',
    SECTION_MANAGER: 'Respo. section',
    CHANGE_PASSWORD_TITLE: 'Changement de mot de passe',
    CONFIRM_GROUP_DELETE: 'Êtes-vous sûr de vouloir supprimer ce groupe ?',
    CONFIRM_USER_DELETE: 'Êtes-vous sûr de vouloir supprimer cet utilisateur ?',
    GROUP_EDIT_TITLE: 'Modification de groupe',
    ALREADY_SUBSCRIBED_NORMAL: 'Vous avez déjà commencé votre (ré-)inscription. Pour terminer l\'inscription' +
        ' merci de télécharger les documents ci-dessous, les imprimer, les signer le cas échéant et les rapporter' +
        ' avec le reste des pièces demandées au guichet.',
    SUBSCRIPTION_FORM: 'Formulaire d\'inscription ASM Natation',
    ASM_SUBSCRIPTION_FORM: 'Demande d\'admission ASM',
    SCHEDULE_DOCUMENT: 'Horaires Natation',
    SUBSCRIPTION_OVERVIEW_TITLE: 'Inscriptions',
    SUBSCRIPTION_STATE_DEFINITION: 'Etat inscription',
    SUBSCRIPTION_STATE_VALUE: '{{state}}',
    SUBSCRIPTION_scheduled: 'En attente',
    SUBSCRIPTION_started: 'Commencée',
    SUBSCRIPTION_missingDoc: 'Document(s) manquant(s)',
    SUBSCRIPTION_done: 'Terminée',
    FIRST_NAME: 'Prénom',
    LAST_NAME: 'Nom',
    ACTIONS: 'Actions',
    STUDENT_INFO_TITLE: 'Informations personnelles',
    STUDENT_CONTACT_TITLE: 'Contact / Responsable légal',
    STUDENT_ADDRESS_TITLE: 'Adresse',
    IS_NEW_LABEL: 'Première inscription',
    SUBSCRIPTION_STATE_TITLE: 'Inscription',
    SUBSCRIPTION_STATE_LABEL: 'Etat inscription',
    RESEND_MAIL: 'Renvoyer le mail d\'inscription',
    LOGIN_TITLE: 'Connexion',
    USERNAME_PLACEHOLDER: 'Login',
    PASSWORD_PLACEHOLDER: 'Mot de passe',
    LOGIN_FAILED: 'Echec de la connexion. Veuillez vérifier votre login et votre mot de passe',
    BAD_REQUEST: 'Données invalides',
    UNKNOWN_ERROR: 'Une erreur inattendue est survenue. Veuillez réessayer ultérieurement.',
    TEST_CREATION_SUCCEEDED: 'La séance de test a bien été créée.',
    TEST_CREATION_FAILED_BAD_REQUEST: 'Un test existe déjà à cette date.',
    TEST_CREATION_FAILED_UNKNOWN_ERROR: 'Une erreur inattendue est survenue, veuillez réessayer ultérieurement.',
    TEST_EDIT_FAILED_BAD_REQUEST: 'Données invalides.',
    TEST_EDIT_FAILED_UNKNOWN_ERROR: 'Une erreur inattendue est survenue, veuillez réessayer ultérieurement.',
    TEST_EDIT_FAILED_NOT_FOUND: 'Aucun test n\'a été trouvé.',
    TEST_ASSIGN_SUCCESS: 'Le membre a bien été ajouté au test.',
    SUB_MAIL_SENT: 'L\'email a bien été envoyé.',
    GROUP_ACTION: 'Action',
    NEXT_YEAR_GROUP: 'Groupe saison suivante',
    SELECTION: 'Sélection',
    MOVE_STUDENT_BUTTON_TITLE: 'Déplacer',
    DELETE_STUDENT_BUTTON_TITLE: 'Supprimer',
    GROUP_CHANGE_TITLE: 'Changement de groupe',
    SELECT_NEW_GROUP_MESSAGE: 'Sélectionnez le groupe dans lequel déplacer les membres :',
    GROUP_DELETE_TITLE: 'Supprimer du groupe',
    CONFIRM_GROUP_DELETE_MESSAGE: 'Êtes-vous sûr de vouloir supprimer les membres du groupe ?' +
        ' Les membres supprimés seront placés dans le groupe "Aucun".',
    CLICK_HERE: '(Cliquez ici)',
    GROUP_TITLE: 'Groupe',
    SUB_GROUP_TITLE: 'Sous-groupe',
    SEND_MAIL_BUTTON: 'Envoyer le mail',
    SEND_MAIL_FOR_TEST_SUCCESS: 'Mails envoyés avec succès',
    PROBLEM: 'Problème',
    SECONDARY_EMAIL_FIELD: 'Deuxième email',
    SECONDARY_PHONE_NUMBER_FIELD: 'Deuxième téléphone',
    FILTERS: 'Filtres',
    SUBSCRIPTION_TITLE: 'Dossier de {{firstName}} {{lastName}}',
    CREATE_NEW_GUARDIAN: 'Créer un nouveau contact / resp. légal',
    NEW_SESSION: 'Nouvelle séance : {{name}}',
    SESSION_DATE: 'Date de la séance',
    ATTENDING: 'Présent',
    EMAIL_SUBJECT_FIELD: 'Objet',
    EMAIL_MESSAGE_FIELD: 'Message',
    MAIL_SEND_SUCCESS: 'Les mails ont bien été envoyés',
    SECTION_MANAGER_DEFINITION: 'Responsable : {{sectionManager}}',
    SECTION_GROUPS_DEFINITION: 'Groupes : {{groupList}}',
    CONFIRM_SECTION_DELETE: 'Êtes-vous sûr de vouloir supprimer cette section ?',
    SECTION_EDIT_TITLE: 'Modification de section',
    SECTION_MANAGER_TITLE: 'Responsable : ',
    DISPLAY_SETTINGS_TITLE: 'Paramètres affichage',
    GROUP_ACTION_DEFINITION: 'Déplacement / suppression',
    NEXT_YEAR_GROUPS_DEFINITION: 'Groupes année prochaine',
    GROUPS_DEFINITION: 'Groupes',
    ADD_GROUP_TITLE: 'Ajout',
    SUB_GROUP_DRAWER_ITEM: 'Gestion des sous-groupes',
    SHOW_ALL_SUB_GROUPS_TITLE: 'Voir tous les sous-groupes',
    SUB_GROUP_CREATION_TITLE: 'Créer un sous-groupe',
    DETAILS_TOOLTIP: 'Détails',
    NEW_SESSION_TITLE: 'Nouvelle séance',
    DELETE_TOOLTIP: 'Supprimer',
    EDIT_TOOLTIP: 'Edition',
    MAIL_TOOLTIP: 'Mails',
    SUB_GROUPS_TOOLTIP: 'Sous-groupes',
    CURRENT_SUB_GROUP: 'Sous-groupe actuel',
    NONE_M: 'Aucun',
    NO_SUB_GROUP: 'Aucun sous-groupe',
    CREATE_TOOLTIP: 'Créer',
    SUB_GROUP_NAME_FIELD: 'Nom du sous-groupe',
    CONFIRM_DELETE_SUB_GROUP_MESSAGE: 'Êtes-vous sûr de vouloir supprimer ce sous-groupe ?',
    SUB_GROUP_CREATE_SUCCESS: 'La création a été effectuée avec succès.',
    SUB_GROUP_DELETE_SUCCESS: 'La suppression a été effectuée avec succès.',
    SESSION_DRAWER_ITEM: 'Séances',
    SESSION_CREATION_TITLE: 'Créer une nouvelle séance',
    SHOW_ALL_SESSIONS_TITLE: 'Voir toutes les séances',
    CHOOSE_SUB_GROUP_SESSION_TITLE: 'Choisir un sous-groupe',
    WHOLE_GROUP_SELECT_ITEM: 'Groupe entier',
    NO_STUDENT_TEXT: 'Aucun membre dans ce (sous-)groupe',
    IS_LATE: 'Retard',
    INVALID_DATE_MESSAGE: 'Date invalide',
    SECTION_REMOVE_GROUP_SUCCESS: 'Groupe déplacé dans "Aucune" avec succès.',
    SECTION_REMOVE_GROUP_FAILURE: 'Une erreur est survenue lors du déplacement du groupe vers "Aucune"',
    CONFIRM_STUDENT_DELETE: 'Êtes-vous sûr de vouloir supprimer ce membre ?',
    BIRTH_PLACE_ERROR: 'Lieu de naissance invalide',
    FFN_LICENSE: 'Licence FFN',
    BIRTH_PLACE: 'Lieu de naissance',
    GENDER_ERROR: 'Sexe invalide',
    DOCUMENTS_STATE: 'Documents',
    SUBSCRIPTION_FORM_LABEL: 'Formulaire d\'inscription',
    ID_PHOTO_LABEL: 'Photo d\'identité',
    ASM_PDF_LABEL: 'Formulaire ASM Général',
    MEDICAL_CERTIFICATE_LABEL: 'Certificat médical',
    PAYMENT_LABEL: 'Paiement',
    FFN_LICENSE_LABEL: 'Licence FFN',
    PROCESS_DATE_LABEL: 'Date de validation',
    TEST_TYPE_LABEL: 'Type de test',
    NATATION_LOISIR: 'Natation',
    ADULTE: 'Natation Adulte',
    'WATER-POLO': 'Water-Polo',
    PLONGEON_COMPETITION: 'Plongeon',
    PLONGEON_LOISIR: 'Plongeon Loisirs',
    SYNCHRO_COMPETITION: 'Natation Synchronisée',
    SYNCHRO_LOISIR: 'Natation Synchronisée Loisirs',
    AUTRES: 'Autre',
    NATATION_COMPETITION: 'Natation de compétition',
    OFFICIALS: 'Officiels',
    DATE_TITLE: 'Date',
    PARTICIPANTS_TITLE: 'Participants',
    ABSENCE_TITLE: 'Absences',
    LATE_TITLE: 'Retards',
    EDIT_SESSION: 'Modification séance',
    RANGE_START: 'Début',
    RANGE_END: 'Fin',
    CREATE_SESSION_SUCCESS: 'Séance créée avec succès',
    TEST_TYPE: 'Test {{type}}',
    EXPORT_BUTTON: 'Exporter (.xlsx)',
    TEST_LOCATION_LABEL: 'Lieu',
    SHIRT_SIZE_FIELD: 'Taille T-shirt',
    SHORT_SIZE_FIELD: 'Taille short',
    SHIRT_SIZE_ERROR: 'Taille de T-shirt invalide',
    SHORT_SIZE_ERROR: 'Taille de short invalide',
    DOCUMENT_LABEL: 'Document {{index}} : ',
    PACK_FIELD: 'Pack d\'équipement',
    SIZES_TITLE: 'Tailles',
    RULES_LABEL: 'Règles de fonctionnement ASM Natation',
    CODE_OF_CONDUCT_LABEL: 'Code de conduite du sportif ASM Natation',
    PARENT_CODE_OF_CONDUCT_LABEL: 'Code de conduite des parents ASM Natation',
    FFN_LICENCE_MAJOR: 'Formulaire de licence FFN - MAJEURS',
    FFN_LICENCE_MINOR: 'Formulaire de licence FFN - MINEURS',
    TYPE_FIELD: 'Type',
    TYPE_ERROR: 'Type invalide',
    PACK_ERROR: 'Pack invalide',
    UPDATE_SECTION_SUCCESS: 'La section a été mise à jour avec succès.',
    MISSING_DATA: 'Données invalides.',
    INSTAGRAM_HANDLE_FIELD: 'Compte Instagram',
    GENERIC_ERROR_MESSAGE: 'Une erreur est survenue. Veuillez réessayer ultérieurement.',
    ACCEPT_PRIVACY_TERMS: 'J\'ai lu et j\'accepte les conditions de confidentialité suivantes :',
    PRIVACY_TERMS: '\nLes informations nominatives sont collectées par l\'ASM Natation, par le biais de ce' +
        ' formulaire,' +
        ' dans le cadre du traitement ayant pour finalité \u00ab Gestion des membres des associations et des ' +
        'fédérations d\'associations \u00bb. Conformément aux dispositions de la Loi n° 1.165, modifiée, du ' +
        '23 décembre 1993, relative à la protection des informations nominatives, vous disposez de droits d"accès, ' +
        'de rectification et de suppression de vos informations nominatives en vous rendant au siège de l\'ASM ' +
        'Natation (7 avenue des Castellans) ou sur demande écrite envoyée à ',
    CREATE_NEW_MEMBER: 'Créer un nouveau membre',
    SCHOOL_ERROR: 'Veuillez rensigner l\'école',
    SCHOOL_FIELD: 'Ecole',
    SCHOOL_LEVEL_ERROR: 'Veuillez renseigner la classe',
    SCHOOL_LEVEL_FIELD: 'Classe',
    SHOW_ALL_TIMESLOTS_TITLE: 'Voir tous les horaires',
    CREATE_NEW_TIMESLOT_TITLE: 'Créer un nouvel horaire',
    TIMESLOT_TITLE: 'Horaires',
    SEND_INVOICE: 'Envoyer l\'attestation',
    INVOICE: 'Attestation d\'inscription'
};

export default fr;
