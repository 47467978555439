import React, {Fragment, FunctionComponent, useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {menuItem, menuItemIcon, menuItemText, menuItemTitle} from '../styles';
import {List, ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import {Create, Schedule, Search} from '@material-ui/icons';
import i18n from '../i18n/i18n';

const styles = (): object => ({
    menuItem: menuItem,
    menuItemTitle: menuItemTitle,
});

type Props = {
    classes: {[key: string]: string};
    onShowAllClick: () => void;
    onCreateClick: () => void;
};

const DrawerTimeSlotItem: FunctionComponent<Props> = ({classes, onCreateClick, onShowAllClick}: Props) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = (): void => {
        setIsOpen(!isOpen);
    };

    const handleCreateClick = (): void => {
        setIsOpen(false);
        onCreateClick();
    };

    const handleShowAllClick = (): void => {
        setIsOpen(false);
        onShowAllClick();
    };

    return (
        <Fragment>
            <ListItem button onClick={handleClick} className={classes.menuItem}>
                <div className={classes.menuItemTitle}>
                    <div style={menuItemIcon}>
                        <ListItemIcon><Schedule/></ListItemIcon>
                    </div>
                    <div style={menuItemText}>
                        <ListItemText primary={i18n.t('TIMESLOT_TITLE')}/>
                    </div>
                </div>
                <div style={{
                    display: isOpen ? 'block' : 'none',
                }}>
                    <List>
                        <ListItem button onClick={handleCreateClick}>
                            <ListItemIcon><Create/></ListItemIcon>
                            <ListItemText primary={i18n.t('CREATE_NEW_TIMESLOT_TITLE')}/>
                        </ListItem>
                    </List>
                    <List>
                        <ListItem button onClick={handleShowAllClick}>
                            <ListItemIcon><Search/></ListItemIcon>
                            <ListItemText primary={i18n.t('SHOW_ALL_TIMESLOTS_TITLE')}/>
                        </ListItem>
                    </List>
                </div>
            </ListItem>
        </Fragment>
    );
};

export default withStyles(styles)(DrawerTimeSlotItem);

