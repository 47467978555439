import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {SubscriptionForm, AppBar} from '../';

class Subscription extends Component {
    componentDidMount() {
        if (!this.props.location.search) {
            this.props.history.push('/login');
        }
    }

    render() {
        return (
            <React.Fragment>
                <AppBar currentRoute={this.props.location.pathname}/>
                <SubscriptionForm token={this.props.location.search.replace(/\?token=/, '')}/>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, null)(Subscription));
