import React, {ChangeEvent, FunctionComponent, useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {createStyles, Theme, withStyles} from '@material-ui/core/styles';
import {appBar} from '../../styles';
import {AppState, errorMessages, Token} from '../../helpers/types';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import {
    CircularProgress,
    Container,
    CssBaseline,
    IconButton,
    MenuItem,
    Paper,
    Select,
    Typography
} from '@material-ui/core';
import AppBar from '../AppBar';
import {NEW_SESSION_ROUTE, SHOW_ALL_GROUPS_ROUTE} from './routes';
import DrawerMenu from '../DrawerMenu';
import {Connector} from '../../helpers/Connector';
import {SubGroupsOverview} from '../../helpers/responseTypes';
import {RequestState} from '../../redux/session/types';
import {
    actionFailed,
    actionRequested,
    actionSucceeded,
    resetSession,
    setSessionGroupAction
} from '../../redux/session/actions';
import i18n from '../../i18n/i18n';
import {CancelOutlined, PlayArrow} from '@material-ui/icons';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
    },
    content: {
        flex: 1,
        width: '100%',
        marginTop: appBar.height,
        paddingTop: theme.spacing(2),
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '70%',
        flex: 1,
        marginTop: appBar.height,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(2),
    },
    subGroupSelect: {
        flex: 0.8,
        width: '60%',
        textAlign: 'center',
        marginTop: theme.spacing(2),
    },
    buttonRow: {
        flex: 0.2,
        width: '60%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'space-between',
    }
});

type StateProps = {
    token: Token;
    login: string;
    request: RequestState;
};

type DispatchProps = {
    sessionActionRequested: () => void;
    sessionActionFailed: (message: string) => void;
    sessionActionSucceeded: () => void;
    resetSessionState: () => void;
    setSessionGroup: (groupId: number, subGroupId: number, name: string) => void;
};

type Props = {
    classes: { [key: string]: string };
} & StateProps & DispatchProps & RouteComponentProps;

const ChooseSubGroupSession: FunctionComponent<Props> = ({
    classes,
    history,
    location,
    token,
    login,
    request,
    sessionActionRequested,
    sessionActionFailed,
    sessionActionSucceeded,
    resetSessionState,
    setSessionGroup,
}: Props) => {
    const [tokenChecked, setTokenChecked] = useState(false);
    const [groupId] = useState(parseInt(location.pathname.replace(/\/group\/([0-9]+)\/subgroup.*/, '$1')));
    const [groupName, setGroupName] = useState('');
    const [subGroups, setSubGroups] = useState<{ id: number; name: string; }[]>([]);
    const [name, setName] = useState('');
    const [selectedSubGroup, setSelectedSubGroup] = useState(0);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        if (!tokenChecked) {
            if (!token.access) {
                // history.push('/login');
            } else {
                setTokenChecked(true);
            }
        }
    }, [history, token, token.access, tokenChecked]);

    const fetchSubGroups = useCallback(() => {
        sessionActionRequested();
        Connector.getInstance().getSubGroupsForGroup(groupId)
            .then((subGroups) => {
                if ((subGroups as SubGroupsOverview).id) {
                    sessionActionSucceeded();
                    setSubGroups((subGroups as SubGroupsOverview).subGroups);
                    setGroupName((subGroups as SubGroupsOverview).name);
                } else if (errorMessages.includes(subGroups as string)) {
                    sessionActionFailed(subGroups as string);
                }
            })
            .catch(e => {
                console.error(e);
                sessionActionFailed(e.message || '');
                if (e.message === 'LOGOUT') {
                    history.push('/login');
                }
            });
    }, [groupId, history, sessionActionFailed, sessionActionRequested, sessionActionSucceeded]);

    useEffect(() => {
        if (subGroups.length > 0) {
            if (selectedSubGroup === 0) {
                setSessionGroup(groupId, 0, groupName);
            } else {
                setSessionGroup(0, selectedSubGroup, name);
            }
        } else {
            setSessionGroup(groupId, 0, groupName);
        }
    }, [groupId, selectedSubGroup, setSessionGroup, name, subGroups.length, groupName]);

    useEffect(() => {
        fetchSubGroups();
    }, [fetchSubGroups]);

    const handleSubGroupChange = (e: ChangeEvent<{ name?: string | undefined; value: unknown; }>): void => {
        setSelectedSubGroup(e.target.value as number);
        setName(subGroups.filter(subGroup => subGroup.id === e.target.value as number)[0]
            ? subGroups.filter(subGroup => subGroup.id === e.target.value as number)[0].name : groupName);
    };

    const handleCancelClick = (): void => {
        resetSessionState();
        history.push(SHOW_ALL_GROUPS_ROUTE);
    };

    const handleStartSessionClick = (): void => {

        history.push(NEW_SESSION_ROUTE);
    };

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar currentRoute={SHOW_ALL_GROUPS_ROUTE} login={login} onBurgerClick={() => {
                setIsMenuOpen(!isMenuOpen);
            }}/>
            <DrawerMenu isOpen={isMenuOpen}/>
            <Container component="main" className={classes.content}>
                {request.requested ? <CircularProgress/> : null}
                <Paper className={classes.paper}>
                    <Typography variant="h4" component="h6">
                        {i18n.t('CHOOSE_SUB_GROUP_SESSION_TITLE')}
                    </Typography>
                    <Select className={classes.subGroupSelect}
                        value={selectedSubGroup}
                        onChange={handleSubGroupChange} variant="outlined">
                        <MenuItem value={0}>{i18n.t('WHOLE_GROUP_SELECT_ITEM')}</MenuItem>
                        {subGroups.length > 0 ? subGroups.map(subGroup => <MenuItem key={subGroup.id}
                            value={subGroup.id}>
                            {subGroup.name}
                        </MenuItem>) : null}
                    </Select>
                    <div className={classes.buttonRow}>
                        <IconButton onClick={handleStartSessionClick}>
                            <PlayArrow/>
                        </IconButton>
                        <IconButton onClick={handleCancelClick}>
                            <CancelOutlined/>
                        </IconButton>
                    </div>
                </Paper>
            </Container>
        </div>
    );
};
const mapStateToProps = (state: AppState): StateProps => {
    const {token, login} = state.login;
    const {request} = state.session;
    return {token, login, request};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, unknown, AnyAction>): DispatchProps => ({
    sessionActionRequested: (): void => {
        dispatch(actionRequested());
    },
    sessionActionFailed: (message: string): void => {
        dispatch(actionFailed(message));
    },
    sessionActionSucceeded: (): void => {
        dispatch(actionSucceeded());
    },
    resetSessionState: (): void => {
        dispatch(resetSession());
    },
    setSessionGroup: (groupId: number, subGroupId: number, name: string) => {
        dispatch(setSessionGroupAction(groupId, subGroupId, name));
    },
});

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(ChooseSubGroupSession)));
