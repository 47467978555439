import React, {FunctionComponent} from 'react';
import {List, ListItem, Theme, withStyles} from '@material-ui/core';
import {PossibleGroup} from '../helpers/responseTypes';

const styles = (theme: Theme): object => ({
    groupList: {
        display: 'flex',
        flexDirection: 'column',
    },
    groupButton: {
        minWidth: '25%',
        marginRight: theme.spacing(1),
        padding: 'auto',
        textAlign: 'center',
        border: '1px solid black',
        borderRadius: 4,
    },
});

type Props = {
    classes: { [key: string]: string };
    groups: PossibleGroup[];
    onGroupClick: (id: number) => void;
};

const NextYearGroupList: FunctionComponent<Props> = ({classes, groups, onGroupClick}: Props) => {
    const handleGroupClick = (id: number) => {
        onGroupClick(id);
    };

    return (
        <List>
            {
                groups.map(group => <ListItem
                    button
                    key={group.id}
                    selected={group.possible}
                    className={classes.groupButton}
                    onClick={() => {
                        handleGroupClick(group.id);
                    }}
                >
                    {group.name}
                </ListItem>)
            }
        </List>
    );
};

export default withStyles(styles)(NextYearGroupList);
