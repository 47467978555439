import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import {Button, CssBaseline, Paper, Step, StepLabel, Stepper, Typography} from '@material-ui/core';
import {AppBar, DrawerMenu} from '..';
import {STUDENT_CREATION_ROUTE} from './routes';
import {CreateStudentForm, SnackBarMessage, TestAssignStudentForm} from '../../components';
import i18n from '../../i18n/i18n';
import {Connector} from '../../helpers/Connector';
import {appBar} from '../../styles';
import {SnackBarType} from '../../components/SnackBarMessage';

const styles = theme => ({
    root: {
        display: 'flex'
    },
    content: {
        marginTop: appBar.height,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        marginTop: theme.spacing(2),
    },
    stepper: {
        width: '100%',
        padding: theme.spacing(3, 2, 0),
        display: 'flex',
    },
    buttonContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    button: {
        margin: theme.spacing(2),

    },
});

class StudentCreation extends Component {
    initialState = {
        activeStep: 0,
        steps: [{
            label: i18n.t('STUDENT_CREATION_TITLE'),
            buttonLabel: i18n.t('NEXT_STEP_BUTTON'),
        }, {
            label: i18n.t('TEST_ASSIGNATION_TITLE'),
            buttonLabel: i18n.t('SUBMIT_BUTTON'),
        }],
        student: null,
        birthDate: '',
        testSessions: [],
        requestFailed: false,
        requestSucceeded: false,
        failMessage: '',
        isMenuOpen: false,
    };

    constructor(props) {
        super(props);

        this.state =  this.initialState;
    }

    componentDidMount() {
        if (!this.props.token.access) {
            this.props.history.push('/login');
        }
    }

    handleLogout = () => {
        this.props.history.push('/login');
    };

    handleCreateStudentSubmit = async (info) => {
        this.setState({failMessage: '', requestFailed: false}, () => {
            const connector = Connector.getInstance();
            connector.createStudent(info)
                .then(newStudent => {
                    if (newStudent && newStudent.id) {
                        this.setState({student: newStudent, birthDate: info.birthDate}, async () => {
                            await this.fetchTestSession();
                        });
                    } else {
                        this.setState({failMessage: newStudent || 'UNKNOWN_ERROR', requestFailed: true});
                    }
                }).catch((e) => {
                    console.error(e);
                    e.message === 'LOGOUT' && this.handleLogout();
                });
        });
    };

    fetchTestSession = async () => {
        this.setState({failMessage: '', requestFailed: false}, () => {
            Connector.getInstance().getTestInBirthYearRange(new Date(this.state.birthDate).getFullYear())
                .then(response => {
                    if (!response || ['BAD_REQUEST', 'UNKNOWN_ERROR'].includes(response)) {
                        this.setState({failMessage: response, requestFailed: true});
                    } else
                        this.setState({activeStep: 1, testSessions: response});
                }).catch((e) => {
                    console.error(e);
                    e.message === 'LOGOUT' && this.props.history.push('/login');
                });
        });
    };

    handleStudentAssigned = (succeeded, failMessage = null) => {
        this.setState({requestSucceeded: succeeded, requestFailed: !succeeded, failMessage: failMessage || ''});
        if (succeeded) {
            this.fetchTestSession()
                .then(() => {
                    this.props.history.push('/home');
                });
        }
    };

    handleCreateNewMemberClick = () => {
        this.setState(this.initialState);
    }

    getStepContent = () => {
        switch (this.state.activeStep) {
        case 0:
            return (
                <React.Fragment>
                    <Typography component="h3" variant="h4" className={this.props.classes.title}>
                        {this.state.steps[this.state.activeStep].label}
                    </Typography>
                    <CreateStudentForm
                        onSubmit={this.handleCreateStudentSubmit}
                    />
                </React.Fragment>
            );
        case 1:
            return (
                <React.Fragment>
                    <Typography component="h3" variant="h4" className={this.props.classes.title}>
                        {this.state.steps[this.state.activeStep].label}
                    </Typography>
                    <TestAssignStudentForm
                        testSessions={this.state.testSessions}
                        studentId={this.state.student.id}
                        logout={this.handleLogout}
                        onStudentAssigned={this.handleStudentAssigned}
                    />
                </React.Fragment>
            );
        default:
            return 'Shit\'s broken';
        }
    };

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                <CssBaseline/>
                <AppBar currentRoute={STUDENT_CREATION_ROUTE} login={this.props.login} onBurgerClick={() => {
                    this.setState({isMenuOpen: !this.state.isMenuOpen});
                }}/>
                <DrawerMenu isOpen={this.state.isMenuOpen}/>
                <div className={classes.content}>
                    <Paper className={classes.paper}>
                        <Stepper activeStep={this.state.activeStep} className={classes.stepper}>
                            {this.state.steps.map(step => (
                                <Step key={step.label}>
                                    <StepLabel>{step.label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        {this.getStepContent()}
                        <div className={this.props.classes.buttonContainer}>
                            <Button
                                variant="contained"
                                color="default"
                                disabled={this.state.activeStep === 0}
                                className={this.props.classes.button}
                                onClick={this.handleCreateNewMemberClick}
                            >
                                {i18n.t('CREATE_NEW_MEMBER')}
                            </Button>
                        </div>
                    </Paper>
                </div>
                <SnackBarMessage
                    content={this.state.requestSucceeded
                        ? i18n.t('TEST_ASSIGN_SUCCESS')
                        : this.state.requestFailed
                            ? i18n.t(this.state.failMessage || 'UNKNOWN_ERROR')
                            : ''}
                    type={this.state.requestSucceeded ? SnackBarType.SUCCESS : SnackBarType.ERROR}
                    isOpen={this.state.requestFailed || this.state.requestSucceeded}/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {token, login} = state.login;
    return {token, login};
};

export default withStyles(styles)(withRouter(connect(mapStateToProps, null)(StudentCreation)));
