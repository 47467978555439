import axios, {AxiosError, AxiosResponse} from 'axios';
import {clear, set} from 'local-storage';
import {
    AllCoachesResponse,
    AllGroupsForSectionResponse,
    AllGroupsForTestResponse,
    AllGroupsResponse,
    AllGuardiansResponse,
    AllSectionsResponse,
    AllSubscriptionsResponse,
    AllTestsResponse,
    AllUsersResponse,
    AssignedStudentsResponse,
    AssignToTestResponse,
    ChangePasswordResponse,
    ChangeStudentGroupResponse,
    ChangeSubGroupResponse,
    CreateCoachResponse,
    CreateGroupResponse,
    CreateGuardianResponse,
    CreateSectionResponse,
    CreateStudentResponse,
    CreateSubGroupResponse,
    CreateTestResponse,
    DeleteGroupResponse,
    DeleteSectionResponse,
    DeleteSubGroupResponse,
    DeleteSubscriptionResponse,
    DeleteTestResponse,
    DeleteUserResponse,
    EditGroupResponse,
    EditTestResponse,
    ExistingDataResponse,
    ExportDataResponse,
    FinishTestResponse,
    Gender,
    GetRolesResponse,
    GetSubGroupsResponse,
    GetTestResponse,
    GroupDetailsResponse,
    GroupsByIdsResponse,
    GroupsForCoachResponse,
    GroupSummaryResponse,
    LoginResponse,
    MailingListResponse,
    MailingListType,
    MoveGroupResponse,
    NextGroupForGroupResponse,
    Pack,
    PacksResponse,
    PatchSectionResponse,
    PossibleGroupsResponse,
    Recipient,
    RefreshResponse,
    RemoveFromTestResponse,
    Role,
    SectionDetailsResponse, SendInvoiceResponse,
    SendMailResponse,
    SendMailsForTestResponse,
    SendSubMailResponse,
    SessionsResponse,
    SetAttendanceResponse,
    SetGuardianResponse,
    SetNextYearGroupsResponse,
    SetPossibleGroupsResponse,
    SetStudentsGroupResponse,
    ShirtSizesResponse,
    ShortSizesResponse,
    StudentDetails,
    StudentsForSession,
    StudentsForSessionResponse,
    SubGroupsOverview,
    SubGroupsOverViewResponse,
    SubmitSessionResponse,
    SubscribeResponse,
    SubscriptionDetailsResponse,
    SubscriptionState,
    Test,
    TestByBirthYearResponse,
    TogglePossibleGroupResponse,
    Token,
    TypesResponse,
    UnassignedStudentsResponse,
    UpdateRolesResponse,
    UpdateSubscriptionResponse,
    VerifyPasswordResponse
} from './responseTypes';
import {Session} from '../redux/session/types';
import {Size, Type} from './types';

type ErrorResponse = {
    response: {
        status: number
    }
};

class Connector {
    static instance: Connector | null = null;

    baseUrl: string;
    headers: { [key: string]: string };
    token: Token;

    static getInstance = (): Connector => {
        if (Connector.instance === null)
            Connector.instance = new Connector();
        return Connector.instance;
    };

    constructor() {
        this.baseUrl = process.env.REACT_APP_BACKEND_URL as string;
        this.headers = {};
        this.setHeaders(false);
        this.token = {
            access: null,
            refresh: null,
        };
    }

    setHeaders = (secured: boolean): void => {
        this.headers = Object.assign({}, this.headers, {
            'Content-Type': 'application/json'
        }, secured && this.token.access ? {
            'Authorization': `Bearer ${this.token.access}`
        } : {});
    };

    setToken = (token: Token): void => {
        this.token = token;
        set('access', token.access);
        set('refresh', token.refresh);
    };

    handleForbidden = async (retried: boolean): Promise<boolean> => {
        try {
            if (!retried) {
                this.setHeaders(false);
                const response = await this.POST('/coach/refresh', {refresh: this.token.refresh}, this.headers);
                this.setToken(response.data);
                return true;
            }
            return false;
        } catch (e) {
            if ((e as ErrorResponse).response.status === 403) {
                clear();
                throw new Error('LOGOUT');
            }
            throw new Error('UNKNOWN_ERROR');
        }
    };

    refreshLogin = async (): Promise<boolean> => {
        try {
            this.setHeaders(false);
            const response = await this.POST('/coach/refresh', {refresh: this.token.refresh}, this.headers);
            if (response && response.data) {
                this.setToken(response.data);
                return true;
            }
            return false;
        } catch (e) {
            return false;
        }
    }

    POST = (route: string,
        body: unknown,
        headers = this.headers,
        config?: unknown): Promise<AxiosResponse> => {
        let configObject = {headers};
        if (config)
            configObject = Object.assign(configObject, config);

        return axios.post(`${this.baseUrl}${route}`, body, configObject);
    };

    PATCH = (route: string,
        body?: unknown,
        headers = this.headers,
        config?: unknown): Promise<AxiosResponse> => {
        let configObject = {headers};
        if (config)
            configObject = Object.assign(configObject, config);

        return axios.patch(`${this.baseUrl}${route}`, body, configObject);
    };

    GET = (route: string,
        params = '',
        query: { [key: string]: string | number } = {},
        headers = this.headers,
        config?: unknown): Promise<AxiosResponse> => {
        let formattedQuery = '', configObject = {headers};
        if (Object.keys(query).length > 0) {
            formattedQuery = '?';
            for (const param of Object.keys(query)) {
                formattedQuery += `${param}=${query[param]}&`;
            }
            formattedQuery = formattedQuery.slice(0, -1);
        }

        if (config) {
            configObject = Object.assign(configObject, config);
        }

        return axios.get(`${this.baseUrl}${route}${params}${formattedQuery}`, configObject);
    };

    DELETE = (route: string,
        headers = this.headers,
        config?: unknown): Promise<AxiosResponse> => {
        let configObject = {headers};
        if (config) {
            configObject = Object.assign(configObject, config);
        }

        return axios.delete(`${this.baseUrl}${route}`, configObject);
    };

    subscribe = async (studentId: number,
        subscriptionId: number,
        formContent: {
            fname: string;
            lname: string;
            gender: string;
            birthDate: string;
            birthPlace: string;
            nationality: string;
            guardianFname: string;
            guardianLname: string;
            guardianEmail: string;
            guardianPhone: string;
            secondGuardianEmail?: string;
            secondGuardianPhone?: string;
            covidClause: boolean;
            address: string;
            addressComp: string;
            city: string;
            zipCode: string;
            country: string;
            shirtSizeId: number;
            shortSizeId: number;
            school: string;
            schoolLevel: string;
        }): Promise<SubscribeResponse> => {
        let response;
        try {
            response = await this.POST('/subscription/', {id: studentId, subscriptionId, ...formContent});
            if (response)
                return response.data.subscriptionId;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            if ((e as ErrorResponse).response && (e as ErrorResponse).response.status === 400) return 'BAD_REQUEST';
            return 'UNKNOWN_ERROR';
        }
    };

    login = async (login: string,
        password: string): Promise<LoginResponse> => {
        try {
            const response = await this.POST('/coach/login', {login, password});
            if (response) {
                const {token, roles} = response.data;
                this.setToken(token);
                return {token, roles};
            }
            return 'UNKNOWN_ERROR';
        } catch (e) {
            if ((e as ErrorResponse).response && (e as ErrorResponse).response.status === 400) return 'BAD_REQUEST';
            return 'UNKNOWN_ERROR';
        }
    };

    redirectToRefresh = async (e: Error | AxiosError | unknown,
        retried: boolean,
        method: string,
        params: unknown[] = []): Promise<RefreshResponse> => {
        if ((e as AxiosError).response) {
            const error: AxiosError = e as AxiosError;
            if (error.response) {
                if (error.response.status === 400) return 'BAD_REQUEST';
                if (error.response.status === 404) return 'NOT_FOUND';
                if (error.response.status === 500) return 'UNKNOWN_ERROR';
                if (error.response.status === 403) {
                    const refreshed = await this.handleForbidden(retried);
                    if (refreshed && method) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        return await this[method](...params, true);
                    } else if (!refreshed) {
                        throw new Error('LOGOUT');
                    }
                }
            }
        }
        return 'UNKNOWN_ERROR';
    };

    createTest = async (testDate: Date,
        capacity: number,
        minBirthYear: number,
        maxBirthYear: number,
        selectedTypeId: number,
        location: number,
        retried = false): Promise<CreateTestResponse> => {
        this.setHeaders(true);
        let response;
        try {
            response = await this.POST('/test/', {
                scheduledDateTime: testDate,
                capacity,
                minBirthYear,
                maxBirthYear,
                selectedTypeId,
                location,
            });
            if (response)
                return response.data;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(e, retried, 'createTest', [
                    testDate,
                    capacity,
                    minBirthYear,
                    maxBirthYear,
                ]) as CreateTestResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    getAllTests = async (retried = false): Promise<AllTestsResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.GET('/test/');
            if (response)
                return response.data;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(e, retried, 'getAllTests') as AllTestsResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    getTest = async (id: number,
        retried = false): Promise<GetTestResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.GET(`/test/${id}`);
            if (response && response.data)
                return response.data;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(e, retried, 'getTest', [id]) as GetTestResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    editTest = async (test: Test,
        retried = false): Promise<EditTestResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.PATCH(`/test/${test.id}`, {
                scheduledDateTime: test.scheduledDate,
                capacity: test.capacity,
                minBirthYear: test.minBirthYear,
                maxBirthYear: test.maxBirthYear,
                typeId: test.typeId,
                location: test.location,
            });
            if (response)
                return true;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                console.error(e);
                return await this.redirectToRefresh(e, retried, 'editTest', [test]) as EditTestResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    deleteTest = async (id: number,
        retried = false): Promise<DeleteTestResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.DELETE(`/test/${id}`);
            if (response)
                return true;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                console.error(e);
                return await this.redirectToRefresh(e, retried, 'deleteTest', []) as DeleteTestResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    createStudent = async (body: {
            fname: string;
            lname: string;
            gender: string;
            birthDate: Date;
            nationality: string;
            guardianEmail: string;
            guardianPhoneNumber: string;
        },
    retried = false): Promise<CreateStudentResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.POST('/student/', body);
            if (response && response.data)
                return response.data;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                console.error(e);
                return await this.redirectToRefresh(e, retried, 'createStudent', [body]) as CreateStudentResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    getTestInBirthYearRange = async (birthYear: number,
        retried = false): Promise<TestByBirthYearResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.GET('/test/birthYear', '', {birthYear: birthYear});
            if (response && response.data)
                return response.data;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                console.error(e);
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'getTestInBirthYearRange',
                    [birthYear]
                ) as TestByBirthYearResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    assignStudentToTest = async (testId: number,
        studentId: number,
        sendMail = true,
        retried = false): Promise<AssignToTestResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.POST('/test/student', {
                testId: testId,
                studentId: studentId,
                sendMail,
            });
            if (response)
                return true;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                console.error(e);
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'assignStudentToTest',
                    [testId, studentId]
                ) as AssignToTestResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    getUnassignedStudents = async (id: number,
        retried = false): Promise<UnassignedStudentsResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.GET('/student/unassigned', '', {id: id});
            if (response && response.data)
                return response.data;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                console.error(e);
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'getUnassignedStudent',
                    [id]
                ) as UnassignedStudentsResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    getTestStudents = async (id: number,
        retried = false): Promise<AssignedStudentsResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.GET(`/student/test/${id}`);
            if (response && response.data) {
                return response.data;
            }
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                console.error(e);
                return await this.redirectToRefresh(e, retried, 'getTestStudents', [id]) as AssignedStudentsResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    removeStudentFromTest = async (testId: number,
        studentId: number,
        retried = false): Promise<RemoveFromTestResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.DELETE(`/test/${testId}/student/${studentId}`);
            if (response)
                return true;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                console.error(e);
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'removeStudentFromTest',
                    [testId, studentId]
                ) as RemoveFromTestResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    getSections = async (retried = false): Promise<AllSectionsResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.GET('/section/');
            if (response && response.data)
                return response.data;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                console.error(e);
                return await this.redirectToRefresh(e, retried, 'getSections', []) as AllSectionsResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    getGroups = async (sectionId: number,
        retried = false): Promise<AllGroupsForSectionResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.GET(`/group/section/${sectionId}`);
            if (response && response.data)
                return response.data;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                console.error(e);
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'getGroups',
                    [sectionId]
                ) as AllGroupsForSectionResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    setPossibleGroups = async (id: number,
        possibleGroups: number[],
        retried = false): Promise<SetPossibleGroupsResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.POST(`/test/${id}/group`, {groupIds: possibleGroups});
            if (response)
                return true;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                console.error(e);
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'setPossibleGroups',
                    [id, possibleGroups]
                ) as SetPossibleGroupsResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    getPossibleGroups = async (id: number,
        retried = false): Promise<PossibleGroupsResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.GET(`/test/${id}/group`);
            if (response && response.data)
                return response.data;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                console.error(e);
                return await this.redirectToRefresh(e, retried, 'getPossibleGroups', [id]) as PossibleGroupsResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    getGroupsByIds = async (ids: number[],
        retried = false): Promise<GroupsByIdsResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.POST('/group/id', {ids});
            if (response && response.data)
                return response.data;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                console.error(e);
                return await this.redirectToRefresh(e, retried, 'getGroupsByIds', [ids]) as GroupsByIdsResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    setStudentsGroup = async (students: { id: number, groupId: number }[],
        retried = false): Promise<SetStudentsGroupResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.PATCH('/student/bulk/group', {students: students});
            if (response)
                return true;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                console.error(e);
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'setStudentsGroup',
                    [students]
                ) as SetStudentsGroupResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    finishTest = async (id: number,
        retried = false): Promise<FinishTestResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.PATCH(`/test/${id}/completed`);
            if (response)
                return true;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                console.error(e);
                return await this.redirectToRefresh(e, retried, 'finishTest', [id]) as FinishTestResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    getAllCoaches = async (retried = false): Promise<AllCoachesResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.GET('/coach/role?role=COACH');
            if (response && response.data)
                return response.data;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                console.error(e);
                return await this.redirectToRefresh(e, retried, 'getAllCoaches', []) as AllCoachesResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    createGroup = async (name: string,
        price: number,
        reSubPrice: number,
        sectionId: number,
        coachId: number,
        packId: number,
        typeId: number,
        retried = false): Promise<CreateGroupResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.POST('/group/', {
                name: name,
                price: price,
                reSubPrice: reSubPrice,
                sectionId: sectionId,
                coachId: coachId,
                packId: packId,
                typeId: typeId,
            });
            if (response && response.data)
                return response.data.id as number;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                console.error(e);
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'createGroup',
                    [name, price, reSubPrice, sectionId, coachId, packId, typeId]
                ) as CreateGroupResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    createSection = async (name: string,
        coachId: number,
        retried = false): Promise<CreateSectionResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.POST('/section/', {
                name: name,
                coachId: coachId,
            });
            if (response && response.data)
                return response.data;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                console.error(e);
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'createSection',
                    [name, coachId]
                ) as CreateSectionResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    getRoles = async (retried = false): Promise<GetRolesResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.GET('/role/');
            if (response && response.data)
                return response.data;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                console.error(e);
                return await this.redirectToRefresh(e, retried, 'getRoles', []) as GetRolesResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    createCoach = async (firstName: string,
        lastName: string,
        roles: number[],
        retried = false): Promise<CreateCoachResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.POST('/coach/', {
                firstName: firstName,
                lastName: lastName,
                roles: roles
            });
            if (response && response.data)
                return response.data;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                console.error(e);
                return await this.redirectToRefresh(e, retried, 'getRoles', []) as CreateCoachResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    getExistingData = async (token: string): Promise<ExistingDataResponse> => {
        try {
            const response = await this.GET(`/subscription/resume?token=${token}`);
            if (response && response.data) {
                return response.data;
            }
            return 'UNKNOWN_ERROR';
        } catch (e) {
            if ((e as ErrorResponse).response) {
                if ((e as ErrorResponse).response.status === 400) return 'BAD_REQUEST';
                if ((e as ErrorResponse).response.status === 404) return 'NOT_FOUND';
                if ((e as ErrorResponse).response.status === 500) return 'UNKNOWN_ERROR';
            }
            console.error(e);
            return 'UNKNOWN_ERROR';
        }
    };

    verifyPassword = async (login: string,
        password: string,
        retried = false): Promise<VerifyPasswordResponse> => {
        this.setHeaders(true);
        try {
            const response = (await this.POST('/coach/verify', {login, password}));
            if (response)
                return true;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                console.error(e);
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'verifyPassword',
                    [login, password]
                ) as VerifyPasswordResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    changePassword = async (login: string,
        password: string,
        retried = false): Promise<ChangePasswordResponse> => {
        this.setHeaders(true);
        try {
            const response = (await this.POST('/coach/update/password', {login, password}));
            if (response && response.data) {
                const {token, roles} = response.data;
                this.setToken(token);
                return {token, roles};
            }
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                console.error(e);
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'changePassword',
                    [login, password]
                ) as ChangePasswordResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    getGroupDetails = async (id: number,
        retried = false): Promise<GroupDetailsResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.GET(`/group/${id}`);
            if (response && response.data)
                return response.data;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                console.error(e);
                return await this.redirectToRefresh(e, retried, 'getGroupDetails', [id]) as GroupDetailsResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    getAllGroups = async (retried = false): Promise<AllGroupsResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.GET('/group/');
            if (response)
                return response.data;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                console.error(e);
                return await this.redirectToRefresh(e, retried, 'getAllGroups') as AllGroupsResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    getAllUsers = async (retried = false): Promise<AllUsersResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.GET('/coach/');
            if (response)
                return response.data;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                console.error(e);
                return await this.redirectToRefresh(e, retried, 'getAllUsers') as AllUsersResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    updateUserRoles = async (userId: number,
        roles: Role[],
        retried = false): Promise<UpdateRolesResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.POST(`/coach/${userId}/roles`, {
                roles
            });
            if (response)
                return true;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                console.error(e);
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'updateUserRoles',
                    [userId, roles]
                ) as UpdateRolesResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    getNextGroupsForGroup = async (id: number,
        retried = false): Promise<NextGroupForGroupResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.GET(`/group/${id}/next`);
            if (response)
                return response.data;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'getNextGroupsForGroup',
                    [id]
                ) as NextGroupForGroupResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    togglePossibleGroup = async (groupId: number,
        possibleGroupId: number,
        retried = false): Promise<TogglePossibleGroupResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.POST(`/group/${groupId}/next`, {possibleGroupId});
            if (response)
                return true;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'togglePossibleGroup',
                    [groupId, possibleGroupId]
                ) as TogglePossibleGroupResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    setNextYearGroups = async (groupId: number,
        students: StudentDetails[],
        retried = false): Promise<SetNextYearGroupsResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.POST(`/group/${groupId}/next/students`, {students});
            if (response)
                return true;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'setNextYearGroups',
                    [groupId, students]
                ) as SetNextYearGroupsResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    getGroupsForCoach = async (login: string,
        retried = false): Promise<GroupsForCoachResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.GET(`/coach/${login}/groups`);
            if (response && response.data)
                return response.data;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(e, retried, 'getGroupsForCoach', [login]) as GroupsForCoachResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    deleteGroup = async (id: number,
        retried = false): Promise<DeleteGroupResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.DELETE(`/group/${id}`);
            if (response)
                return true;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(e, retried, 'deleteGroup', [id]) as DeleteGroupResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    deleteUser = async (id: number,
        retried = false): Promise<DeleteUserResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.DELETE(`/coach/${id}`);
            if (response)
                return true;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(e, retried, 'deleteUser', [id]) as DeleteUserResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    editGroup = async (id: number,
        body: {
            name: string,
            price: number,
            pack: Pack | null,
            type: Type | null,
            reSubPrice: number,
            ffnLicense: boolean,
            coaches: number[]
        },
        retried = false): Promise<EditGroupResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.PATCH(`/group/${id}`, body);
            if (response && response.data)
                return true;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(e, retried, 'editGroup', [id, body]) as EditTestResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    getAllSubscriptions = async (retried = false): Promise<AllSubscriptionsResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.GET('/subscription/');
            if (response && response.data)
                return response.data;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(e, retried, 'getAllSubscriptions') as AllSubscriptionsResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    deleteSubscription = async (id: number,
        retried = false): Promise<DeleteSubscriptionResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.DELETE(`/subscription/student/${id}`);
            if (response)
                return true;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'deleteSubscriptions',
                    [id]
                ) as DeleteSubscriptionResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    getSubscriptionDetails = async (id: number,
        retried = false): Promise<SubscriptionDetailsResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.GET(`/subscription/student/${id}`);
            if (response && response.data)
                return response.data;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'getSubscriptionDetails',
                    [id]
                ) as SubscriptionDetailsResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    updateSubscription = async (studentId: number,
        subscription: {
            studentId: number;
            firstName: string;
            lastName: string;
            isNew: boolean;
            subscriptionState: SubscriptionState;
            birthDate: Date;
            birthPlace: string | null;
            gender: Gender;
            nationality: string;
            address: string;
            zipCode: string;
            city: string;
            country: string;
            shirtSize: Size | null;
            shortSize: Size | null;
            guardianFirstName: string;
            guardianLastName: string;
            guardianPhoneNumber: string;
            guardianEmail: string;
            guardianSecondaryPhoneNumber: string | null;
            guardianSecondaryEmail: string | null;
            subscriptionForm: boolean;
            rules: boolean;
            idPhoto: boolean;
            asmPdf: boolean;
            medicalCertificate: boolean;
            payment: boolean;
            ffnLicense: boolean;
            codeOfConduct: boolean;
            guardianCodeOfConduct: boolean;
            processDate: Date | null;
            instagramHandle: string | null;
            schoolLevel: string;
            school: string;
        },
        retried = false): Promise<UpdateSubscriptionResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.POST(`/subscription/student/${studentId}`, subscription);
            if (response)
                return true;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'updateSubscription',
                    [studentId, subscription]
                ) as UpdateSubscriptionResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    sendSubEmail = async (id: number,
        body: { email: string, secondaryEmail: string | null, isNew: boolean },
        retried = false): Promise<SendSubMailResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.POST(`/subscription/student/${id}/mail`, body);
            if (response)
                return true;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(e, retried, 'sendSubMail', [id, body]) as SendSubMailResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    sendInvoice = async (id: number, body: { email: string, secondaryEmail: string | null},
        retried = false): Promise<SendInvoiceResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.POST(`/subscription/${id}/attestation`, body);
            if (response)
                return true;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(e, retried, 'sendInvoice', [id, body]) as SendInvoiceResponse;
            } catch (err) {
                throw err || e;
            }
        }
    }

    changeGroupForStudents = async (groupId: number,
        students: number[],
        retried = false): Promise<ChangeStudentGroupResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.PATCH('/student/group', {groupId, students});
            if (response)
                return true;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'changeGroupForStudents',
                    [groupId, students]
                ) as ChangeStudentGroupResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    getAllTestGroups = async (testId?: number,
        retried = false): Promise<AllGroupsForTestResponse> => {
        this.setHeaders(true);
        try {
            let response;
            if (testId) {
                response = await this.GET(`/test/${testId}/groups`);
                if (response && response.data)
                    return response.data;
                return 'UNKNOWN_ERROR';
            }
            response = await this.GET('/test/groups');
            if (response && response.data)
                return response.data;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'getAllTestGroups',
                    [testId]
                ) as AllGroupsForTestResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    sendMailsForTest = async (testId: number,
        retried = false): Promise<SendMailsForTestResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.GET(`/test/${testId}/mail`);
            if (response)
                return true;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(e, retried, 'sendMailsForTest', [testId]) as SendSubMailResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    getAllGuardians = async (retried = false): Promise<AllGuardiansResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.GET('/guardian/');
            if (response && response.data)
                return response.data;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(e, retried, 'getAllGuardians', []) as AllGuardiansResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    setGuardian = async (studentId: number,
        guardianId: number,
        retried = false): Promise<SetGuardianResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.POST(`/student/${studentId}/guardian`, {guardianId});
            if (response)
                return true;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(e, retried, 'getAllGuardians', []) as SetGuardianResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    createGuardian = async (guardian: {
            firstName: string;
            lastName: string;
            email: string;
            phoneNumber: string;
            secondaryEmail: string;
            secondaryPhoneNumber: string;
        },
    retried = false): Promise<CreateGuardianResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.POST('/guardian/', guardian);
            if (response)
                return true;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(e, retried, 'createGuardian', [guardian]) as CreateGuardianResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    setAttendance = async (
        groupId: number,
        studentIds: number[],
        retried = false,
    ): Promise<SetAttendanceResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.GET('');
            if (response) return true;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'setAttendance',
                    [groupId, studentIds]
                ) as SetAttendanceResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    getMailsForGroup = async (id: number,
        retried = false): Promise<MailingListResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.GET(`/group/${id}/recipients`);
            if (response && (response.data as MailingListType).recipients)
                return response.data as MailingListType;
            if (response)
                return response.data as string;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'getMailsForGroup',
                    [id]
                ) as MailingListResponse;
            } catch (err) {
                throw err || e;
            }
        }
    }

    sendMailForGroup = async (
        id: number,
        subject: string,
        message: string,
        recipients: Recipient[],
        retried = false
    ): Promise<SendMailResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.POST(`/mail/group/${id}`, {subject, message, recipients});
            if (response) {
                return true;
            }
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'sendMailForGroup',
                    [id, subject, message, recipients]
                ) as SendMailResponse;
            } catch (err) {
                throw err || e;
            }
        }
    }

    deleteSection = async (id: number,
        retried = false): Promise<DeleteSectionResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.DELETE(`/section/${id}`);
            if (response) {
                return true;
            }
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'deleteSection',
                    [id]
                ) as DeleteSectionResponse;
            } catch (err) {
                throw err || e;
            }
        }
    }

    getSectionDetails = async (id: number,
        retried = false): Promise<SectionDetailsResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.GET(`/section/${id}`);
            if (response && response.data) {
                return response.data;
            }
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'getSectionDetails',
                    [id]
                ) as SectionDetailsResponse;
            } catch (err) {
                throw err || e;
            }
        }
    }
    getAllGroupsSummaries = async (retried = false): Promise<GroupSummaryResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.GET('/group/summary');
            if (response && response.data) {
                return response.data;
            }
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'getAllGroupsSummaries',
                ) as GroupSummaryResponse;
            } catch (err) {
                throw err || e;
            }
        }
    }

    moveGroup = async (id: number,
        sectionId: number,
        retried = false): Promise<MoveGroupResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.PATCH(`/group/${id}/section`, {sectionId: sectionId});
            if (response)
                return true;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'moveGroup',
                    [id, sectionId],
                ) as MoveGroupResponse;
            } catch (err) {
                throw err || e;
            }
        }
    }

    getSubGroupsDetails = async (id: number,
        retried = false): Promise<GetSubGroupsResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.GET(`/group/${id}/subgroup/`);
            if (response && response.data) {
                return response.data;
            }
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'getSubGroupsDetails',
                    [id],
                ) as GetSubGroupsResponse;
            } catch (err) {
                throw err || e;
            }
        }
    }

    changeSubGroup = async (
        id: number,
        subGroupId: number | null,
        retried = false
    ): Promise<ChangeSubGroupResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.PATCH(`/student/${id}/subgroup`, {subGroupId: subGroupId});
            if (response) return true;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'changeSubGroup',
                    [id, subGroupId],
                ) as ChangeSubGroupResponse;
            } catch (err) {
                throw err || e;
            }
        }
    }

    createSubGroup = async (
        groupId: number,
        name: string,
        coaches: number[],
        retried = false
    ): Promise<CreateSubGroupResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.POST(`/group/${groupId}/subgroup/`, {name, coaches});
            if (response)
                return true;
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'createSubGroup',
                    [groupId, name, coaches],
                ) as CreateSubGroupResponse;
            } catch (err) {
                throw err || e;
            }
        }
    }

    deleteSubGroup = async (id: number,
        retried = false): Promise<DeleteSubGroupResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.DELETE(`/group/${id}/subgroup/`);
            return response ? true : 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'createSubGroup',
                    [id],
                ) as DeleteSubGroupResponse;
            } catch (err) {
                throw err || e;
            }
        }
    }

    getSubGroupsForGroup = async (id: number,
        retried = false): Promise<SubGroupsOverViewResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.GET(`/group/${id}/subgroup/overview`);
            if (response.data) {
                return response.data as SubGroupsOverview;
            }
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'getSubGroupsForGroup',
                    [id],
                ) as SubGroupsOverViewResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    getStudentsForSession = async (
        groupId = 0,
        subGroupId = 0,
        retried = false
    ): Promise<StudentsForSessionResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.GET('/student/session', '', {groupId, subGroupId});
            if (response.data) {
                return response.data as StudentsForSession;
            }
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'getStudentsForSession',
                    [groupId, subGroupId],
                ) as StudentsForSessionResponse;
            } catch (err) {
                throw err || e;
            }
        }
    };

    submitSession = async (session: Session,
        retried = false): Promise<SubmitSessionResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.POST('/session/', {session});
            if (response) {
                return true;
            }
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'submitSession',
                    [session],
                ) as SubmitSessionResponse;
            } catch (err) {
                throw err || e;
            }
        }
    }

    editSession = async (session: Session,
        retried = false): Promise<SubmitSessionResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.PATCH('/session/edit/', {session});
            if (response) {
                return true;
            }

            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'editSession',
                    [session],
                ) as SubmitSessionResponse;
            } catch (err) {
                throw err || e;
            }
        }
    }

    getSessionsByDateRangeForCoach = async (
        startDate: number,
        endDate: number,
        login: string,
        retried = false
    ): Promise<SessionsResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.GET('/session', '', {startDate: startDate, endDate: endDate, login: login});
            if (response.data) {
                return response.data as SessionsResponse;
            }
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'getSessionsByDateRangeForCoach',
                    [startDate, endDate, login],
                ) as SessionsResponse;
            } catch (err) {
                throw err || e;
            }
        }
    }

    getAllTypes = async (retried = false): Promise<TypesResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.GET('/type/');
            if (response.data) {
                return response.data as TypesResponse;
            }
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'getAllTypes'
                ) as TypesResponse;
            } catch (err) {
                throw err || e;
            }
        }
    }

    fetchExportData = async (retried = false): Promise<ExportDataResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.GET('/student/export');
            if (response.data) {
                return response.data as ExportDataResponse;
            }
            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'fetchExportData'
                ) as ExportDataResponse;
            } catch (err) {
                throw err || e;
            }
        }
    }

    getShirtSizes = async (): Promise<ShirtSizesResponse> => {
        const response = await this.GET('/size/shirt');
        if (response.data) {
            return response.data as ShirtSizesResponse;
        }

        return 'UNKNOWN_ERROR';
    }

    getShortSizes = async (): Promise<ShortSizesResponse> => {
        const response = await this.GET('/size/short');
        if (response.data) {
            return response.data as ShirtSizesResponse;
        }

        return 'UNKNOWN_ERROR';
    }

    getPacks = async (retried = false): Promise<PacksResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.GET('/pack/');
            if (response.data) {
                return response.data as PacksResponse;
            }

            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'getPacks'
                ) as PacksResponse;
            } catch (err) {
                throw err || e;
            }
        }
    }

    patchSection = async (id: number,
        name: string,
        coachId: number,
        retried = false): Promise<PatchSectionResponse> => {
        this.setHeaders(true);
        try {
            const response = await this.PATCH(`/section/${id}/`, {name, coachId});
            if (response.data) {
                return response.data as PatchSectionResponse;
            }

            return 'UNKNOWN_ERROR';
        } catch (e) {
            try {
                return await this.redirectToRefresh(
                    e,
                    retried,
                    'patchSection',
                    [id, name, coachId]
                ) as PatchSectionResponse;
            } catch (err) {
                throw err || e;
            }
        }
    }
}

export {Connector};
