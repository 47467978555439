import React, {ChangeEvent, FunctionComponent, useEffect, useState} from 'react';

import {connect} from 'react-redux';
import {
    Button,
    CircularProgress,
    Container,
    createStyles,
    CssBaseline,
    Grid,
    TextField,
    Theme,
    Typography,
    withStyles
} from '@material-ui/core';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {AppState, errorMessages, Token} from '../../helpers/types';
import AppBar from '../AppBar';
import {SUBSCRIPTION_DETAILS_ROUTE} from './routes';
import DrawerMenu from '../DrawerMenu';
import {appBar} from '../../styles';
import {Connector} from '../../helpers/Connector';
import i18n from '../../i18n/i18n';
import {MailingListType} from '../../helpers/responseTypes';
import SnackBarMessage, {SnackBarType} from '../../components/SnackBarMessage';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    content: {
        flex: 1,
        width: '100%',
        marginTop: appBar.height,
        paddingTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',

    },
    paper: {
        padding: theme.spacing(2)
    },
    mailForm: {
        flex: 1,
        width: '100%',
    },
    button: {
        margin: theme.spacing(2)
    },
});

type StateProps = {
    token: Token;
    login: string;
};

type Props = {
    classes: {[key: string]: string};
} & StateProps & RouteComponentProps;

const MailSend: FunctionComponent<Props> = ({classes, token, login, history, location}: Props) => {
    const [mailingList, setMailingList] = useState<MailingListType | null>(null);
    const [requestFailed, setRequestFailed] = useState(false);
    const [requestSucceeded, setRequestSucceeded] = useState(false);
    const [failMessage, setFailMessage] = useState('');
    const [isWorking, setIsWorking] = useState(false);
    const [groupId] = useState(parseInt(location.pathname.replace(/\/group\/([0-9]+)/, '$1')));
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [messageError, setMessageError] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        if (!token.access) {
            history.push('/login');
        }
    }, [history, token.access]);

    useEffect(() => {
        if (!mailingList && !requestFailed && !failMessage && !isWorking) {
            if (groupId) {
                Connector.getInstance().getMailsForGroup(groupId)
                    .then(response => {
                        if ((response as MailingListType).groupName) {
                            setIsWorking(false);
                            setFailMessage('');
                            setRequestFailed(false);
                            setMailingList(response as MailingListType);
                        }
                    }).catch(e => console.warn(e));
            }
        }
    }, [failMessage, groupId, isWorking, mailingList, requestFailed]);

    const handleSubjectChange = (e: ChangeEvent<HTMLInputElement>) => setSubject(e.currentTarget.value);

    const handleMessageChange = (e: ChangeEvent<HTMLInputElement>) => setMessage(e.currentTarget.value);

    const handleMessageBlur = () => setMessageError(message === '');

    const handleMessageFocus = () => setMessageError(false);

    const handleCancelClick = () => history.goBack();

    const handleSubmitClick = () => {
        setRequestFailed(false);
        setFailMessage('');
        if (!mailingList) return;
        Connector.getInstance().sendMailForGroup(groupId, subject, message, mailingList.recipients)
            .then(response => {
                if (!errorMessages.includes(response as string)) {
                    setRequestSucceeded(true);
                    setTimeout(() => {
                        setRequestSucceeded(false);
                        history.goBack();
                    }, 3000);
                } else {
                    setRequestFailed(true);
                    setFailMessage(response as string);
                    setTimeout(() => {
                        setRequestFailed(false);
                        setFailMessage('');
                        history.goBack();
                    }, 3000);
                }
            });
    };

    return <div className={classes.root}>
        <CssBaseline/>
        <AppBar login={login} currentRoute={SUBSCRIPTION_DETAILS_ROUTE} onBurgerClick={() => {
            setIsMenuOpen(!isMenuOpen);
        }}/>
        <DrawerMenu isOpen={isMenuOpen}/>
        {mailingList
            ? <Container component="main" className={classes.content}>
                <Typography component="h3" variant="h6">{mailingList.groupName}</Typography>
                <Grid container spacing={2} className={classes.mailForm}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            name="subject"
                            label={i18n.t('EMAIL_SUBJECT_FIELD')}
                            value={subject}
                            onChange={handleSubjectChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            required
                            name="message"
                            label={i18n.t('EMAIL_MESSAGE_FIELD')}
                            value={message}
                            onChange={handleMessageChange}
                            onBlur={handleMessageBlur}
                            onFocus={handleMessageFocus}
                            error={messageError}
                            multiline={true}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <Button
                            variant="contained"
                            color="default"
                            className={classes.button}
                            onClick={handleCancelClick}
                        >
                            {i18n.t('CANCEL_BUTTON')}
                        </Button>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={handleSubmitClick}
                            disabled={message === ''}
                        >
                            {i18n.t('SUBMIT_BUTTON')}
                        </Button>
                    </Grid>
                </Grid>
            </Container>
            : <Container component="main" className={classes.content}>
                <CircularProgress/>
            </Container>
        }
        <SnackBarMessage
            content={requestSucceeded ? i18n.t('MAIL_SEND_SUCCESS') : failMessage ? i18n.t(failMessage) : ''}
            type={requestSucceeded ? SnackBarType.SUCCESS : SnackBarType.ERROR}
            isOpen={requestSucceeded || requestFailed}
        />
    </div>;
};

const mapStateToProps = (state: AppState): StateProps => {
    const {token, login} = state.login;
    return {token, login};

};

export default withStyles(styles)(connect(mapStateToProps, null)(withRouter(MailSend)));
