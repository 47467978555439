import React, {Component, Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {AppBar, DrawerMenu} from '../index';
import {TEST_CREATION_ROUTE} from './routes';
import {SnackBarMessage, TestCreationForm, TestSessionOverview} from '../../components';
import {CssBaseline, Paper, Typography} from '@material-ui/core';
import i18n from '../../i18n/i18n';
import {Connector} from '../../helpers/Connector';
import moment from 'moment';
import {appBar} from '../../styles';
import {SnackBarType} from '../../components/SnackBarMessage';
import {errorMessages} from '../../helpers/types';

const styles = theme => ({
    root: {
        display: 'flex'
    },
    appBar: {},
    paper: {
        width: '80%',
        marginTop: appBar.height,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2),
    },
    content: {
        marginTop: theme.spacing(3),
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    existingTests: {
        flexGrow: 1,
        marginTop: appBar.height,
        display: 'flex',
        flexDirection: 'row',
        width: '50%',
        overflow: 'auto',
    },
    testItem: {
        width: '23%',
        minWidth: '23%',
    }
});

class TestCreation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            existingTests: [],
            requestFailed: false,
            failMessage: '',
            types: [],
            isMenuOpen: false,
            requestSucceeded: false,
        };
    }

    componentDidMount() {
        if (!this.props.token.access) {
            this.props.history.push('/login');
        }

        this.fetchTests();
    }

    handleBurgerClick = () => {
        this.setState({isMenuOpen: !this.state.isMenuOpen});
    }

    fetchTests = () => {
        this.setState({failMessage: '', requestFailed: false}, () => {
            Connector.getInstance().getAllTests()
                .then(tests => {
                    if (!tests || tests === 'UNKNOWN_ERROR')
                        this.setState({requestFailed: true, failMessage: tests || 'UNKNOWN_ERROR'});
                    else
                        this.setState({existingTests: tests}, () => {
                            Connector.getInstance().getAllTypes()
                                .then(types => {
                                    if (types.length && !errorMessages.includes(types)) {
                                        this.setState({types: types});
                                    }
                                });
                        });
                })
                .catch(e => e.message === 'LOGOUT' && this.props.history.push('/login'));
        });
    };

    handleCreateSubmit = (testDate, capacity, minBirthYear, maxBirthYear, selectedGroups, selectedTypeId, location) => {
        this.setState({failMessage: '', requestFailed: false}, () => {
            const connector = Connector.getInstance();
            connector.createTest(testDate, capacity, minBirthYear, maxBirthYear, selectedTypeId, location)
                .then(test => {
                    if (test === 'BAD_REQUEST' || test === 'UNKNOWN_ERROR') {
                        this.setState({requestFailed: true, failMessage: test});
                    } else if (test) {
                        connector.setPossibleGroups(test.id, selectedGroups)
                            .then(ok => {
                                if (ok && test !== 'BAD_REQUEST' && test !== 'UNKNOWN_ERROR')
                                    this.setState({requestSucceeded: true}, () => {
                                        this.fetchTests();
                                    });
                                else
                                    this.setState({requestFailed: true, failMessage: ok || 'UNKNOWN_ERROR'});
                            });
                    }
                });
        });
    };

    handleEditClick = id => {
        this.props.history.push(`/test/${id}`, {testId: id});
    };

    handleStartTestClick = id => {
        this.props.history.push(`/test/${id}/start`, {testId: id});
    };

    handleLogout = () => this.props.history.push('/login');

    handleRequestFailed = (message) => this.setState({requestFailed: true, failMessage: message});

    render() {
        const {classes} = this.props;

        return (
            <Fragment>
                <CssBaseline/>
                <AppBar currentRoute={TEST_CREATION_ROUTE}
                    login={this.props.login}
                    onBurgerClick={this.handleBurgerClick}/>
                <DrawerMenu isOpen={this.state.isMenuOpen}/>
                <div className={classes.content}>
                    <div className={classes.existingTests}>
                        {
                            this.state.existingTests.map(test => {
                                return test.remaining > 0 ? (
                                    <TestSessionOverview
                                        logout={this.handleLogout}
                                        key={test.id}
                                        testId={test.id}
                                        date={moment(test.scheduledDate).format('DD/MM/YYYY')}
                                        time={test.scheduledTime}
                                        minBirthYear={test.minBirthYear}
                                        maxBirthYear={test.maxBirthYear}
                                        capacity={test.capacity}
                                        remaining={test.remaining}
                                        onEditClicked={this.handleEditClick}
                                        onStartTestClick={this.handleStartTestClick}
                                        onUpdate={this.fetchTests}
                                        onRequestFailed={this.handleRequestFailed}
                                        type={this.state.types.find(type => type.id === test.typeId)}
                                    />) : null;
                            })
                        }
                    </div>
                    <Paper className={classes.paper}>
                        <Typography component="h3" variant="h6">
                            {i18n.t('TEST_CREATION_TITLE')}
                        </Typography>
                        <TestCreationForm
                            onSubmit={this.handleCreateSubmit}
                            logout={this.handleLogout}
                            onRequestFailed={this.handleRequestFailed}
                        />
                    </Paper>
                </div>
                <SnackBarMessage
                    content={this.state.requestSucceeded
                        ? i18n.t('TEST_CREATION_SUCCEEDED')
                        : i18n.t(`TEST_CREATION_FAILED_${this.state.failMessage}`)}
                    type={this.state.requestSucceeded ? SnackBarType.SUCCESS : SnackBarType.ERROR}
                    isOpen={this.state.requestFailed || this.state.requestSucceeded}/>
            </Fragment>
        );

    }
}

const mapStateToProps = state => {
    const {token, login} = state.login;
    return {token, login};
};

export default withRouter(connect(mapStateToProps, null)(withStyles(styles)(TestCreation)));
