import React, {FunctionComponent} from 'react';
import {createStyles, CssBaseline, Grid, IconButton, Paper, Theme, Tooltip, Typography,} from '@material-ui/core';
import {AllGroupsResponse} from '../helpers/types';
import withStyles from '@material-ui/core/styles/withStyles';
import i18n from '../i18n/i18n';
import {Delete, Edit, Search, PlayArrow, Mail, Ballot} from '@material-ui/icons';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(0.5),
        padding: 0,
        flex: 0.3,
        minWidth: '24%'
    },
    title: {
        width: '100%',
        flex: 0.2,
        textAlign: 'center',
    },
    info: {
        flex: 0.1,
        width: '100%'
    },
    iconRow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
    }
});

type Props = {
    classes: { [key: string]: string };
    isAdmin: boolean;
    isSectionManager: boolean;
    group: AllGroupsResponse,
    onDetailsClick: (id: number) => void;
    onDeleteClick: (id: number) => void;
    onEditClick: (id: number) => void;
    onNewSessionClick: (id: number) => void;
    onMailClick: (id: number) => void;
    onSubGroupsClick: (id: number) => void;
}

const GroupOverview: FunctionComponent<Props> = ({
    classes,
    isAdmin,
    isSectionManager,
    group,
    onDetailsClick,
    onDeleteClick,
    onEditClick,
    onNewSessionClick,
    onMailClick,
    onSubGroupsClick,
}: Props) => {
    const handleDetailsClick = (id: number): void => {
        onDetailsClick(id);
    };

    const handleDeleteClick = (id: number): void => {
        onDeleteClick(id);
    };

    const handleEditClick = (id: number): void => {
        onEditClick(id);
    };

    const handleGoToNewSession = (id: number): void => {
        onNewSessionClick(id);
    };

    const handleMailClick = (id: number): void => {
        onMailClick(id);
    };

    const handleSubGroupsClick = (id: number): void => {
        onSubGroupsClick(id);
    };

    return (
        <Grid item sm={6} xs={12} key={group.id}>
            <React.Fragment>
                <CssBaseline/>
                <Paper className={classes.root}>
                    <div className={classes.testInfo}>
                        <Typography component="h3" variant="h6" className={classes.title}>
                            {group.name}
                        </Typography>
                        <Typography paragraph className={classes.info}>
                            {i18n.t('GROUP_COACH_DEFINITION', {
                                coachList: group.coaches.map(coach => `${coach.firstName} ${coach.lastName}`).join(', ')
                            })}
                        </Typography>
                        <Typography paragraph className={classes.info}>
                            {i18n.t('STUDENT_COUNT_DEFINITION', {
                                studentCount: group.studentCount,
                            })}
                        </Typography>
                        <div className={classes.iconRow}>
                            <Tooltip title={i18n.t('DETAILS_TOOLTIP')}>
                                <IconButton onClick={() => {
                                    handleDetailsClick(group.id);
                                }}>
                                    <Search />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={i18n.t('NEW_SESSION_TITLE')}>
                                <IconButton onClick={() => {
                                    handleGoToNewSession(group.id);
                                }}>
                                    <PlayArrow/>
                                </IconButton>
                            </Tooltip>
                            {isAdmin
                                ? <Tooltip title={i18n.t('DELETE_TOOLTIP')}>
                                    <IconButton onClick={() => {
                                        handleDeleteClick(group.id);
                                    }}>
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                                : null}
                            {isAdmin
                                ? <Tooltip title={i18n.t('EDIT_TOOLTIP')}>
                                    <IconButton onClick={() => {
                                        handleEditClick(group.id);
                                    }}>
                                        <Edit />
                                    </IconButton>
                                </Tooltip>
                                : null}
                            <Tooltip title={i18n.t('MAIL_TOOLTIP')}>
                                <IconButton onClick={() => {
                                    handleMailClick(group.id);
                                }}>
                                    <Mail />
                                </IconButton>
                            </Tooltip>
                            {isAdmin || isSectionManager
                                ? <Tooltip title={i18n.t('SUB_GROUPS_TOOLTIP')}>
                                    <IconButton onClick={() => {
                                        handleSubGroupsClick(group.id);
                                    }}>
                                        <Ballot />
                                    </IconButton>
                                </Tooltip>
                                : null}
                        </div>
                    </div>
                </Paper>
            </React.Fragment>
        </Grid>
    );
};

export default withStyles(styles)(GroupOverview);
