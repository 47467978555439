import React, {FunctionComponent} from 'react';
import {Button, Modal, Theme, Typography, withStyles} from '@material-ui/core';
import i18n from '../i18n/i18n';

const styles = (theme: Theme): object => ({
    root: {
        position: 'absolute',
        width: 400,
        height: 200,
        top: '50%',
        marginTop: -100,
        left: '50%',
        marginLeft: -200,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 4),
        display: 'flex',
        flexDirection: 'column',
        outline: 0,
    },
    title: {
        width: '100%',
        flex: 0.3,
    },
    message: {
        width: '100%',
        flex: 0.4
    },
    buttonView: {
        width: '100%',
        flex: 0.3,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
});

type Props = {
    classes: {[key: string]: string};
    onConfirm: () => void;
    onClose: () => void;
    message: string;
    isOpen: boolean;
}

const ConfirmModal: FunctionComponent<Props> = ({classes, onConfirm, onClose, isOpen, message}: Props) => {

    return <Modal open={isOpen} onClose={onClose}>
        <div className={classes.root}>
            <Typography component="h3" variant="h6" className={classes.title}>
                {i18n.t('CONFIRMATION_TITLE')}
            </Typography>
            <p className={classes.message}>
                {message}
            </p>
            <div className={classes.buttonView}>
                <Button variant="contained" color="default" onClick={onClose}>
                    {i18n.t('CANCEL_BUTTON')}
                </Button>
                <Button variant="contained" color="primary" onClick={onConfirm}>
                    {i18n.t('SUBMIT_BUTTON')}
                </Button>
            </div>
        </div>
    </Modal>;
};

export default withStyles(styles)(ConfirmModal);