import React, {Component, Fragment} from 'react';
import {withStyles} from '@material-ui/core/styles';
import i18n from '../i18n/i18n';
import {List, ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import {Assignment, Create, Search} from '@material-ui/icons';
import {menuItem, menuItemIcon, menuItemText, menuItemTitle} from '../styles';

const styles = () => ({});

class DrawerGroupItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            anchor: null
        };
    }

    handleClick = e => {
        this.setState({open: !this.state.open, anchor: e.currentTarget});
    };

    handleGroupCreateClick = () => {
        this.setState({open: false});
        this.props.onCreateGroupClick();
    };

    handleShowAllClick = () => {
        this.setState({open: false});
        this.props.onShowAllClick();
    };

    render() {
        const {readOnly} = this.props;
        return (
            <Fragment>
                <ListItem button onClick={this.handleClick} style={menuItem}>
                    <div style={menuItemTitle}>
                        <ListItemIcon style={menuItemIcon}><Assignment/></ListItemIcon>
                        <ListItemText style={menuItemText} primary={i18n.t('GROUP_DRAWER_ITEM')}/>
                    </div>
                    <div style={{
                        display: this.state.open ? 'block' : 'none',
                    }}>
                        <List>
                            {readOnly ? null :
                                <ListItem button onClick={this.handleGroupCreateClick}>
                                    <ListItemIcon><Create/></ListItemIcon>
                                    <ListItemText primary={i18n.t('GROUP_CREATION_TITLE')}/>
                                </ListItem>
                            }
                            <ListItem button onClick={this.handleShowAllClick}>
                                <ListItemIcon><Search/></ListItemIcon>
                                <ListItemText primary={i18n.t('SHOW_ALL_GROUPS_TITLE')}/>
                            </ListItem>
                        </List>
                    </div>
                </ListItem>
            </Fragment>
        );
    }
}

export default withStyles(styles)(DrawerGroupItem);
