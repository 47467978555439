import React, {ChangeEvent, FunctionComponent, useEffect, useState} from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {createStyles, withStyles} from '@material-ui/core/styles';
import {appBar} from '../../styles';
import {AppState, errorMessages, Token} from '../../helpers/types';
import {connect} from 'react-redux';
import {
    Button,
    CircularProgress,
    Container,
    CssBaseline,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@material-ui/core';
import AppBar from '../AppBar';
import {SUBSCRIPTION_DETAILS_ROUTE} from './routes';
import DrawerMenu from '../DrawerMenu';
import {Guardian, SubscriptionOverview} from '../../helpers/responseTypes';
import {Connector} from '../../helpers/Connector';
import i18n from '../../i18n/i18n';
import {RadioButtonChecked, RadioButtonUnchecked} from '@material-ui/icons';
import {SnackBarMessage} from '../../components';
import {SnackBarType} from '../../components/SnackBarMessage';

const styles = (theme: { spacing: (arg0: number) => number; }) => createStyles({
    root: {
        display: 'flex',
        width: '100%',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    content: {
        margin: 0,
        width: '100%',
        marginTop: appBar.height,
        paddingTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    filtersView: {
        width: '100%',
        padding: theme.spacing(2),
        flexDirection: 'row'
    },
    buttonsRow: {
        width: '100%',
        padding: theme.spacing(2),
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    input: {
        width: '40%',
        marginLeft: '5%',
        marginTop: 5,
        marginBottom: 5,
    },
    selected: {
        backgroundColor: '#b9b9b9',
    },
    notSelected: {
        backgroundColor: '#fff',
    }
});

type StateProps = {
    token: Token;
    login: string;
};

type Props = {
    classes: { [key: string]: string },
} & RouteComponentProps & StateProps;

const SubscriptionResolution: FunctionComponent<Props> = ({classes, token, login, location, history}: Props) => {
    const [studentId] = useState(parseInt(location.pathname.replace(/\/subscription\/problem\/([0-9]+)/, '$1')));
    const [isWorking, setIsWorking] = useState(false);
    const [student] = useState<SubscriptionOverview>(location.state as SubscriptionOverview);
    const [guardians, setGuardians] = useState<Guardian[]>([]);
    const [requestFailed, setRequestFailed] = useState(false);
    const [failMessage, setFailMessage] = useState('');
    const [selectedGuardianId, setSelectedGuardianId] = useState(0);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const [firstNameFilter, setFirstNameFilter] = useState('');
    const [lastNameFilter, setLastNameFilter] = useState('');
    const [emailFilter, setEmailFilter] = useState('');
    const [phoneFilter, setPhoneFilter] = useState('');

    useEffect(() => {
        if (!token.access) {
            history.push('/login');
        }
    }, [history, token.access]);

    useEffect(() => {
        setIsWorking(true);
        Connector.getInstance().getAllGuardians()
            .then(guardiansResponse => {
                if (guardiansResponse && !errorMessages.includes(guardiansResponse as string)) {
                    setGuardians(guardiansResponse as Guardian[]);
                } else {
                    setFailMessage(guardiansResponse as string || 'UNKNOWN_ERROR');
                    setRequestFailed(true);
                }
                setIsWorking(false);
            });
    }, []);

    const handleFirstNameFilterChange = (e: ChangeEvent<HTMLInputElement>) => setFirstNameFilter(e.target.value);

    const handleLastNameFilterChange = (e: ChangeEvent<HTMLInputElement>) => setLastNameFilter(e.target.value);

    const handleEmailFilterChange = (e: ChangeEvent<HTMLInputElement>) => setEmailFilter(e.target.value);

    const handlePhoneFilterChange = (e: ChangeEvent<HTMLInputElement>) => setPhoneFilter(e.target.value);

    const toggleSelected = (id: number) => {
        setSelectedGuardianId(id === selectedGuardianId ? 0 : id);
    };

    const handleCreateNewGuardianClick = () => {
        history.push('/guardian/new');
    };

    const handleSubmitClick = () => {
        setRequestFailed(false);
        setFailMessage('');
        Connector.getInstance().setGuardian(studentId, selectedGuardianId)
            .then(ok => {
                if (ok && !errorMessages.includes(ok as string)) {
                    history.goBack();
                } else {
                    setRequestFailed(true);
                    setFailMessage(ok as string || 'UNKNOWN_ERROR');
                }
            });
    };

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar login={login} currentRoute={SUBSCRIPTION_DETAILS_ROUTE} onBurgerClick={() => {
                setIsMenuOpen(!isMenuOpen);
            }}/>
            <DrawerMenu isOpen={isMenuOpen}/>
            {isWorking ? <Container component="main" className={classes.content}>
                <CircularProgress/>
            </Container> : <Container component="main" className={classes.content}>
                <Typography>{i18n.t('SUBSCRIPTION_TITLE', {
                    firstName: student.firstName,
                    lastName: student.lastName
                })}</Typography>
                <Paper className={classes.filtersView}>
                    <Typography>{i18n.t('FILTERS')}</Typography>
                    <TextField
                        className={classes.input}
                        variant="outlined"
                        id="firstName"
                        label={i18n.t('FIRST_NAME')}
                        name="firstName"
                        placeholder={i18n.t('FIRST_NAME')}
                        value={firstNameFilter}
                        onChange={handleFirstNameFilterChange}
                    />
                    <TextField
                        className={classes.input}
                        variant="outlined"
                        id="lastName"
                        label={i18n.t('LAST_NAME')}
                        name="lastName"
                        placeholder={i18n.t('LAST_NAME')}
                        value={lastNameFilter}
                        onChange={handleLastNameFilterChange}
                    />
                    <TextField
                        className={classes.input}
                        variant="outlined"
                        id="email"
                        label={i18n.t('EMAIL_FIELD')}
                        name="email"
                        placeholder={i18n.t('EMAIL_FIELD')}
                        value={emailFilter}
                        onChange={handleEmailFilterChange}
                    />
                    <TextField
                        className={classes.input}
                        variant="outlined"
                        id="phone"
                        label={i18n.t('PHONE_NUMBER_FIELD')}
                        name="phone"
                        placeholder={i18n.t('PHONE_NUMBER_FIELD')}
                        value={phoneFilter}
                        onChange={handlePhoneFilterChange}
                    />
                </Paper>
                <div className={classes.buttonsRow}>
                    <Button onClick={handleCreateNewGuardianClick} variant="contained">
                        {i18n.t('CREATE_NEW_GUARDIAN')}
                    </Button>
                    <Button disabled={selectedGuardianId === 0} onClick={handleSubmitClick} variant="contained"
                        color="primary">
                        {i18n.t('SUBMIT_BUTTON')}
                    </Button>
                </div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{i18n.t('SELECTION')}</TableCell>
                            <TableCell>{i18n.t('FIRST_NAME')}</TableCell>
                            <TableCell>{i18n.t('LAST_NAME')}</TableCell>
                            <TableCell>{i18n.t('EMAIL_FIELD')}</TableCell>
                            <TableCell>{i18n.t('PHONE_NUMBER_FIELD')}</TableCell>
                            <TableCell>{i18n.t('SECONDARY_EMAIL_FIELD')}</TableCell>
                            <TableCell>{i18n.t('SECONDARY_PHONE_NUMBER_FIELD')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {guardians.filter(guardian => firstNameFilter
                            ? guardian.firstName
                                ? new RegExp(`.*${firstNameFilter}.*`, 'i').test(guardian.firstName) : false : true)
                            .filter(guardian =>
                                lastNameFilter
                                    ? guardian.lastName
                                        ? new RegExp(`.*${lastNameFilter}.*`, 'i').test(guardian.lastName)
                                        : false : true)
                            .filter(guardian =>
                                emailFilter
                                    ? new RegExp(`.*${emailFilter}.*`, 'i').test(guardian.email) : true)
                            .filter(guardian =>
                                emailFilter
                                    ? new RegExp(`.*${emailFilter}.*`, 'i')
                                        .test(guardian.secondaryEmail || emailFilter) : true)
                            .filter(guardian =>
                                phoneFilter
                                    ? guardian.phoneNumber
                                        ? new RegExp(`.*${phoneFilter}.*`, 'i')
                                            .test(guardian.phoneNumber) : false : true)
                            .filter(guardian =>
                                phoneFilter
                                    ? new RegExp(`.*${phoneFilter}.*`, 'i')
                                        .test(guardian.secondaryPhoneNumber || phoneFilter) : true)
                            .map(guardian => <TableRow key={guardian.id}
                                className={selectedGuardianId === guardian.id ? classes.selected : classes.notSelected}>
                                <TableCell><IconButton onClick={() => {
                                    toggleSelected(guardian.id);
                                }}>{selectedGuardianId === guardian.id ? <RadioButtonChecked/> :
                                        <RadioButtonUnchecked/>}</IconButton></TableCell>
                                <TableCell>{guardian.firstName}</TableCell>
                                <TableCell>{guardian.lastName}</TableCell>
                                <TableCell>{guardian.email}</TableCell>
                                <TableCell>{guardian.phoneNumber}</TableCell>
                                <TableCell>{guardian.secondaryEmail}</TableCell>
                                <TableCell>{guardian.secondaryPhoneNumber}</TableCell>
                            </TableRow>)
                        }
                    </TableBody>
                </Table>
            </Container>}
            <SnackBarMessage isOpen={requestFailed} type={SnackBarType.ERROR} content={i18n.t(failMessage)}/>
        </div>
    );
};

const mapStateToProps = (state: AppState): StateProps => {
    const {token, login} = state.login;
    return {token, login};
};

export default withStyles(styles)(connect(mapStateToProps, null)(withRouter(SubscriptionResolution)));
