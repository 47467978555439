import React, {Fragment, FunctionComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {createStyles, Grid, List, ListItem, Theme, Typography} from '@material-ui/core';
import {TestSection} from '../helpers/responseTypes';


const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
    },
    groupList: {
        display: 'flex',
        flexDirection: 'row',
        maxWidth: '100%',
        overflow: 'auto',
    },
    groupName: {
        minWidth: '25%',
        marginRight: theme.spacing(1),
        height: '100%',
        minHeight: '100%',
        padding: 'auto',
        textAlign: 'center',
        border: '1px solid black',
        borderRadius: 4,
    },
});

type Props = {
    classes: { [key: string]: string };
    sections: TestSection[];
    onToggleSelected: (id: number) => void;
};

const TestGroupsList: FunctionComponent<Props> = ({classes, sections, onToggleSelected}: Props) => {
    const toggleSelected = (id: number): void => onToggleSelected(id);

    return <Fragment>
        <Grid container spacing={2}>
            {sections.map(section =>
                <Grid item xs={6} key={section.id}>
                    <Typography paragraph>{section.name}</Typography>
                    <List className={classes.groupList}>
                        {section.groups.sort((a, b) =>
                            a.name < b.name ? - 1 : a.name > b.name ? 1 : 0
                        ).map(group =>
                            <ListItem className={classes.groupName} button selected={group.selected} key={group.id}
                                onClick={() => {
                                    toggleSelected(group.id);
                                }}>{group.name}</ListItem>
                        )}
                    </List>
                </Grid>)}
        </Grid>
    </Fragment>;
};

export default withStyles(styles)(TestGroupsList);
