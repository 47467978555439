import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import i18n from '../i18n/i18n';
import {List, Typography, ListItem} from '@material-ui/core';
import moment from 'moment';
import {Connector} from '../helpers/Connector';

const styles = () => ({
    root: {
        display: 'flex',
    },
    testList: {},
    listItem: {
        display: 'flex',
        flexDirection: 'column',
    },
});

class TestAssignStudentForm extends Component {
    handleItemClick = async id => {
        const connector = Connector.getInstance();
        connector.assignStudentToTest(id, this.props.studentId)
            .then(ok => {
                if (ok !== undefined && ['BAD_REQUEST', 'NOT_FOUND', 'UNKNOWN_ERROR'].includes(ok))
                    this.props.onStudentAssigned(false, ok);
                else if (ok) {
                    this.props.onStudentAssigned(true);
                }
            })
            .catch(e => e.message === 'LOGOUT' && this.props.logout());
    };

    renderTestSession = () => this.props.testSessions.map(item => {
        return item.remaining > 0 ? (
            <ListItem button className={this.props.classes.listItem} key={item.id} onClick={async () => {
                await this.handleItemClick(item.id);
            }}>
                <Typography paragraph>{i18n.t('TEST_SESSION_LIST_ITEM', {
                    type: item.typeLabel ? i18n.t(item.typeLabel) : '',
                    scheduledDate: moment(item.scheduledDate).format('DD/MM/YYYY'),
                    scheduledTime: item.scheduledTime,
                    capacity: item.remaining,
                    minBirthYear: item.minBirthYear,
                    maxBirthYear: item.maxBirthYear,
                })}</Typography>
                <Typography paragraph>
                    {item.groups ? item.groups.join(', ') : ''}
                </Typography>
            </ListItem>
        ) : null;
    });

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                <Typography paragraph>
                </Typography>
                <List className={classes.testList}>
                    {this.renderTestSession()}
                </List>
            </div>
        );
    }
}

export default withStyles(styles)(TestAssignStudentForm);
