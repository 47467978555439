import React, {Component, Fragment} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {List, ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import {Create, Person, Search} from '@material-ui/icons';
import i18n from '../i18n/i18n';
import {menuItem, menuItemIcon, menuItemText, menuItemTitle} from '../styles';

const styles = () => ({});

class DrawerUserItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            anchor: null
        };
    }

    handleClick = e => {
        this.setState({open: !this.state.open, anchor: e.currentTarget});
    };

    handleUserCreateClick = () => {
        this.setState({open: false});
        this.props.onCreateUserClick();
    };

    handleShowAllClick = () => {
        this.setState({open: false});
        this.props.onShowAllClick();
    };

    render() {
        return (
            <Fragment>
                <ListItem button onClick={this.handleClick} style={menuItem}>
                    <div style={menuItemTitle}>
                        <div style={menuItemIcon}>
                            <ListItemIcon><Person/></ListItemIcon>
                        </div>
                        <div style={menuItemText}>
                            <ListItemText primary={i18n.t('USER_DRAWER_ITEM')}/>
                        </div>
                    </div>
                    <div style={{
                        display: this.state.open ? 'block' : 'none',
                    }}>
                        <List>
                            <ListItem button onClick={this.handleUserCreateClick}>
                                <ListItemIcon><Create/></ListItemIcon>
                                <ListItemText primary={i18n.t('USER_CREATION_TITLE')}/>
                            </ListItem>
                            <ListItem button onClick={this.handleShowAllClick}>
                                <ListItemIcon><Search/></ListItemIcon>
                                <ListItemText primary={i18n.t('SHOW_ALL_USERS_TITLE')}/>
                            </ListItem>
                        </List>
                    </div>
                </ListItem>
            </Fragment>
        );
    }
}

export default withStyles(styles)(DrawerUserItem);
