import {
    AssignStudentToSession,
    BulkAssignStudentToSession,
    CreateSession,
    EditStudentSession,
    Session,
    SESSION_ACTIONS,
    SessionActionFailed,
    SessionActions,
    SetSession,
    SetSessionDate,
    SetSessionGroup
} from './types';

const initialState: Session = {
    groupId: null,
    subGroupId: null,
    date: new Date(),
    name: '',
    students: [],
    request: {
        requested: false,
        succeeded: false,
        failed: false,
        failMessage: '',
    },
    isTransmitted: false,
};

export const session = (state = initialState,
    action: SessionActions): Session => {
    switch (action.type) {
    case SESSION_ACTIONS.ACTION_REQUESTED:
        return Object.assign({}, state, {
            request: {
                requested: true,
                succeeded: false,
                failed: false,
                failMessage: '',
            },
        });
    case SESSION_ACTIONS.ACTION_SUCCEEDED:
        return Object.assign({}, state, {
            request: {
                requested: false,
                succeeded: false,
                failed: false,
                failMessage: '',
            },
        });
    case SESSION_ACTIONS.ACTION_FAILED:
        return Object.assign({}, state, {
            request: {
                requested: false,
                succeeded: false,
                failed: true,
                failMessage: (action as SessionActionFailed).failMessage,
            },
        });
    case SESSION_ACTIONS.CREATE_SESSION: {
        const {date, name, groupId, subGroupId} = action as CreateSession;
        return Object.assign({}, state, {
            date,
            name,
            groupId,
            subGroupId,
            request: {
                requested: false,
                succeeded: true,
                failed: false,
                failMessage: '',
            },
            isTransmitted: false,
        });
    }
    case SESSION_ACTIONS.ASSIGN_STUDENT_TO_SESSION: {
        const {studentId, isLate, isAbsent, firstName, lastName} = action as AssignStudentToSession;
        const newStudents = state.students;
        if (!(newStudents.filter(student => student.id === studentId).length)) {
            newStudents.push({
                id: studentId, isLate, isAbsent, firstName, lastName,
            });
        }
        return Object.assign({}, state, {
            students: newStudents,
            request: {
                requested: false,
                succeeded: true,
                failed: false,
                failMessage: '',
            },
            isTransmitted: false,
        });
    }
    case SESSION_ACTIONS.EDIT_STUDENT_SESSION: {
        const {studentId, isLate, isAbsent} = action as EditStudentSession;
        const newStudents = state.students.map(student => {
            if (student.id === studentId) {
                student.isLate = isLate;
                student.isAbsent = isAbsent;
            }
            return student;
        });

        return Object.assign({}, state, {
            students: newStudents,
            request: {
                requested: false,
                succeeded: true,
                failed: false,
                failMessage: '',
            },
            isTransmitted: false,
        });
    }
    case SESSION_ACTIONS.SUBMIT_SESSION:
        return Object.assign({}, state, {
            request: {
                requested: false,
                succeeded: true,
                failed: false,
                failMessage: '',
            },
            isTransmitted: true,
        });
    case SESSION_ACTIONS.SET_SESSION_GROUP: {
        const {groupId, subGroupId, name} = action as SetSessionGroup;
        return Object.assign({}, state, {
            groupId,
            subGroupId,
            name,
        });
    }
    case SESSION_ACTIONS.CLEAR_STUDENTS:
        return Object.assign({}, state, {
            students: [],
        });
    case SESSION_ACTIONS.RESET_SESSION:
        return initialState;
    case SESSION_ACTIONS.SET_SESSION_DATE: {
        const {date} = action as SetSessionDate;
        return Object.assign({}, state, {
            date
        });
    }
    case SESSION_ACTIONS.BULK_ASSIGN_STUDENT_TO_SESSION: {
        const {students} = action as BulkAssignStudentToSession;
        return Object.assign({}, state, {
            students,
            request: {
                requested: false,
                succeeded: true,
                failed: false,
                failMessage: '',
            }
        });
    }
    case SESSION_ACTIONS.SET_SESSION: {
        const {session} = action as SetSession;
        return Object.assign({}, state, {
            groupId: session.groupId,
            subGroupId: session.subGroupId,
            date: session.date,
            name: session.name,
            students: session.students,
            request: {
                requested: false,
                succeeded: false,
                failed: false,
                failMessage: '',
            },
            isTransmitted: false,
        });
    }
    default:
        return state;
    }
};
