import {
    LOGIN_ACTIONS,
    LoginActions,
    LoginFailure,
    LoginRequest,
    LoginSuccess,
    LogoutRequest,
} from './types';
import {ThunkAction, ThunkDispatch} from 'redux-thunk';
import {Connector} from '../../helpers/Connector';
import {LoginSuccessResponse, Role, Token} from '../../helpers/responseTypes';
import {clear, set} from 'local-storage';


export const loginRequested = (login: string, password: string): LoginRequest => ({
    type: LOGIN_ACTIONS.LOGIN_REQUEST,
    login,
    password,
});

export const loginSuccess = (token: Token, roles: Role[]): LoginSuccess => ({
    type: LOGIN_ACTIONS.LOGIN_SUCCESS,
    token,
    roles,
});

export const loginFailure = (failMessage: string): LoginFailure => ({
    type: LOGIN_ACTIONS.LOGIN_FAILURE,
    failMessage,
});

export const logoutRequest = (): LogoutRequest => ({
    type: LOGIN_ACTIONS.LOGOUT,
});

export const login = (login: string, password: string): ThunkAction<void, unknown, unknown, LoginActions> =>
    (dispatch: ThunkDispatch<unknown, unknown, LoginActions>): void => {
        dispatch(loginRequested(login, password));
        Connector.getInstance().login(login, password)
            .then(response => {
                if ((response as LoginSuccessResponse)?.token) {
                    response = (response as LoginSuccessResponse);
                    dispatch(loginSuccess(response.token, response.roles));
                    set('access', response.token.access);
                    set('refresh', response.token.refresh);
                    set('roles', response.roles);
                    set('login', login);
                } else {
                    dispatch(loginFailure(response as string));
                }
            }).catch(e => {
                dispatch(loginFailure(e));
            });
    };

export const logout = (): ThunkAction<void, unknown, unknown, LoginActions> =>
    (dispatch: ThunkDispatch<unknown, unknown, LoginActions>): void => {
        dispatch(logoutRequest());
        clear();
    };