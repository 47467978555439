import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {FormControl, InputLabel, MenuItem, Select} from '@material-ui/core';
import i18n from '../i18n/i18n';
import {Connector} from '../helpers/Connector';

const styles = () => ({
    formSelect: {
        width: '100%',
    }
});

class SectionSelect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sectionList: [],
            selectedSection: 0
        };
    }

    componentDidMount() {
        const connector = Connector.getInstance();
        connector.getSections().then(sections => {
            this.setState({sectionList: sections, selectedSection: sections[0].id});
        }).catch(e => e.message === 'LOGOUT' && this.props.logout());
    }

    getSelected = () => this.state.sectionList.filter(section => section.id === this.state.selectedSection)[0];

    renderSections = () =>
        this.state.sectionList.map(section => <MenuItem value={section.id} key={section.id}>{section.name}</MenuItem>);

    handleSectionChange = e => this.setState({selectedSection: e.target.value});

    render() {
        const {classes} = this.props;

        return (
            <FormControl className={classes.formSelect}>
                <InputLabel htmlFor="maxBirthYear">{i18n.t('SECTION_LABEL')}</InputLabel>
                <Select
                    value={this.state.selectedSection}
                    onChange={this.handleSectionChange}
                    inputProps={{
                        name: 'section',
                        id: 'section'
                    }}
                    fullWidth
                >
                    {this.renderSections()}
                </Select>
            </FormControl>
        );
    }
}

export default withStyles(styles)(SectionSelect);
