import React from 'react';
import {
    CircularProgress,
    Container,
    CssBaseline,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField
} from '@material-ui/core';
import {KeyboardDatePicker} from '@material-ui/pickers';
import {withStyles} from '@material-ui/core/styles';
import * as moment from 'moment';
import ErrorView from './ErrorView';
import i18n from '../i18n/i18n';
import {countries} from '../helpers/countries.json';
import {isStudentMajor} from '../helpers/utils';

const styles = theme => ({
    form: {
        width: '100%', // Fix IE 11 issue (apparently)
        marginBottom: theme.spacing(3)
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'row'
    },
    radioItem: {
        flex: 0.5,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    formItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
    }
});

class StudentInfoForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fname: this.props.fname || '',
            lname: this.props.lname || '',
            gender: this.props.gender || 'M',
            birthDate: this.props.birthDate || new Date(),
            birthPlace: this.props.birthPlace || '',
            nationality: this.props.nationality || '',
            fnameError: false,
            lnameError: false,
            birthDateError: false,
            birthPlaceError: false,
            nationalityError: false,
            nationalityArray: null,
            shirtSize: '',
            shirtSizeArray: null,
            shortSizeArray: null,
            shirtSizeError: false,
            shortSizeError: false,
            shortSize: '',
            errorList: [],
            loaded: false,
            instagramHandle: '',
            school: this.props.school || '',
            schoolError: false,
            schoolLevel: this.props.schoolLevel || '',
            schoolLevelError: false,
        };

    }

    componentDidUpdate() {
        if (this.props.data && !this.state.loaded && Object.keys(this.props.data).length !== 0) {
            this.setState({
                fname: this.props.data.fname,
                lname: this.props.data.lname,
                gender: this.props.data.subscription.gender,
                birthDate: this.props.data.subscription.birthDate,
                birthPlace: this.props.data.subscription.birthPlace ?? '',
                nationality: this.props.data.subscription.nationality,
                loaded: true,
                instagramHandle: this.props.data.subscription.instagramHandle ?? '',
                school: this.props.data.subscription.school ?? '',
                schoolLevel: this.props.data.subscription.schoolLevel ?? '',
            });
        }
        if (this.props.shirtSizes && this.props.shirtSizes.length && !this.state.shirtSizeArray) {
            this.setState({
                shirtSizeArray: this.props.shirtSizes,
            });
        }
        if (this.props.shortSizes && this.props.shortSizes.length && !this.state.shortSizeArray) {
            this.setState({
                shortSizeArray: this.props.shortSizes,
            });
        }
    }

    componentDidMount() {
        this.setState({
            loaded: this.props.creation,
            nationalityArray: countries
        });
    }

    submitInfo = () => {
        const {
            fname,
            lname,
            gender,
            birthDate,
            birthPlace,
            nationality,
            shirtSize,
            shortSize,
            instagramHandle,
            school,
            schoolLevel,
        } = this.state;
        let errorList = [];
        if (this.isFnameValid()
            && this.isLnameValid()
            && (nationality !== '' || this.props.creation)
            && this.isBirthDateValid()
            && (birthPlace !== '' || this.props.creation)
            && (shirtSize !== '' || this.props.creation)
            && (shortSize !== '' || this.props.creation)
            && this.isGenderValid()
            && (school !== '' || this.props.creation || isStudentMajor(this.state.birthDate))
            && (schoolLevel !== '' || this.props.creation || isStudentMajor(this.state.birthDate))
        ) {
            return {fname, lname, gender, birthDate, birthPlace, nationality, instagramHandle, school, schoolLevel,
                shirtSizeId: this.props.creation
                    ? null : this.state.shirtSizeArray.filter(size => size.label === shirtSize)[0].id,
                shortSizeId: this.props.creation
                    ? null : this.state.shortSizeArray.filter(size => size.label === shortSize)[0].id};
        }
        if (!this.isFnameValid()) {
            errorList.push('FNAME_ERROR');
            this.setState({fnameError: true});
        }
        if (!this.isLnameValid()) {
            errorList.push('LNAME_ERROR');
            this.setState({lnameError: true});
        }
        if (nationality === '' && !this.props.creation) {
            errorList.push('NATIONALITY_ERROR');
            this.setState({nationalityError: true});
        }
        if (!this.isBirthDateValid()) {
            errorList.push('BIRTH_DATE_ERROR');
            this.setState({birthDateError: true});
        }
        if (birthPlace === '' && !this.props.creation) {
            errorList.push('BIRTH_PLACE_ERROR');
            this.setState({birthPlaceError: true});
        }
        if (!this.isGenderValid()) {
            errorList.push('GENDER_ERROR');
            this.setState({birthPlaceError: true});
        }
        if (shirtSize === '' && !this.props.creation) {
            errorList.push('SHIRT_SIZE_ERROR');
            this.setState({shirtSizeError: true});
        }
        if (shortSize === '' && !this.props.creation) {
            errorList.push('SHORT_SIZE_ERROR');
            this.setState({shortSizeError: true});
        }
        if (school === '' && !this.props.creation && !isStudentMajor(this.state.birthDate)) {
            errorList.push('SCHOOL_ERROR');
            this.setState({schoolError: true});
        }
        if (schoolLevel === '' && !this.props.creation && !isStudentMajor(this.state.birthDate)) {
            errorList.push('SCHOOL_LEVEL_ERROR');
            this.setState({schoolLevelError: true});
        }
        this.setState({errorList: errorList});
        return null;

    };

    handleFnameFocus = () =>this.setState({
        fnameError: false,
        errorList: this.state.errorList.filter(error => error !== 'FNAME_ERROR'),
    });
    handleLnameFocus = () => this.setState({
        lnameError: false,
        errorList: this.state.errorList.filter(error => error !== 'LNAME_ERROR'),
    });
    handleBirthDateFocus = () => this.setState({
        birthDateError: false,
        errorList: this.state.errorList.filter(error => error !== 'BIRTH_DATE_ERROR')
    });
    handleBirthPlaceFocus = () => this.setState({birthPlaceError: false});
    handleNationalityFocus = () => this.setState({nationalityError: false});

    handleShirtSizeFocus = () => this.setState({shirtSizeError: false});
    handleShortSizeFocus = () => this.setState({shortSizeError: false})

    handleSchoolFocus = () => this.setState({schoolError: false});
    handleSchoolLevelFocus = () => this.setState({schoolLevelError: false});

    isGenderValid = () => {
        return !!this.state.gender && (this.state.gender === 'M' || this.state.gender === 'F');
    }

    isFnameValid = () =>
        !(this.state.fname === '' || !(/^[a-zA-Z0-9\u00C0-\u024F\u1E00-\u1EFF \-'.(),]+$/g.test(this.state.fname)));
    isLnameValid = () =>
        !(this.state.lname === '' || !(/^[a-zA-Z0-9\u00C0-\u024F\u1E00-\u1EFF \-'.(),]+$/g.test(this.state.lname)));
    isBirthPlaceValid = () =>
        !(this.state.birthPlace === ''
            || !(/^[a-zA-Z0-9\u00C0-\u024F\u1E00-\u1EFF \-'.(),]+$/g.test(this.state.birthPlace)));
    isBirthDateValid = () =>
        (moment(this.state.birthDate).year() > 1940
            // eslint-disable-next-line no-undef
            && moment(this.state.birthDate).year() <= parseInt(process.env.REACT_APP_MAX_BIRTH_DATE));

    handleChangeDate = e => this.setState({birthDate: e});

    handleFnameBlur = () => {
        if (!this.isFnameValid()) {
            let newErrorList = this.state.errorList;
            if (!this.state.errorList.filter(error => error === 'FNAME_ERROR').length)
                newErrorList.push('FNAME_ERROR');
            this.setState({fnameError: true, errorList: newErrorList});
        }
    };
    handleLnameBlur = () => {
        if (!this.isLnameValid()) {
            let newErrorList = this.state.errorList;
            if (!this.state.errorList.filter(error => error === 'LNAME_ERROR').length)
                newErrorList.push('LNAME_ERROR');
            this.setState({lnameError: true, errorList: newErrorList});
        }
    };
    handleNationalityChange = event => {
        this.setState({nationality: event.target.value, nationalityError: event.target.value === ''});
    };

    handleShirtSizeChange = event => {
        this.setState({shirtSize: event.target.value, shirtSizeError: event.target.value === ''});
    }

    handleShortSizeChange = event => {
        this.setState({shortSize: event.target.value, shortSizeError: event.target.value === ''});
    }

    handleBirthPlaceChange = event => {
        this.setState({birthPlace: event.target.value, birthPlaceError: event.target.value === ''});
    }

    handleBirthPLaceBlur = () => {
        if (!this.isBirthPlaceValid()) {
            this.setState({birthPlaceError: true});
        }
    };

    isStudentAbove13 = () => {
        if (this.state.birthDate) {
            const todayDate = new Date();
            const socialMediaLimit = new Date();
            socialMediaLimit.setFullYear(todayDate.getFullYear() - 13);
            return socialMediaLimit > new Date(this.state.birthDate);
        }
        return false;
    }

    handleInstagramHandleChange = event => {
        this.setState({instagramHandle: event.target.value});
    }

    handleSchoolChange = event => {
        this.setState({school: event.target.value});
    }

    handleSchoolLevelChange = event => {
        this.setState({schoolLevel: event.target.value});
    }

    render() {
        const {classes} = this.props;

        return (
            <Container component="main" maxWidth="sm">
                <CssBaseline/>
                {this.state.loaded ? <form className={classes.form} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="fname"
                                name="firstName"
                                required
                                fullWidth
                                id="firstName"
                                label={i18n.t('FIRST_NAME_FIELD')}
                                value={this.state.fname}
                                onChange={e => this.setState({fname: e.target.value})}
                                onBlur={this.handleFnameBlur}
                                error={this.state.fnameError}
                                onFocus={this.handleFnameFocus}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="lname"
                                name="lastName"
                                required
                                fullWidth
                                id="lastName"
                                label={i18n.t('LAST_NAME_FIELD')}
                                value={this.state.lname}
                                onChange={e => this.setState({lname: e.target.value})}
                                onBlur={this.handleLnameBlur}
                                error={this.state.lnameError}
                                onFocus={this.handleLnameFocus}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <RadioGroup
                                aria-label={i18n.t('FORM_GENDER_TITLE')}
                                name="gender"
                                value={this.state.gender}
                                className={classes.radioGroup}
                                onChange={(e, value) => this.setState({gender: value === 'M' ? 'M' : 'F'})}
                            >
                                <FormControlLabel
                                    className={classes.radioItem}
                                    value="M"
                                    control={<Radio/>}
                                    label={i18n.t('GENDER_RADIO_M')}
                                />

                                <FormControlLabel
                                    className={classes.radioItem}
                                    value="F"
                                    control={<Radio/>}
                                    label={i18n.t('GENDER_RADIO_F')}
                                />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <KeyboardDatePicker
                                required
                                fullWidth
                                id="birthDate"
                                label={i18n.t('FORM_BIRTH_DATE')}
                                value={this.state.birthDate}
                                onChange={this.handleChangeDate}
                                error={this.state.birthDateError}
                                format="DD/MM/YYYY"
                                onFocus={this.handleBirthDateFocus}
                            />
                        </Grid>
                        {this.props.creation ? null : <Grid item xs={12} sm={6} className={classes.formItem}>
                            <FormControl fullWidth>
                                <InputLabel>{i18n.t('NATIONALITY_FIELD')}</InputLabel>
                                <Select
                                    fullWidth
                                    required
                                    value={this.state.nationality}
                                    onChange={this.handleNationalityChange}
                                    name="nationality"
                                    id="nationality"
                                    error={this.state.nationalityError}
                                    displayEmpty
                                    renderValue={v => v ? v : ''}
                                    onFocus={this.handleNationalityFocus}
                                >
                                    {this.state.nationalityArray ? this.state.nationalityArray.map((country, index) =>
                                        <MenuItem key={index.toString()} value={country}>{country}</MenuItem>) : null}
                                </Select>
                            </FormControl>
                        </Grid>}
                        {this.props.creation ? null : <Grid item xs={12} sm={6} className={classes.formItem}>
                            <TextField
                                autoComplete="birthPlace"
                                name="birthPlace"
                                required
                                fullWidth
                                id="birthPlace"
                                label={i18n.t('BIRTH_PLACE')}
                                value={this.state.birthPlace}
                                onChange={this.handleBirthPlaceChange}
                                onBlur={this.handleLnameBlur}
                                error={this.state.birthPlaceError}
                                onFocus={this.handleBirthPlaceFocus}
                            />
                        </Grid>}
                    </Grid>
                    {this.props.creation ? null :
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} className={classes.formItem}>
                                <FormControl fullWidth>
                                    <InputLabel>{i18n.t('SHIRT_SIZE_FIELD')}</InputLabel>
                                    <Select
                                        fullWidth
                                        required
                                        value={this.state.shirtSize}
                                        onChange={this.handleShirtSizeChange}
                                        name="shirtSize"
                                        id="shirtSize"
                                        error={this.state.shirtSizeError}
                                        displayEmpty
                                        renderValue={v => v ? v : ''}
                                        onFocus={this.handleShortSizeFocus}
                                    >
                                        {this.state.shirtSizeArray
                                            ? this.state.shirtSizeArray.map(shirtSize =>
                                                <MenuItem key={shirtSize.id.toString()}
                                                    value={shirtSize.label}>{shirtSize.label}</MenuItem>) : null}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.formItem}>
                                <FormControl fullWidth>
                                    <InputLabel>{i18n.t('SHORT_SIZE_FIELD')}</InputLabel>
                                    <Select
                                        fullWidth
                                        required
                                        value={this.state.shortSize}
                                        onChange={this.handleShortSizeChange}
                                        name="shirtSize"
                                        id="shirtSize"
                                        error={this.state.shortSizeError}
                                        displayEmpty
                                        renderValue={v => v ? v : ''}
                                        onFocus={this.handleShortSizeFocus}
                                    >
                                        {this.state.shortSizeArray
                                            ? this.state.shortSizeArray.map(shortSize =>
                                                <MenuItem key={shortSize.id.toString()}
                                                    value={shortSize.label}>{shortSize.label}</MenuItem>) : null}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {this.isStudentAbove13() ? <Grid item xs={12} sm={6} className={classes.formItem}>
                                <FormControl fullWidth>
                                    <TextField
                                        autoComplete="instagramHandle"
                                        name="instagramHandle"
                                        fullWidth
                                        id="instagramHandle"
                                        label={i18n.t('INSTAGRAM_HANDLE_FIELD')}
                                        value={this.state.instagramHandle}
                                        onChange={this.handleInstagramHandleChange}
                                    />
                                </FormControl>
                            </Grid> : ''}
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="school"
                                    name="school"
                                    required={!isStudentMajor(this.state.birthDate)}
                                    fullWidth
                                    id="school"
                                    label={i18n.t('SCHOOL_FIELD')}
                                    value={this.state.school}
                                    onChange={this.handleSchoolChange}
                                    error={this.state.schoolError}
                                    onFocus={this.handleSchoolFocus}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="schoolLevel"
                                    name="schoolLevel"
                                    required={!isStudentMajor(this.state.birthDate)}
                                    fullWidth
                                    id="schoolLevel"
                                    label={i18n.t('SCHOOL_LEVEL_FIELD')}
                                    value={this.state.schoolLevel}
                                    onChange={this.handleSchoolLevelChange}
                                    error={this.state.schoolLevelError}
                                    onFocus={this.handleSchoolLevelFocus}
                                />
                            </Grid>
                        </Grid>}
                    <ErrorView errorList={this.state.errorList}/>
                </form> : <CircularProgress/>}
            </Container>
        );
    }
}

export default withStyles(styles)(StudentInfoForm);
