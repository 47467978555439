import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import {AppBar, DrawerMenu} from '..';
import {CssBaseline, Grid, Paper, TextField, Typography, Button} from '@material-ui/core';
import i18n from '../../i18n/i18n';
import {ErrorView, CoachSelect, SnackBarMessage} from '../../components';
import {Connector} from '../../helpers/Connector';
import {appBar} from '../../styles';
import {SnackBarType} from '../../components/SnackBarMessage';
import {errorMessages} from '../../helpers/types';

const styles = theme => ({
    root: {
        display: 'flex',
    },
    content: {
        marginTop: theme.spacing(3),
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    paper: {
        width: '35%',
        marginTop: appBar.height,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2),
    },
});

class SectionCreation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: this.props.name || '',
            nameError: false,
            errorList: [],
            requestFailed: false,
            failMessage: '',
            isMenuOpen: false,
        };
    }

    componentDidMount() {
        if (!this.props.token.access) {
            this.props.history.push('/login');
        }
    }

    handleNameChange = e => this.setState({name: e.target.value});
    handleNameFocus = () => this.setState({nameError: false});
    isNameValid = () => (/^[0-9a-zA-Z\-éèàïâêö ']+$/g.test(this.state.name));
    handleNameBlur = () => this.setState({nameError: !this.isNameValid()});

    handleCancelClick = () => {
        this.props.history.push('/home');
    };

    handleSubmitClick = () => {
        const {name} = this.state;
        let errorList = [];
        if (!this.isNameValid()) {
            errorList.push('SECTION_NAME_ERROR');
            this.setState({nameError: true});
        } else {
            const coach = this.coachSelect.getSelected();
            if (coach) {
                this.setState({requestFailed: false, failMessage: ''}, () => {
                    Connector.getInstance().createSection(name, coach.id)
                        .then(section => {
                            if (section && !errorMessages.includes(section))
                                this.props.history.push(`/section/${section.id}`);
                            else
                                this.setState({requestFailed: true, failMessage: section || 'UNKNOWN_ERROR'});
                        })
                        .catch(e => e.message === 'LOGOUT' && this.props.history.push('/login'));
                });
            }
        }
        this.setState({errorList: errorList});
    };

    handleRequestFailed = (message) => this.setState({requestFailed: true, failMessage: message});

    render() {
        const {classes} = this.props;

        return (
            <div className={classes.root}>
                <CssBaseline/>
                <AppBar login={this.props.login} onBurgerClick={() => {
                    this.setState({isMenuOpen: !this.state.isMenuOpen});
                }}/>
                <DrawerMenu isOpen={this.state.isMenuOpen}/>
                <main className={classes.content}>
                    <Paper className={classes.paper}>
                        <Typography component="h3" variant="h6">
                            {i18n.t('SECTION_CREATION_TITLE')}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="name"
                                    label={i18n.t('SECTION_NAME_LABEL')}
                                    value={this.state.name}
                                    onChange={this.handleNameChange}
                                    error={this.state.nameError}
                                    onFocus={this.handleNameFocus}
                                    onBlur={this.handleNameBlur}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CoachSelect
                                    ref={ref => this.coachSelect = ref}
                                    onRequestFailed={this.handleRequestFailed}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button variant="contained" color="default" fullWidth onClick={this.handleCancelClick}>
                                    {i18n.t('CANCEL_BUTTON')}
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button variant="contained" color="primary" fullWidth onClick={this.handleSubmitClick}>
                                    {i18n.t('SUBMIT_BUTTON')}
                                </Button>
                            </Grid>
                        </Grid>
                        <ErrorView errorList={this.state.errorList}/>
                    </Paper>
                </main>
                <SnackBarMessage
                    content={i18n.t(this.state.failMessage)}
                    type={SnackBarType.ERROR}
                    isOpen={this.state.requestFailed}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {token, login} = state.login;
    return {token, login};
};

export default withStyles(styles)(withRouter(connect(mapStateToProps, null)(SectionCreation)));
