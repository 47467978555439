import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {List, ListItem, Typography} from '@material-ui/core';
import moment from 'moment';

const styles = () => ({
    root: {
        display: 'flex',
    },
    list: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    listItem: {
        justifyContent: 'center',
        alignItems: 'center',
    }
});

class StudentList extends Component {
    renderStudents = () => {
        return this.props.students.map(student =>
            <ListItem
                button key={student.id}
                className={this.props.classes.listItem}
                onClick={() => {
                    this.props.onClick(student);
                }}
            >
                <Typography paragraph>
                    {student.firstName} {student.lastName} {moment(student.Subscription.birthDate).format('DD/MM/YYYY')}
                </Typography>
            </ListItem>
        );
    };

    render() {
        const {classes} = this.props;
        return (
            <div className={this.props.className}>
                <List className={classes.list}>
                    {this.renderStudents()}
                </List>
            </div>
        );
    }
}

export default withStyles(styles)(StudentList);
