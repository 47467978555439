import React, {ChangeEvent, FunctionComponent, useEffect, useState} from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {createStyles, withStyles} from '@material-ui/core/styles';
import {appBar} from '../../styles';
import {AppState, errorMessages, Token} from '../../helpers/types';
import {connect} from 'react-redux';
import {
    Button,
    Container,
    CssBaseline,
    Grid,
    IconButton,
    Paper,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';
import {AppBar, DrawerMenu} from '../index';
import {CREATE_GUARDIAN_ROUTE} from './routes';
import i18n from '../../i18n/i18n';
import {Add} from '@material-ui/icons';
import {Connector} from '../../helpers/Connector';
import {SnackBarMessage} from '../../components';
import {SnackBarType} from '../../components/SnackBarMessage';

const styles = (theme: { spacing: (arg0: number) => number; }) => createStyles({
    root: {
        display: 'flex',
        width: '100%',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    content: {
        margin: 0,
        width: '100%',
        marginTop: appBar.height,
        paddingTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    form: {
        width: '100%',
        padding: theme.spacing(2),
    },
    buttonRow: {
        marginTop: theme.spacing(2),
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    }
});

type StateProps = {
    token: Token;
    login: string;
}

type Props = {
    classes: { [key: string]: string };
} & StateProps & RouteComponentProps;

const GuardianCreation: FunctionComponent<Props> = ({classes, token, login, history}: Props) => {
    const [firstName, setFirstName] = useState('');
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastName, setLastName] = useState('');
    const [lastNameError, setLastNameError] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [secondaryPhoneNumber, setSecondaryPhoneNumber] = useState('');
    const [secondaryPhoneNumberError, setSecondaryPhoneNumberError] = useState(false);
    const [secondaryEmail, setSecondaryEmail] = useState('');
    const [secondaryEmailError, setSecondaryEmailError] = useState(false);
    const [showSecondEmail, setShowSecondEmail] = useState(false);
    const [requestFailed, setRequestFailed] = useState(false);
    const [failMessage, setFailMessage] = useState('');
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        if (!token.access) {
            history.push('/login');
        }
    }, [history, token.access]);

    const handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value);

    const handleLastNameChange = (e: ChangeEvent<HTMLInputElement>) => setLastName(e.target.value);

    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);

    const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => setPhoneNumber(e.target.value);

    const handleSecondEmailChange = (e: ChangeEvent<HTMLInputElement>) => setSecondaryEmail(e.target.value);

    const handleSecondPhoneChange = (e: ChangeEvent<HTMLInputElement>) => setSecondaryPhoneNumber(e.target.value);

    const isStringValid = (value: string): boolean => /^[a-zA-Z0-9\u00C0-\u024F\u1E00-\u1EFF \-'.(),]+$/g.test(value);

    const isEmailValid = (value: string): boolean => /^[^@!]+@[^@!]+\.[a-z]{2,64}$/.test(value);

    const isPhoneNumberValid = (value: string): boolean => /^(0|00|\+)?[0-9]{8,20}$/.test(value);

    const handleResetClick = () => {
        setFirstName('');
        setFirstNameError(false);
        setLastName('');
        setLastNameError(false);
        setEmail('');
        setEmailError(false);
        setPhoneNumber('');
        setPhoneNumberError(false);
        setShowSecondEmail(false);
        setSecondaryPhoneNumber('');
        setSecondaryPhoneNumberError(false);
        setSecondaryEmail('');
        setSecondaryEmailError(false);
    };

    const handleCancelClick = () => history.goBack();

    const handleSubmitClick = () => {
        Connector.getInstance().createGuardian({
            firstName,
            lastName,
            email,
            phoneNumber,
            secondaryEmail,
            secondaryPhoneNumber
        }).then(ok => {
            if (ok && !errorMessages.includes(ok as string))
                history.goBack();
            else {
                setRequestFailed(true);
                setFailMessage(ok as string || 'UNKNOWN_ERROR');
            }
        });
    };

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar login={login} currentRoute={CREATE_GUARDIAN_ROUTE} onBurgerClick={() => {
                setIsMenuOpen(!isMenuOpen);
            }}/>
            <DrawerMenu isOpen={isMenuOpen}/>
            <Container className={classes.content}>
                <Typography component="h3" variant="h6">
                    {i18n.t('CREATE_NEW_GUARDIAN')}
                </Typography>
                <Paper className={classes.form}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="firstName"
                                required
                                fullWidth
                                id="firstName"
                                label={i18n.t('FIRST_NAME_FIELD')}
                                value={firstName}
                                onChange={handleFirstNameChange}
                                error={firstNameError}
                                onBlur={() => setFirstNameError(firstName ? !isStringValid(firstName) : false)}
                                onFocus={() => setFirstNameError(false)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="LastName"
                                required
                                fullWidth
                                id="lastName"
                                label={i18n.t('LAST_NAME_FIELD')}
                                value={lastName}
                                onChange={handleLastNameChange}
                                error={lastNameError}
                                onBlur={() => setLastNameError(lastName ? !isStringValid(lastName) : false)}
                                onFocus={() => setLastNameError(false)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="email"
                                required
                                fullWidth
                                id="email"
                                label={i18n.t('EMAIL_FIELD')}
                                value={email}
                                onChange={handleEmailChange}
                                error={emailError}
                                onBlur={() => setEmailError(!isEmailValid(email))}
                                onFocus={() => setEmailError(false)}
                            />
                        </Grid>
                        <Grid item xs={showSecondEmail ? 12 : 11} sm={showSecondEmail ? 6 : 5}>
                            <TextField
                                name="phoneNumber"
                                required
                                fullWidth
                                id="phoneNumber"
                                label={i18n.t('PHONE_NUMBER_FIELD')}
                                value={phoneNumber}
                                onChange={handlePhoneChange}
                                error={phoneNumberError}
                                onBlur={() =>
                                    setPhoneNumberError(phoneNumber ? !isPhoneNumberValid(phoneNumber) : false)}
                                onFocus={() => setPhoneNumberError(false)}
                            />
                        </Grid>
                        {showSecondEmail ? (
                            <React.Fragment>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="secondEmail"
                                        fullWidth
                                        id="secondEmail"
                                        label={i18n.t('EMAIL_FIELD')}
                                        value={secondaryEmail}
                                        onChange={handleSecondEmailChange}
                                        error={secondaryEmailError}
                                        onBlur={() =>
                                            setSecondaryEmailError(secondaryEmail
                                                ? !isEmailValid(secondaryEmail)
                                                : false)}
                                        onFocus={() => setSecondaryEmailError(false)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="secondPhoneNumber"
                                        fullWidth
                                        id="secondPhoneNumber"
                                        label={i18n.t('PHONE_NUMBER_FIELD')}
                                        value={secondaryPhoneNumber}
                                        onChange={handleSecondPhoneChange}
                                        error={secondaryPhoneNumberError}
                                        onBlur={() =>
                                            setSecondaryPhoneNumberError(secondaryPhoneNumber
                                                ? !isPhoneNumberValid(secondaryPhoneNumber)
                                                : false)}
                                        onFocus={() => setSecondaryPhoneNumberError(false)}
                                    />
                                </Grid>
                            </React.Fragment>
                        ) : (
                            <Grid item xs={1} sm={1}>
                                <Tooltip title={i18n.t('ADD_CONTACT_TOOLTIP')} placement="right">
                                    <IconButton onClick={() => setShowSecondEmail(!showSecondEmail)}>
                                        <Add/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        )}
                    </Grid>
                </Paper>
                <div className={classes.buttonRow}>
                    <Button className={classes.button} variant="contained" onClick={handleResetClick}>
                        {i18n.t('RESET_FORM_BUTTTON')}
                    </Button>
                    <Button className={classes.button} variant="contained" onClick={handleCancelClick}>
                        {i18n.t('CANCEL_BUTTON')}
                    </Button>
                    <Button className={classes.button}
                        variant="contained"
                        color="primary"
                        disabled={
                            (firstName ? !isStringValid(firstName) : false)
                                || (lastName ? !isStringValid(lastName) : false)
                                || !isEmailValid(email)
                                || (phoneNumber ? !isPhoneNumberValid(phoneNumber) : false)
                                || (secondaryEmail ? !isEmailValid(secondaryEmail) : false)
                                || (secondaryPhoneNumber ? !isPhoneNumberValid(secondaryPhoneNumber) : false)}
                        onClick={handleSubmitClick}>
                        {i18n.t('SUBMIT_BUTTON')}
                    </Button>
                </div>
            </Container>
            <SnackBarMessage isOpen={requestFailed} type={SnackBarType.ERROR} content={failMessage}/>
        </div>
    );
};

const mapStateToProps = (state: AppState): StateProps => {
    const {token, login} = state.login;
    return {token, login};
};

export default withStyles(styles)(connect(mapStateToProps, null)(withRouter(GuardianCreation)));
