import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Button, CssBaseline, Grid, TextField} from '@material-ui/core';
import StudentInfoForm from './StudentInfoForm';
import i18n from '../i18n/i18n';
import ErrorView from './ErrorView';

const styles = theme => ({
    root: {
        display: 'flex',
    },
    paper: {
        width: '80%',
        flexGrow: 1,
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    form: {
        width: '100%',
    },
    buttonView: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    button: {
        margin: theme.spacing(2)
    },
    emailInput: {
        width: '90%',
        marginLeft: '5%',
    }
});

class CreateStudentForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fname: '',
            lname: '',
            gender: '',
            birthDate: new Date(),
            nationality: '',
            email: '',
            emailError: false,
            phoneNumber: '',
            phoneNumberError: false,
            errorList: [],
        };
    }

    handleCancelClick = () => {
        this.setState({
            fname: '',
            lname: '',
            gender: '',
            birthDate: new Date(),
            nationality: '',
            email: '',
            phoneNumber: '',
        });
    };

    handleSubmitClick = async () => {
        let info = this.form.submitInfo();
        if (!this.isEmailValid() || !this.isPhoneNumberValid()) {
            let errorList = [];
            if (!this.isEmailValid()) {
                this.setState({emailError: true});
                errorList.push('EMAIL_ERROR');
            }
            if (!this.isPhoneNumberValid()) {
                this.setState({phoneNumberError: true});
                errorList.push('PHONE_ERROR');
            }
            this.setState({errorList: errorList});
            return;
        }
        if (info) {
            info = Object.assign(info, {guardianEmail: this.state.email, guardianPhoneNumber: this.state.phoneNumber});
            this.props.onSubmit(info);
        }
    };

    isEmailValid = () => /^[^@!]+@[^@!]+\.[a-z]{2,64}$/.test(this.state.email);

    handleEmailFocus = () => this.setState({emailError: false});
    handleEmailChange = e => this.setState({email: e.target.value});
    handleEmailBlur = () => this.setState({emailError: !this.isEmailValid()});

    isPhoneNumberValid = () => /^(0|00|\+)?[0-9]{8,20}$/.test(this.state.phoneNumber);

    handlePhoneNumberFocus = () => this.setState({phoneNumberError: false});
    handlePhoneNumberChange = e => this.setState({phoneNumber: e.target.value});
    handlePhoneNumberBlur = () => this.setState({phoneNumberError: !this.isPhoneNumberValid()})


    render() {
        const {classes} = this.props;

        return (
            <div className={classes.root}>
                <CssBaseline/>
                <div className={classes.paper}>
                    <StudentInfoForm creation={true} {...this.state} ref={ref => this.form = ref}/>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                className={classes.emailInput}
                                fullWidth
                                required
                                name="email"
                                label={i18n.t('EMAIL_FIELD')}
                                value={this.state.email}
                                error={this.state.emailError}
                                onChange={this.handleEmailChange}
                                onFocus={this.handleEmailFocus}
                                onBlur={this.handleEmailBlur}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                className={classes.emailInput}
                                fullWidth
                                required
                                name="phoneNumber"
                                label={i18n.t('PHONE_NUMBER_FIELD')}
                                value={this.state.phoneNumber}
                                error={this.state.phoneNumberError}
                                onChange={this.handlePhoneNumberChange}
                                onFocus={this.handlePhoneNumberFocus}
                                onBlur={this.handlePhoneNumberBlur}
                            />
                        </Grid>
                    </Grid>
                    <ErrorView errorList={this.state.errorList}/>
                    <div className={classes.buttonView}>
                        <Button
                            variant="contained"
                            color="default"
                            className={classes.button}
                            onClick={this.handleCancelClick}
                        >
                            {i18n.t('CANCEL_BUTTON')}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={this.handleSubmitClick}
                        >
                            {i18n.t('SUBMIT_BUTTON')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(CreateStudentForm);
