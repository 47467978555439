import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {AppBar, DrawerMenu} from '..';
import {TEST_EDIT_ROUTE} from './routes';
import {
    Button,
    CssBaseline,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography
} from '@material-ui/core';
import {KeyboardDateTimePicker} from '@material-ui/pickers';
import i18n from '../../i18n/i18n';
import {Connector} from '../../helpers/Connector';
import moment from 'moment';
import {SnackBarMessage, StudentList, TestGroupsList} from '../../components';
import {drawerWidth} from '../DrawerMenu';
import {appBar} from '../../styles';
import {SnackBarType} from '../../components/SnackBarMessage';
import {errorMessages} from '../../helpers/types';

const styles = theme => {
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
        },
        content: {
            marginTop: appBar.height,
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: window.outerWidth - drawerWidth,
            marginLeft: drawerWidth
        },
        paper: {
            margin: theme.spacing(3),
            padding: theme.spacing(2)
        },
        formSelect: {
            width: '100%'
        },
        students: {
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
        },
        studentList: {
            display: 'flex',
            justifyContent: 'center',
            flex: 0.5,
            height: '100%',
        },
    };
};

class TestEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            capacity: 0,
            testDate: null,
            // eslint-disable-next-line no-undef
            minBirthYear: process.env.REACT_APP_MAX_BIRTH_DATE,
            // eslint-disable-next-line no-undef
            maxBirthYear: process.env.REACT_APP_MAX_BIRTH_DATE,
            capacityError: false,
            testDateError: false,
            birthYearError: false,
            unassignedStudents: [],
            testStudents: [],
            requestFailed: false,
            requestSucceeded: false,
            failMessage: '',
            possibleGroups: [],
            groupIds: [],
            possibleTypes: [],
            selectedType: 0,
            location: '',
            locationError: false,
            isMenuOpen: false,
        };
    }

    componentDidMount() {
        if (!this.props.token.access) {
            this.props.history.push('/login');
        }
        const connector = Connector.getInstance();
        connector.getAllTypes()
            .then(types => {
                if (types.length && !errorMessages.includes(types)) {
                    this.setState({
                        possibleTypes: types,
                        selectedType: types[0].id,
                    }, () => {
                        connector.getTest(this.props.location.state.testId).then(test => {
                            if (test && test.id) {
                                this.setState({
                                    capacity: test.capacity,
                                    testDate: moment(`${test.scheduledDate}`),
                                    minBirthYear: test.minBirthYear,
                                    maxBirthYear: test.maxBirthYear,
                                    selectedType: test.typeId,
                                    location: test.location,
                                }, () => {
                                    connector.getUnassignedStudents(this.props.location.state.testId)
                                        .then(students => {
                                            if (students && students !== 'UNKNOWN_ERROR') {
                                                this.setState({unassignedStudents: students});
                                                connector.getTestStudents(this.props.location.state.testId)
                                                    .then(testStudents => {
                                                        if (testStudents
                                                            && !['BAD_REQUEST', 'UNKNOWN_ERROR']
                                                                .includes(testStudents)) {
                                                            this.setState({testStudents: testStudents});
                                                            connector.getAllTestGroups(test.id).then(sections => {
                                                                if (sections && !errorMessages.includes(sections)) {
                                                                    let groupIds = [];
                                                                    sections.forEach(section => {
                                                                        section.groups.forEach(group => {
                                                                            if (group.selected) {
                                                                                groupIds.push(group.id);
                                                                            }
                                                                        });
                                                                    });
                                                                    this.setState({possibleGroups: sections, groupIds});
                                                                } else {
                                                                    this.setState({
                                                                        requestFailed: true,
                                                                        failMessage: sections || 'UNKNOWN_ERROR'
                                                                    });
                                                                }
                                                            });
                                                        } else {
                                                            this.setState({
                                                                requestFailed: true,
                                                                failMessage: testStudents || 'UNKNOWN_ERROR'
                                                            });
                                                        }
                                                    });
                                            } else {
                                                this.setState({
                                                    requestFailed: true,
                                                    failMessage: test || 'UNKNOWN_ERROR'
                                                });
                                            }
                                        });

                                });
                            } else {
                                this.setState({requestFailed: true, failMessage: test || 'UNKNOWN_ERROR'});
                            }
                        });
                    });
                }
            });
    }

    handleCancel = () => {
        this.props.history.goBack();
    };

    handleSubmit = async () => {
        let error = false;
        if (this.state.capacity <= 0) {
            this.setState({capacityError: true});
            error = true;
        }
        if (new Date(this.state.testDate).getTime() <= new Date().getTime()) {
            this.setState({testDateError: true});
            error = true;
        }
        if (this.state.location === '') {
            this.setState({locationError: true});
            error = true;
        }

        if (this.state.minBirthYear < new Date().getFullYear() - 80
            // eslint-disable-next-line no-undef
            || this.state.minBirthYear > process.env.REACT_APP_MAX_BIRTH_DATE
            || this.state.maxBirthYear < new Date().getFullYear() - 80
            // eslint-disable-next-line no-undef
            || this.state.maxBirthYear > process.env.REACT_APP_MAX_BIRTH_DATE
            || this.state.minBirthYear > this.state.maxBirthYear) {
            this.setState({birthYearError: true});
        }
        if (!error) {
            const connector = Connector.getInstance();
            try {
                let response = await connector.editTest({
                    id: this.props.location.state.testId,
                    scheduledDate: this.state.testDate,
                    capacity: this.state.capacity,
                    minBirthYear: this.state.minBirthYear,
                    maxBirthYear: this.state.maxBirthYear,
                    typeId: this.state.selectedType,
                    location: this.state.location,
                });
                if (response && !['BAD_REQUEST', 'NOT_FOUND', 'UNKNOWN_ERROR'].includes(response)) {
                    response = await connector.setPossibleGroups(this.props.location.state.testId, this.state.groupIds);
                    if (response && !['BAD_REQUEST', 'NOT_FOUND', 'UNKNOWN_ERROR'].includes(response)) {
                        this.props.history.goBack();
                    } else {
                        this.setState({requestFailed: true, failMessage: response || 'UNKNOWN_ERROR'});
                    }
                } else {
                    this.setState({requestFailed: true, failMessage: response || 'UNKNOWN_ERROR'});
                }
            } catch (e) {
                console.error(e);
            }
        }
    };

    handleDateChange = e => this.setState({testDate: e});
    handleCapacityChange = e => this.setState({capacity: e.target.value});
    handleMinBirthYearChange = e => this.setState({minBirthYear: e.target.value});
    handleMaxBirthYearChange = e => this.setState({maxBirthYear: e.target.value});
    handleSelectedTypeChanged = e => this.setState({selectedType: e.target.value});
    handleLocationChanged = e => this.setState({location: e.target.value});

    renderBirthYears = () => {
        const years = [];
        // eslint-disable-next-line no-undef
        for (let i = process.env.REACT_APP_MAX_BIRTH_DATE; i > new Date().getFullYear() - 80; i--) {
            years.push(i);
        }

        return years.map(item => (<MenuItem value={item} key={item}>{item}</MenuItem>));
    };

    handleUnassignedClick = student => {

        if (this.state.testStudents.length < this.state.capacity) {
            const connector = Connector.getInstance();
            connector.assignStudentToTest(this.props.location.state.testId, student.id, false).then(() => {
                const newTestStudents = this.state.testStudents.concat(student);
                const newUnassignedStudents = this.state.unassignedStudents.filter(item => item.id !== student.id);

                this.setState({testStudents: newTestStudents, unassignedStudents: newUnassignedStudents});
            });
        }
    };

    handleAssignedClick = student => {
        this.setState({requestFailed: false, failMessage: ''}, () => {
            Connector.getInstance().removeStudentFromTest(this.props.location.state.testId, student.id).then(ok => {
                if (ok && !errorMessages.includes(ok)) {
                    const newUnassignedStudent = this.state.unassignedStudents.concat(student);
                    const newTestStudents = this.state.testStudents.filter(item => item.id !== student.id);
                    this.setState({testStudents: newTestStudents, unassignedStudents: newUnassignedStudent});
                } else {
                    this.setState({requestFailed: true, failMessage: 'UNKNOWN_ERROR'});
                }
            });
        });
    };

    handleRequestFailed = (message) => this.setState({requestFailed: true, failMessage: message});

    isGroupSelected = (id) => this.state.groupIds.filter(selected => selected === id)[0] !== undefined;

    handleToggleSelected = (id) => {
        const newSelected = [];
        if (this.isGroupSelected(id)) {
            this.state.groupIds.map((selected) => {
                if (selected !== id)
                    newSelected.push(selected);
                return selected;
            });
        } else {
            this.state.groupIds.map(selected => newSelected.push(selected));
            newSelected.push(id);
        }

        this.setState({
            possibleGroups: this.state.possibleGroups.map(section => {
                return {
                    id: section.id,
                    name: section.name,
                    groups: section.groups.map(group => {
                        return {
                            id: group.id,
                            name: group.name,
                            selected: group.id === id ? !this.isGroupSelected(id) : group.selected,
                        };
                    }),
                };
            }),
            groupIds: newSelected
        });
    };

    handleSendMail = () => {
        this.setState({requestFailed: '', failMessage: ''}, () => {
            Connector.getInstance().sendMailsForTest(this.props.location.state.testId)
                .then(ok => {
                    if (ok && !errorMessages.includes(ok)) {
                        this.setState({requestSucceeded: true}, () => {
                            setTimeout(() => {
                                this.setState({requestSucceeded: false});
                            }, 6000);
                        });
                    } else {
                        this.setState({requestFailed: true, failMessage: ok || 'UNKNOWN_ERROR'});
                    }
                });
        });
    };

    renderTypes = () => this.state.possibleTypes.length
        ? this.state.possibleTypes.map(type =>
            <MenuItem value={type.id}
                key={type.id}>{i18n.t(type.label)}</MenuItem>)
        : null;

    render() {
        const {classes} = this.props;

        return (
            <div className={classes.root}>
                <CssBaseline/>
                <AppBar currentRoute={TEST_EDIT_ROUTE} login={this.props.login}
                    onBurgerClick={() => this.setState({isMenuOpen: !this.state.isMenuOpen})}/>
                <DrawerMenu isOpen={this.state.isMenuOpen}/>
                <main className={classes.content}>
                    <Paper className={classes.paper}>
                        <Typography component="h3" variant="h6">
                            {i18n.t('EDIT_TEST_TITLE')}
                        </Typography>
                        <form className={classes.form} noValidate>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <KeyboardDateTimePicker
                                        required
                                        fullWidth
                                        id="testDate"
                                        label={i18n.t('TEST_DATETIME_LABEL')}
                                        value={this.state.testDate}
                                        onChange={this.handleDateChange}
                                        error={this.state.testDateError}
                                        format="DD/MM/YYYY HH:mm"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="capacity"
                                        label={i18n.t('TEST_CAPACITY_LABEL')}
                                        type="number"
                                        value={this.state.capacity}
                                        onChange={this.handleCapacityChange}
                                        error={this.state.capacityError}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={6} sm={6}>
                                    <FormControl className={classes.formSelect}>
                                        <InputLabel htmlFor="minBirthYear">{i18n.t('MIN_BIRTH_YEAR_LABEL')}</InputLabel>
                                        <Select
                                            error={this.state.birthYearError}
                                            value={this.state.minBirthYear}
                                            onChange={this.handleMinBirthYearChange}
                                            inputProps={{
                                                name: 'minBirthYear',
                                                id: 'minBirthYear'
                                            }}
                                        >
                                            {this.renderBirthYears()}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <FormControl className={classes.formSelect}>
                                        <InputLabel htmlFor="minBirthYear">{i18n.t('MAX_BIRTH_YEAR_LABEL')}</InputLabel>
                                        <Select
                                            error={this.state.birthYearError}
                                            value={this.state.maxBirthYear}
                                            onChange={this.handleMaxBirthYearChange}
                                            inputProps={{
                                                name: 'maxBirthYear',
                                                id: 'maxBirthYear'
                                            }}
                                        >
                                            {this.renderBirthYears()}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl className={classes.formSelect}>
                                        <InputLabel htmlFor="selectedType">{i18n.t('TEST_TYPE_LABEL')}</InputLabel>
                                        <Select
                                            value={this.state.selectedType || ''}
                                            onChange={this.handleSelectedTypeChanged}
                                            inputProps={{
                                                name: 'selectedType',
                                                id: 'selectedType'
                                            }}
                                        >
                                            {this.renderTypes()}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl className={classes.formSelect}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="location"
                                            label={i18n.t('TEST_LOCATION_LABEL')}
                                            value={this.state.location}
                                            onChange={this.handleLocationChanged}
                                            error={this.state.locationError}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <TestGroupsList sections={this.state.possibleGroups}
                                onToggleSelected={this.handleToggleSelected}/>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="default"
                                        className={classes.submit}
                                        onClick={this.handleSendMail}
                                        disabled={this.props.disabled}
                                    >
                                        {i18n.t('SEND_MAIL_BUTTON')}
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="default"
                                        className={classes.submit}
                                        onClick={this.handleCancel}
                                        disabled={this.props.disabled}
                                    >
                                        {i18n.t('CANCEL_BUTTON')}
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                        onClick={this.handleSubmit}
                                        disabled={this.props.disabled}
                                    >
                                        {i18n.t('SUBMIT_BUTTON')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                    <Typography>{i18n.t('TEST_REMAINING_SLOTS', {
                        remaining: this.state.capacity - this.state.testStudents.length,
                        capacity: this.state.capacity,
                    })}</Typography>
                    <div className={classes.students}>
                        <StudentList className={classes.studentList} students={this.state.unassignedStudents}
                            onClick={this.handleUnassignedClick}/>
                        <StudentList className={classes.studentList} students={this.state.testStudents}
                            onClick={this.handleAssignedClick}/>
                    </div>
                </main>
                <SnackBarMessage
                    content={this.state.requestFailed
                        ? i18n.t(`TEST_EDIT_FAILED_${this.state.failMessage}`)
                        : i18n.t('SEND_MAIL_FOR_TEST_SUCCESS')}
                    type={this.state.requestFailed ? SnackBarType.ERROR : SnackBarType.SUCCESS}
                    isOpen={this.state.requestFailed || this.state.requestSucceeded}/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {token, login} = state.login;
    return {token, login};
};

export default connect(mapStateToProps, null)(withStyles(styles)(withRouter(TestEdit)));

