import React, {FunctionComponent, useEffect, useState} from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {appBar} from '../../styles';
import {
    Button,
    Checkbox,
    CircularProgress,
    Container,
    CssBaseline,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
    Typography
} from '@material-ui/core';
import {createStyles, withStyles} from '@material-ui/core/styles';
import {Session, SessionStudent} from '../../redux/session/types';
import {AppState, Token} from '../../helpers/types';
import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from 'redux';
import {
    actionFailed,
    actionRequested,
    actionSucceeded,
    assignStudentToSessionAction,
    bulkAssignStudentToSession,
    clearStudentsAction,
    editStudentSessionAction,
    resetSession,
    setSessionDate,
    submitSession
} from '../../redux/session/actions';
import {connect} from 'react-redux';
import AppBar from '../AppBar';
import {EDIT_SESSION_ROUTE} from './routes';
import DrawerMenu from '../DrawerMenu';
import i18n from '../../i18n/i18n';
import {KeyboardDateTimePicker} from '@material-ui/pickers';
import {CheckBoxOutlineBlank} from '@material-ui/icons';
import {SnackBarType} from '../../components/SnackBarMessage';
import {SnackBarMessage} from '../../components';
import {Moment} from 'moment';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
    },
    content: {
        flex: 1,
        width: '100%',
        marginTop: appBar.height,
        paddingTop: theme.spacing(2),
        alignItems: 'center',
        justifyContent: 'center',
    },
    titleView: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    attendanceForm: {
        display: 'flex',
        flexDirection: 'row',
    },
    submitView: {
        display: 'flex',
        flexDirection: 'column',
        flex: 0.2,
    },
    studentList: {
        flex: 0.8
    }
});

type StateProps = {
    token: Token;
    login: string;
    isAdmin: boolean;
    isCoach: boolean;
    isSectionManager: boolean;
    session: Session;
};

type DispatchProps = {
    actionRequested: () => void;
    actionSucceeded: () => void;
    actionFailed: (message: string) => void;
    assignStudentToSession: (
        studentId: number,
        isLate: boolean,
        isAbsent: boolean,
        firstName: string,
        lastName: string,
    ) => void;
    editStudentSession: (
        studentId: number,
        isLate: boolean,
        isAbsent: boolean,
    ) => void;
    resetSession: () => void;
    clearStudents: () => void;
    setSessionDate: (date: Date) => void;
    bulkAssignStudentToSession: (students: SessionStudent[]) => void;
    submitSession: (session: Session) => void;
};

type Props = {
    classes: { [key: string]: string };
} & StateProps & DispatchProps & RouteComponentProps;

const EditSession: FunctionComponent<Props> = ({
    editStudentSession,
    classes,
    history,
    token,
    login,
    session,
    resetSession,
    setSessionDate,
    submitSession,
}) => {
    const [sessionDateError, setSessionDateError] =
        useState(!session.date || (session.date && session.date.toString() === 'Invalid Date'));
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        if (!token.access) {
            history.push('/login');
        }
    }, [history, token.access]);

    const handleSessionDateChange = (newDate: Moment | null): void => {
        if (newDate) {
            setSessionDate(newDate.toDate());
        } else {
            setSessionDate(new Date('Invalid'));
        }
    };

    const handleSessionDateBlur = (): void => {
        setSessionDateError(!session.date || (session.date && session.date.toString() === 'Invalid Date'));
    };

    const handleSubmitClick = (): void => {
        submitSession(session);
    };

    const handleCancelClick = (): void => {
        resetSession();
    };

    const isAttendingChecked = (id: number): boolean =>
        !session.students.filter(student => student.id === id)[0].isAbsent;

    const handleIsAttendingChecked = (id: number): void => {
        const student = session.students.filter(student => student.id === id)[0];
        editStudentSession(student.id, student.isLate, !student.isAbsent);
    };

    const isLateChecked = (id: number): boolean =>
        session.students.filter(student => student.id === id)[0].isLate;

    const handleIsLateChecked = (id: number): void => {
        const student = session.students.filter(student => student.id === id)[0];
        editStudentSession(student.id, !student.isLate, student.isLate ? student.isAbsent : false);
    };

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar currentRoute={EDIT_SESSION_ROUTE} login={login} onBurgerClick={() => {
                setIsMenuOpen(!isMenuOpen);
            }}/>
            <DrawerMenu isOpen={isMenuOpen}/>
            {session.request.requested ?
                <Container component="main" className={classes.content}>
                    <CircularProgress/>
                </Container> :
                <Container component="main" className={classes.content}>
                    <div className={classes.titleView}>
                        <Typography variant="h3" component="h6">
                            {i18n.t('EDIT_SESSION', {name: session.name})}
                        </Typography>
                        <KeyboardDateTimePicker
                            required
                            id="birthDate"
                            label={i18n.t('SESSION_DATE')}
                            value={session.date}
                            onChange={handleSessionDateChange}
                            error={sessionDateError}
                            format="DD/MM/YYYY HH:mm"
                            onBlur={handleSessionDateBlur}
                            invalidDateMessage={i18n.t('INVALID_DATE_MESSAGE')}
                        />
                    </div>
                    <div className={classes.attendanceForm}>
                        <div className={classes.submitView}>
                            <Button variant="contained" color="primary"
                                disabled={sessionDateError || session.isTransmitted}
                                onClick={handleSubmitClick}>
                                {i18n.t('SUBMIT_BUTTON')}
                            </Button>
                            <Button variant="contained" color="default"
                                onClick={handleCancelClick}>
                                {i18n.t('CANCEL_BUTTON')}
                            </Button>
                        </div>
                        <Paper className={classes.studentList}>
                            {session.students.length ? <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{i18n.t('ATTENDING')}</TableCell>
                                        <TableCell>{i18n.t('IS_LATE')}</TableCell>
                                        <TableCell>{i18n.t('FIRST_NAME_FIELD')}</TableCell>
                                        <TableCell>{i18n.t('LAST_NAME_FIELD')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {session.students.sort((a, b) =>
                                        a.lastName < b.lastName
                                            ? -1
                                            : a.lastName > b.lastName
                                                ? 1 : 0).map(student =>
                                        <TableRow key={student.id}>
                                            <TableCell>
                                                <Checkbox checked={isAttendingChecked(student.id)} value="checked"
                                                    icon={<CheckBoxOutlineBlank color="primary"/>}
                                                    color="primary"
                                                    onChange={() => {
                                                        handleIsAttendingChecked(student.id);
                                                    }}/>
                                            </TableCell>
                                            <TableCell>
                                                <Checkbox checked={isLateChecked(student.id)} value="checked"
                                                    icon={<CheckBoxOutlineBlank color="primary"/>}
                                                    color="primary"
                                                    onChange={() => {
                                                        handleIsLateChecked(student.id);
                                                    }}/>
                                            </TableCell>
                                            <TableCell>
                                                {student.firstName}
                                            </TableCell>
                                            <TableCell>
                                                {student.lastName}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                                :
                                <Typography paragraph>
                                    {i18n.t('NO_STUDENT_TEXT')}
                                </Typography>}
                        </Paper>
                    </div>
                </Container>}
            <SnackBarMessage
                content={session.isTransmitted && session.request.succeeded
                    ? i18n.t('CREATE_SESSION_SUCCESS') : session.request.failMessage}
                type={session.isTransmitted && session.request.succeeded ? SnackBarType.SUCCESS : SnackBarType.ERROR}
                isOpen={(session.isTransmitted && session.request.succeeded) || session.request.failed}/>
        </div>
    );
};

const mapStateToProps = (state: AppState): StateProps => {
    const {token, login, isAdmin, isCoach, isSectionManager} = state.login;
    return {token, login, isAdmin, isCoach, isSectionManager, session: state.session};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, unknown, AnyAction>): DispatchProps => ({
    actionRequested: (): void => {
        dispatch(actionRequested());
    },
    actionSucceeded: (): void => {
        dispatch(actionSucceeded());
    },
    actionFailed: (message: string): void => {
        dispatch(actionFailed(message));
    },
    assignStudentToSession: (
        studentId: number,
        isLate: boolean,
        isAbsent: boolean,
        firstName: string,
        lastName: string,
    ): void => {
        dispatch(assignStudentToSessionAction(studentId, isLate, isAbsent, firstName, lastName));
    },
    resetSession: (): void => {
        dispatch(resetSession());
    },
    clearStudents: (): void => {
        dispatch(clearStudentsAction());
    },
    editStudentSession: (
        studentId: number,
        isLate: boolean,
        isAbsent: boolean,
    ): void => {
        dispatch(editStudentSessionAction(studentId, isLate, isAbsent));
    },
    bulkAssignStudentToSession: (students: SessionStudent[]): void => {
        dispatch(bulkAssignStudentToSession(students));
    },
    setSessionDate: (date: Date): void => {
        dispatch(setSessionDate(date));
    },
    submitSession: (session: Session): void => {
        dispatch(submitSession(session, true));
    },
});

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(EditSession)));
