import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import {AppBar, DrawerMenu} from '..';
import {Button, CssBaseline, Grid, Paper, TextField, Typography} from '@material-ui/core';
import i18n from '../../i18n/i18n';
import {ErrorView, SnackBarMessage, SuccessView} from '../../components';
import {Connector} from '../../helpers/Connector';
import {loginSuccess} from '../../redux/login/actions';
import ls from 'local-storage';
import {appBar} from '../../styles';
import {SnackBarType} from '../../components/SnackBarMessage';
import {errorMessages} from '../../helpers/types';

const styles = theme => ({
    root: {
        display: 'flex',
    },
    content: {
        marginTop: theme.spacing(3),
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    paper: {
        width: '35%',
        marginTop: appBar.height,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2),
    },
});

class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            authenticated: false,
            oldPassword: '',
            oldPasswordError: false,
            newPassword: '',
            newPasswordError: false,
            confirmNewPassword: '',
            confirmNewPasswordError: false,
            errorList: [],
            changed: false,
            requestFailed: false,
            failMessage: '',
            isMenuOpen: false,
        };
    }

    componentDidMount() {
        if (!this.props.token.access) {
            this.props.history.push('/login');
        }
    }

    handleOldPasswordFocus = () => this.setState({oldPasswordError: false});
    handleOldPasswordChange = e => this.setState({oldPassword: e.target.value});
    handleOldPasswordBlur = () => this.setState({oldPasswordError: this.state.oldPassword === ''});

    handleNewPasswordFocus = () => this.setState({newPasswordError: false});
    handleNewPasswordChange = e => this.setState({newPassword: e.target.value});
    handleNewPasswordBlur = () => this.setState({newPasswordError: this.state.newPassword === ''});

    handleConfirmNewPasswordFocus = () => this.setState({confirmNewPasswordError: false});
    handleConfirmNewPasswordChange = e => this.setState({confirmNewPassword: e.target.value});
    handleConfirmNewPasswordBlur = () => this.setState({confirmNewPasswordError: this.state.confirmNewPassword === ''});

    handleCancelClick = () => this.props.history.push('/home');

    handlePreAuthSubmitClick = () => {
        let errorList = [];
        if (this.state.oldPassword === '') {
            this.setState({oldPasswordError: true});
            errorList.push('PASSWORD_ERROR');
        }
        if (errorList.length === 0) {
            Connector.getInstance().verifyPassword(this.props.login, this.state.oldPassword)
                .then(auth => {

                    if (auth === true) this.setState({authenticated: true});
                    else {
                        errorList.push('WRONG_PASSWORD');
                        this.setState({errorList: errorList, requestFailed: true, failMessage: auth});
                    }

                }).catch(e => e.message === 'LOGOUT' && this.props.history.push('/login'));
        }
        this.setState({errorList: errorList});
    };

    handlePostAuthSubmitClick = () => {
        const {newPassword, confirmNewPassword} = this.state;
        let errorList = [];

        if (newPassword === '') {
            this.setState({newPasswordError: true});
            errorList.push('PASSWORD_ERROR');
        }
        if (confirmNewPassword === '') {
            this.setState({confirmNewPasswordError: true});
            errorList.push('CONFIRMATION_ERROR');
        }
        if (confirmNewPassword !== newPassword) {
            this.setState({newPasswordError: true, confirmNewPasswordError: true});
            errorList.push('CONFIRMATION_MISMATCH');
        }
        if (errorList.length === 0) {
            this.setState({requestFailed: false, failMessage: '', errorList: []}, () => {
                Connector.getInstance().changePassword(this.props.login, newPassword)
                    .then(response => {
                        if (!errorMessages.includes(response)) {
                            const {token, roles} = response;
                            this.props.updateToken(token, roles);
                            ls('access', token.access);
                            ls('refresh', token.refresh);
                            ls('roles', roles);
                            this.setState({changed: true});
                            setTimeout(() => {
                                this.props.history.push('home');
                            }, 3000);
                        } else
                            this.setState({requestFailed: true, failMessage: response});
                    });
            });
        }

        this.setState({errorList: errorList});
    };

    renderPreAuthenticate = () => {
        const {classes} = this.props;

        return (
            <Paper className={classes.paper}>
                <Typography component="h3" variant="h6">
                    {i18n.t('PLEASE_ENTER_PASSWORD')}
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            required
                            type="password"
                            name="oldPassword"
                            label={i18n.t('PASSWORD_LABEL')}
                            value={this.state.oldPassword}
                            onFocus={this.handleOldPasswordFocus}
                            onChange={this.handleOldPasswordChange}
                            onBlur={this.handleOldPasswordBlur}
                            error={this.state.oldPasswordError}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button variant="contained" fullWidth color="default" onClick={this.handleCancelClick}>
                            {i18n.t('CANCEL_BUTTON')}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button variant="contained" fullWidth color="primary" onClick={this.handlePreAuthSubmitClick}>
                            {i18n.t('SUBMIT_BUTTON')}
                        </Button>
                    </Grid>
                </Grid>
                <ErrorView errorList={this.state.errorList}/>
            </Paper>
        );
    };

    renderPostAuthenticate = () => {
        const {classes} = this.props;
        return (
            <Paper className={classes.paper}>
                <Typography component="h3" variant="h6">
                    {i18n.t('CHANGE_PASSWORD_TITLE')}
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            required
                            type="password"
                            name="newPassword"
                            label={i18n.t('PASSWORD_LABEL')}
                            value={this.state.newPassword}
                            onFocus={this.handleNewPasswordFocus}
                            onChange={this.handleNewPasswordChange}
                            onBlur={this.handleNewPasswordBlur}
                            error={this.state.newPasswordError}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            required
                            type="password"
                            name="confirmNewPassword"
                            label={i18n.t('PASSWORD_CONFIRMATION_LABEL')}
                            value={this.state.confirmNewPassword}
                            onFocus={this.handleConfirmNewPasswordFocus}
                            onChange={this.handleConfirmNewPasswordChange}
                            onBlur={this.handleConfirmNewPasswordBlur}
                            error={this.state.confirmNewPasswordError}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button variant="contained" fullWidth color="default" onClick={this.handleCancelClick}>
                            {i18n.t('CANCEL_BUTTON')}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button variant="contained" fullWidth color="primary" onClick={this.handlePostAuthSubmitClick}>
                            {i18n.t('SUBMIT_BUTTON')}
                        </Button>
                    </Grid>
                </Grid>
                <ErrorView errorList={this.state.errorList}/>
                <SuccessView shown={this.state.changed} success={this.state.changed}
                    successExplanation={i18n.t('CHANGE_PASSWORD_SUCCESS')}/>
            </Paper>
        );
    };

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                <CssBaseline/>
                <AppBar login={this.props.login} onBurgerClick={() => {
                    this.setState({isMenuOpen: !this.state.isMenuOpen});
                }}/>
                <DrawerMenu isOpen={this.state.isMenuOpen}/>
                <main className={classes.content}>
                    {!this.state.authenticated ? this.renderPreAuthenticate() : this.renderPostAuthenticate()}
                </main>
                <SnackBarMessage content={i18n.t(this.state.failMessage)} type={SnackBarType.ERROR}
                    isOpen={this.state.requestFailed}/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {login, token} = state.login;
    return {login, token};
};

const mapDispatchToProps = dispatch => ({
    updateToken: (token, roles) => dispatch(loginSuccess(token, roles))
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(withRouter(Settings)));
