import React, {Component, Fragment} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Button, CssBaseline, FormControl, Grid, InputLabel, MenuItem, Select, TextField,} from '@material-ui/core';
import {KeyboardDateTimePicker} from '@material-ui/pickers';
import i18n from '../i18n/i18n';
import TestGroupsList from './TestGroupsList';
import {Connector} from '../helpers/Connector';
import {errorMessages} from '../helpers/types';

const styles = theme => ({
    form: {
        width: '100%',
        marginBottom: theme.spacing(3),
        marginLeft: 0,
    },
    submit: {
        margin: theme.spacing(2, 0, 2)
    },
    formSelect: {
        width: '100%'
    }
});

class TestCreationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            testDate: new Date(),
            testDateError: false,
            capacity: 0,
            capacityError: false,
            // eslint-disable-next-line no-undef
            minBirthYear: process.env.REACT_APP_MAX_BIRTH_DATE,
            // eslint-disable-next-line no-undef
            maxBirthYear: process.env.REACT_APP_MAX_BIRTH_DATE,
            groupIds: [],
            possibleGroups: [],
            possibleTypes: [],
            selectedType: 0,
            location: 'Stade Louis II',
            locationError: false,
        };
    }

    componentDidMount() {
        Connector.getInstance().getAllTestGroups()
            .then(sections => {
                if (sections && !errorMessages.includes(sections)) {
                    this.setState({
                        possibleGroups: sections
                    }, () => {
                        Connector.getInstance().getAllTypes()
                            .then(types => {
                                if (types && !errorMessages.includes(types)) {
                                    this.setState({possibleTypes: types, selectedType: types[0].id});
                                }
                            });
                    });
                } else {
                    this.props.onRequestFailed(sections);
                }
            });
    }

    handleDateChange = e => this.setState({testDate: e});
    handleCapacityChange = e => {
        this.setState({capacity: e.target.value});
    };

    handleLocationChange = e => {
        this.setState({location: e.target.value});
    }

    handleSubmit = () => {
        let error = false;
        if (this.state.capacity <= 0) {
            this.setState({capacityError: true});
            error = true;
        }
        if (this.state.testDate.getTime <= new Date().getTime) {
            this.setState({testDateError: true});
            error = true;
        }
        if (this.state.location === '') {
            this.setState({locationError: true});
            error = true;
        }
        // eslint-disable-next-line no-undef
        if (this.state.maxBirthYear > process.env.REACT_APP_MAX_BIRTH_DATE
            || this.state.maxBirthYear < new Date().getFullYear() - 80
            // eslint-disable-next-line no-undef
            || this.state.minBirthYear > process.env.REACT_APP_MAX_BIRTH_DATE
            || this.state.minBirthYear < new Date().getFullYear() - 80
            || this.state.minBirthYear > this.state.maxBirthYear) {
            this.setState({birthYearError: true});
            error = true;
        }
        if (!error) {
            this.props.onSubmit(
                this.state.testDate,
                this.state.capacity,
                this.state.minBirthYear,
                this.state.maxBirthYear,
                this.state.groupIds,
                this.state.selectedType,
                this.state.location,
            );
        }
    };

    renderBirthYears = () => {
        const years = [];
        // eslint-disable-next-line no-undef
        for (let i = process.env.REACT_APP_MAX_BIRTH_DATE; i > new Date().getFullYear() - 80; i--) {
            years.push(i);
        }

        return years.map(item => (<MenuItem value={item} key={item}>{item}</MenuItem>));
    };

    renderTypes = () => this.state.possibleTypes.length
        ? this.state.possibleTypes.map(type => <MenuItem value={type.id}
            key={type.id}>{i18n.t(type.label)}</MenuItem>)
        : null;

    isGroupSelected = (id) => this.state.groupIds.filter(selected => selected === id)[0] !== undefined;

    handleToggleSelected = (id) => {
        const newSelected = [];
        if (this.isGroupSelected(id)) {
            this.state.groupIds.map((selected) => {
                if (selected !== id)
                    newSelected.push(selected);
                return selected;
            });
        } else {
            this.state.groupIds.map(selected => newSelected.push(selected));
            newSelected.push(id);
        }

        this.setState({
            possibleGroups: this.state.possibleGroups.map(section => {
                return {
                    id: section.id,
                    name: section.name,
                    groups: section.groups.map(group => {
                        return {
                            id: group.id,
                            name: group.name,
                            selected: group.id === id ? !this.isGroupSelected(id) : group.selected,
                        };
                    }),
                };
            }),
            groupIds: newSelected
        });
    };

    handleMinBirthYearChange = e => {
        this.setState({minBirthYear: e.target.value});
    };

    handleMaxBirthYearChange = e => {
        this.setState({maxBirthYear: e.target.value});
    };

    handleSelectedTypeChanged = e => {
        this.setState({selectedType: e.target.value});
    }

    render() {
        const {classes} = this.props;
        return (
            <Fragment>
                <CssBaseline/>
                <form className={classes.form} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <KeyboardDateTimePicker
                                required
                                fullWidth
                                id="testDate"
                                label={i18n.t('TEST_DATETIME_LABEL')}
                                value={this.state.testDate}
                                onChange={this.handleDateChange}
                                error={this.state.testDateError}
                                format="DD/MM/YYYY HH:mm"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="capacity"
                                label={i18n.t('TEST_CAPACITY_LABEL')}
                                type="number"
                                value={this.state.capacity}
                                onChange={this.handleCapacityChange}
                                error={this.state.capacityError}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <FormControl className={classes.formSelect}>
                                <InputLabel htmlFor="minBirthYear">{i18n.t('MIN_BIRTH_YEAR_LABEL')}</InputLabel>
                                <Select
                                    value={this.state.minBirthYear}
                                    onChange={this.handleMinBirthYearChange}
                                    error={this.state.birthYearError}
                                    inputProps={{
                                        name: 'minBirthYear',
                                        id: 'minBirthYear'
                                    }}
                                >
                                    {this.renderBirthYears()}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl className={classes.formSelect}>
                                <InputLabel htmlFor="maxBirthYear">{i18n.t('MAX_BIRTH_YEAR_LABEL')}</InputLabel>
                                <Select
                                    value={this.state.maxBirthYear}
                                    onChange={this.handleMaxBirthYearChange}
                                    error={this.state.birthYearError}
                                    inputProps={{
                                        name: 'maxBirthYear',
                                        id: 'maxBirthYear'
                                    }}
                                >
                                    {this.renderBirthYears()}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl className={classes.formSelect}>
                                <InputLabel htmlFor="selectedType">{i18n.t('TEST_TYPE_LABEL')}</InputLabel>
                                <Select
                                    value={this.state.selectedType}
                                    onChange={this.handleSelectedTypeChanged}
                                    inputProps={{
                                        name: 'selectedType',
                                        id: 'selectedType'
                                    }}
                                >
                                    {this.renderTypes()}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl className={classes.formSelect}>
                                <TextField
                                    required
                                    fullWidth
                                    id="location"
                                    label={i18n.t('TEST_LOCATION_LABEL')}
                                    value={this.state.location}
                                    onChange={this.handleLocationChange}
                                    error={this.state.locationError}
                                />
                            </FormControl>
                        </Grid>
                        <TestGroupsList sections={this.state.possibleGroups}
                            onToggleSelected={this.handleToggleSelected}/>
                        <Grid item xs={12} sm={6}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={this.handleSubmit}
                                disabled={this.props.disabled}
                            >
                                {i18n.t('SUBMIT_BUTTON')}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Fragment>
        );
    }
}

export default withStyles(styles)(TestCreationForm);
