import React from 'react';
import {
    Button,
    Checkbox,
    CircularProgress,
    CssBaseline,
    FormControlLabel,
    Grid,
    Link,
    Paper,
    Step,
    StepLabel,
    Stepper,
    Typography
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {SnackBarMessage, StudentAddressForm, StudentContactForm, StudentInfoForm} from '../components';
import {subscribe} from '../redux/subscribe/actions';
import i18n from '../i18n/i18n';
import SuccessView from '../components/SuccessView';
import {Connector} from '../helpers/Connector';
import {SnackBarType} from '../components/SnackBarMessage';
import {errorMessages} from '../helpers/types';
import {isStudentMajor} from '../helpers/utils';
import {CheckBoxOutlineBlank} from '@material-ui/icons';

const styles = theme => ({
    formRoot: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    paper: {
        width: '100%',
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2),
        overflow: 'wrap',
    },
    stepper: {
        width: '100%',
        padding: theme.spacing(3, 0, 5),
        display: 'flex',
        flexWrap: 'wrap',
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    stepButtons: {
        marginRight: theme.spacing(2)
    },
    covidClauseView: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        textAlignVertical: 'center',
    },
    covidClauseText: {
        fontSize: 11,
        margin: 0,
    }
});


class SubscriptionForm extends React.Component {
    constructor(props) {
        super(props);
        this.steps = [i18n.t('FORM_PERSONAL_INFO'), i18n.t('FORM_CONTACT'), i18n.t('FORM_ADDRESS')];
        this.state = {
            isWorking: true,
            isNew: true,
            data: {},
            activeStep: 0,
            formContent: {
                personalInfo: {},
                contactInfo: {},
                addressInfo: {}
            },
            subscriptionState: '',
            groupTypeId: 0,
            requestFailed: false,
            failMessage: '',
            covidChecked: true,
            shirtSizes: [],
            shortSizes: [],
            privacyNoticeChecked: false,
            ffnLicense: false,
            isRenewalRulesChecked: false,
            isRenewalCodeOfConductChecked: false,
            isRenewalParentCodeOfConductChecked: false,
            school: '',
            schoolLevel: '',
        };
    }

    documentLinks = () => {
        return this.state.data ? [{
            // eslint-disable-next-line no-undef
            link: `${process.env.REACT_APP_BACKEND_URL}/subscription/${
                this.state.data.subscription.id}/Formulaire d'inscription ASM Natation`,
            label: i18n.t('SUBSCRIPTION_FORM'),
        }, this.state.isNew ? {
            // eslint-disable-next-line no-undef
            link: `${process.env.REACT_APP_BACKEND_URL}/subscription/${
                this.state.data.subscription.id}/Demande d'admission ASM`,
            label: i18n.t('ASM_SUBSCRIPTION_FORM'),
        } : undefined, this.state.isNew ? {
            // eslint-disable-next-line no-undef
            link: `${process.env.REACT_APP_BACKEND_URL}/subscription/Règles de fonctionnement ASM Natation`,
            label: i18n.t('RULES_LABEL'),
        } : undefined, this.state.groupTypeId !== 6 && this.state.groupTypeId !== 10 && this.state.isNew ? {
            // eslint-disable-next-line no-undef
            link: `${process.env.REACT_APP_BACKEND_URL}/subscription/Code de conduite du sportif ASM Natation`,
            label: i18n.t('CODE_OF_CONDUCT_LABEL')
        } : undefined, this.state.groupTypeId !== 6
        && !isStudentMajor(this.state.data.subscription.birthDate)
        && this.state.isNew ? {
            // eslint-disable-next-line no-undef
                link: `${process.env.REACT_APP_BACKEND_URL}/subscription/Code de conduite des parents ASM Natation`,
                label: i18n.t('PARENT_CODE_OF_CONDUCT_LABEL')
            } : undefined, this.state.ffnLicense ? {
            // eslint-disable-next-line no-undef
            link: `${process.env.REACT_APP_BACKEND_URL}/subscription/${
                isStudentMajor(this.state.data.subscription.birthDate)
                    ? 'Formulaire de licence FFN - MAJEURS'
                    : 'Formulaire de licence FFN - MINEURS'}`,
            label: i18n.t(isStudentMajor(this.state.data.subscription.birthDate)
                ? 'FFN_LICENCE_MAJOR' : 'FFN_LICENCE_MINOR'),
        } : undefined, [1].includes(this.state.groupTypeId) ? {
            // eslint-disable-next-line no-undef
            link: `${process.env.REACT_APP_BACKEND_URL}/group/Horaires Natation 2024-2025`,
            label: i18n.t('SCHEDULE_DOCUMENT'),
        } : undefined].filter(item => item) : [];
    };

    componentDidMount() {
        if (this.props.token) {
            this.setState({requestFailed: false, failMessage: ''}, () => {
                Connector.getInstance().getExistingData(this.props.token).then(data => {
                    if (!errorMessages.includes(data)) {
                        this.setState({
                            data: data,
                            isNew: data.isNew,
                            subscriptionState: data.subscription.state,
                            groupTypeId: data.groupTypeId,
                            ffnLicense: data.ffnLicense,
                            isWorking: false
                        });
                    } else
                        this.setState({requestFailed: true, failMessage: data});
                });
                Connector.getInstance().getShirtSizes().then(response => {
                    if (!errorMessages.includes(response)) {
                        this.setState({
                            shirtSizes: response,
                        });
                    }
                });
                Connector.getInstance().getShortSizes().then(response => {
                    if (!errorMessages.includes(response)) {
                        this.setState({
                            shortSizes: response,
                        });
                    }
                });
            });
        }
    }

    handleNext = () => {
        switch (this.state.activeStep) {
        case 0: {
            const personalInfo = this.infoForm.submitInfo();
            if (personalInfo) {
                if (isStudentMajor(personalInfo.birthDate)) {
                    this.setState({
                        formContent: Object.assign({}, this.state.formContent,
                            {
                                personalInfo,
                                contactInfo: {
                                    guardianFname: personalInfo.fname,
                                    guardianLname: personalInfo.lname
                                }
                            })
                    });
                } else {
                    this.setState({formContent: Object.assign({}, this.state.formContent, {personalInfo})});
                }
                this.setState({activeStep: this.state.activeStep + 1});
            }
            break;
        }
        case 1: {
            const contactInfo = this.contactForm.submitContent();
            if (contactInfo) {
                this.setState({formContent: Object.assign({}, this.state.formContent, {contactInfo})});
                this.setState({activeStep: this.state.activeStep + 1});
            }
            break;
        }
        case 2: {
            const addressInfo = this.addressForm.submitContent();
            if (addressInfo) {
                this.setState({formContent: Object.assign({}, this.state.formContent, {addressInfo})}, () => {
                    this.submitForm();
                });
            }
            break;
        }
        default:
            break;
        }
    };

    submitForm = () => {
        this.setState({requestFailed: false, failMessage: ''}, () => {
            this.props.submitForm(this.state.data.id, this.state.data.subscription.id, {
                ...this.state.formContent.personalInfo,
                ...this.state.formContent.contactInfo,
                ...this.state.formContent.addressInfo,
            }, this.state.covidChecked);
        });
    };

    handleBack = () => {
        this.setState({activeStep: this.state.activeStep - 1});
    };

    handleReset = () => {
        this.setState({activeStep: 0, formContent: {personalInfo: {}, contactInfo: {}, addressInfo: {}}});
    };

    getStepContent = step => {
        switch (step) {
        case 0:
            return <StudentInfoForm
                creation={false}
                school={this.state.school}
                schoolLevel={this.state.schoolLevel}
                shirtSizes={this.state.shirtSizes}
                shortSizes={this.state.shortSizes}
                data={this.state.data}
                {...this.state.formContent.personalInfo}
                ref={ref => this.infoForm = ref}/>;
        case 1:
            return <StudentContactForm data={this.state.data} {...this.state.formContent.contactInfo}
                birthDate={this.state.formContent.personalInfo.birthDate}
                ref={ref => this.contactForm = ref}/>;
        case 2:
            return <StudentAddressForm data={this.state.data} {...this.state.formContent.addressInfo}
                ref={ref => this.addressForm = ref}/>;
        default:
            return 'Shit\'s broken';
        }
    };

    renderAlreadySubscribed = () =>
        <Paper className={this.props.classes.paper}>
            <p>{i18n.t('ALREADY_SUBSCRIBED_NORMAL')}</p>
            <ul>
                {this.documentLinks().map((item, index) =>
                    <li key={index}><Link
                        target="_blank"
                        href={item.link}
                        download={item.filename}
                    >{i18n.t('DOCUMENT_LABEL', {index: index + 1})}{item.label}</Link></li>
                )}
            </ul>
        </Paper>;

    renderDownloadLinks = () =>
        <div>
            {this.documentLinks().map((item, index) =>
                <li key={index}><Link
                    target="_blank"
                    href={item.link}
                >{i18n.t('DOCUMENT_LABEL', {index: index + 1})}{item.label}</Link></li>
            )}
        </div>;

    handlePrivacyNoticeChanged = () => {
        this.setState({privacyNoticeChecked: !this.state.privacyNoticeChecked});
    };

    renderPrivacyNotice = () => {
        return this.state.activeStep === this.steps.length - 1 ? <Typography paragraph style={{
            width: '80%',
        }}>
            <FormControlLabel
                control={<Checkbox checked={this.state.privacyNoticeChecked}
                    value={this.state.privacyNoticeChecked}
                    icon={<CheckBoxOutlineBlank color="primary"/>}
                    color="primary" onChange={this.handlePrivacyNoticeChanged}/>}
                label={i18n.t('ACCEPT_PRIVACY_TERMS')}
            />
            <br/>
            {i18n.t('PRIVACY_TERMS')}
            <Link to="#" onClick={() => {
                window.location.href = 'mailto:asmonaconatation@monaco.mc';
            }}>
                asmonaconatation@monaco.mc
            </Link>
        </Typography> : '';
    };

    renderRenewalRules = () => {
        return <Link target="_blank"
            onClick={() => this.setState({isRenewalRulesChecked: true})}
            href={
                // eslint-disable-next-line no-undef
                `${process.env.REACT_APP_BACKEND_URL}/subscription/Règles de fonctionnement ASM Natation`}>
            {i18n.t('RULES_LABEL')}
        </Link>;
    };
    
    renderCodeOfConduct = () => {
        return <Link target="_blank" 
            onClick={() => this.setState({isRenewalCodeOfConductChecked: true})}
            // eslint-disable-next-line no-undef
            href={`${process.env.REACT_APP_BACKEND_URL}/subscription/Code de conduite du sportif ASM Natation`}>
            {i18n.t('CODE_OF_CONDUCT_LABEL')}
        </Link>;
    }
    
    renderParentCodeOfConduct = () => {
        return <Link target="_blank"
            onClick={() => this.setState({isRenewalParentCodeOfConductChecked: true})}
            // eslint-disable-next-line no-undef
            href={`${process.env.REACT_APP_BACKEND_URL}/subscription/Code de conduite des parents ASM Natation`}>
            {i18n.t('PARENT_CODE_OF_CONDUCT_LABEL')}
        </Link>;
    }

    handleRenewalRulesChecked = () => {
        this.setState({isRenewalRulesChecked: !this.state.isRenewalRulesChecked});
    }

    handleRenewalCodeOfConductChecked = () => {
        this.setState({isRenewalCodeOfConductChecked: !this.state.isRenewalCodeOfConductChecked});
    }

    handleRenewalParentCodeOfConductChecked = () => {
        this.setState({isRenewalParentCodeOfConductChecked: !this.state.isRenewalParentCodeOfConductChecked});
    }
    renderAcceptDocs = () => {
        return  this.state.activeStep === this.steps.length - 1 ?
            <Typography paragraph style={{
                width: '80%',
            }}>
                <FormControlLabel
                    control={<Checkbox checked={this.state.isRenewalRulesChecked}
                        value={this.state.isRenewalRulesChecked}
                        icon={<CheckBoxOutlineBlank color="primary"/>}
                        color="primary" onChange={this.handleRenewalRulesChecked}/>}
                    label={this.renderRenewalRules()}
                />
                {this.state.groupId !== 6 ? <FormControlLabel
                    control={<Checkbox checked={this.state.isRenewalCodeOfConductChecked}
                        value={this.state.isRenewalRulesChecked}
                        icon={<CheckBoxOutlineBlank color="primary"/>}
                        color="primary" onChange={this.handleRenewalCodeOfConductChecked}/>}
                    label={this.renderCodeOfConduct()}
                /> : ''}
                {this.state.groupId !== 6 && !isStudentMajor(this.state.data.subscription.birthDate) ? <FormControlLabel
                    control={<Checkbox checked={this.state.isRenewalParentCodeOfConductChecked}
                        value={this.state.isRenewalRulesChecked}
                        icon={<CheckBoxOutlineBlank color="primary"/>}
                        color="primary" onChange={this.handleRenewalParentCodeOfConductChecked}/>}
                    label={this.renderParentCodeOfConduct()}
                /> : ''}
            </Typography> : '';
    };

    renewalAllChecked = () => {
        return (!this.state.isNew
            && this.state.isRenewalRulesChecked
            && this.state.isRenewalCodeOfConductChecked
            && (isStudentMajor(this.state.data.subscription.birthDate)
                || this.state.isRenewalParentCodeOfConductChecked)
        ) || this.state.isNew;
    }

    render() {
        const {classes} = this.props;

        return (
            <React.Fragment>
                <CssBaseline/>
                <div className={classes.formRoot}>
                    {this.state.isWorking ? <CircularProgress/>
                        : this.state.subscriptionState === 'scheduled' ? <Paper className={classes.paper}>
                            <Stepper activeStep={this.state.activeStep} className={classes.stepper}>
                                {this.steps.map(label => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                            <React.Fragment>
                                {this.getStepContent(this.state.activeStep)}
                                {this.renderPrivacyNotice()}
                                {!this.state.isNew && this.renderAcceptDocs()}
                                <Grid container spacing={2}>
                                    <Grid item xs={6} className={classes.buttonContainer}>
                                        <Button
                                            onClick={this.handleReset}
                                            className={classes.stepButtons}
                                        >
                                            {i18n.t('RESET_FORM_BUTTTON')}
                                        </Button>
                                        {this.state.activeStep === 0 ? null : (
                                            <Button
                                                variant="contained"
                                                onClick={this.handleBack}
                                                className={classes.stepButtons}
                                                disabled={this.props.request.requested || this.props.request.succeeded}
                                            >
                                                {i18n.t('BACK_BUTTON')}
                                            </Button>
                                        )}
                                        {this.state.activeStep === this.steps.length ? null : (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={this.handleNext}
                                                className={classes.stepButtons}
                                                disabled={(this.state.activeStep === this.steps.length - 1
                                                        && (!this.state.privacyNoticeChecked
                                                        || !this.renewalAllChecked()))
                                                    || (this.props.request.requested
                                                        || this.props.request.succeeded)}
                                            >
                                                {this.state.activeStep === this.steps.length - 1
                                                    ? i18n.t('SUBMIT_BUTTON')
                                                    : i18n.t('NEXT_STEP_BUTTON')}
                                            </Button>
                                        )}

                                    </Grid>
                                </Grid>
                                <SuccessView shown={this.props.request.succeeded}
                                    subscribed={this.props.request.succeeded}/>
                                {this.props.request.requested
                                    ? <CircularProgress/>
                                    : null}
                                {this.props.request.succeeded
                                    ? this.renderDownloadLinks()
                                    : null}
                            </React.Fragment>
                        </Paper> : this.renderAlreadySubscribed()}
                </div>
                <SnackBarMessage content={i18n.t(this.props.request.message
                    || this.state.failMessage
                    || 'GENERIC_ERROR_MESSAGE'
                )} type={SnackBarType.ERROR} isOpen={this.state.requestFailed || this.props.request.failed}/>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {...state.subscription};
};

const mapDispatchToProps = dispatch => ({
    submitForm: (studentId, subscriptionId, formContent, covidClause) =>
        dispatch(subscribe(studentId, subscriptionId, formContent, covidClause)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SubscriptionForm));
