import {combineReducers} from 'redux';
import {subscribe} from '../subscribe/reducer';
import {login} from '../login/reducer';
import {session} from '../session/reducer';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const loginPersistConfig = {
    key: 'login',
    blacklist: ['password', 'request'],
    storage,
};

const subscriptionPersistConfig = {
    key: 'subscription',
    blacklist: ['request'],
    storage,
};

const sessionPersistConfig = {
    key: 'session',
    blacklist: 'request',
    storage,
};

const rootReducer = combineReducers({
    subscription: persistReducer(subscriptionPersistConfig, subscribe),
    login: persistReducer(loginPersistConfig, login),
    session: persistReducer(sessionPersistConfig, session),
});

export default rootReducer;
