import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import {
    Button,
    CssBaseline,
    Radio,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core';
import {AppBar, DrawerMenu} from '..';
import i18n from '../../i18n/i18n';
import {Connector} from '../../helpers/Connector';
import moment from 'moment';
import {appBar} from '../../styles';
import {SnackBarType} from '../../components/SnackBarMessage';
import {SnackBarMessage} from '../../components';

const styles = theme => ({
    root: {
        display: 'flex',
    },
    content: {
        marginTop: appBar.height,
        paddingTop: theme.spacing(2),
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    title: {
        flex: 0.1
    },
    form: {
        width: '100%',
    },
    studentTable: {
        display: 'flex',
        flexDirection: 'column',
        flex: 0.8,
        width: '90%',
        marginLeft: '5%',
        marginTop: theme.spacing(2),
        alignItems: 'space-between',
        justifyContent: 'space-between',
    },
    tableHeader: {
        minWidth: '100%',
        width: '100%',
    },
    tableRow: {
        minWidth: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
    },
    tableCell: {
        flex: 1,
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonView: {
        marginTop: theme.spacing(2),
        flex: 0.1,
        display: 'flex',
        width: '90%',
        marginLeft: '5%',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
    },
    button: {
        margin: theme.spacing(2),
    },
    tableHeaderCell: {
        flex: 1,
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'sticky',
        top: appBar.height,
    }
});

class TestResults extends Component {
    constructor(props) {
        super(props);

        this.state = {
            students: [],
            test: {},
            groups: [],
            requestFailed: false,
            failMessage: '',
            isMenuOpen: false,
        };
    }

    componentDidMount() {
        if (!this.props.token.access) {
            this.props.history.push('/login');
        }
        const connector = Connector.getInstance();
        connector.getTest(this.props.location.state.testId).then(test => {
            if (test && test.id) {
                connector.getTestStudents(test.id).then(students => {
                    if (students && !['BAD_REQUEST', 'UNKNOWN_ERROR'].includes(students)) {
                        connector.getPossibleGroups(this.props.location.state.testId).then(possibleGroups => {
                            if (possibleGroups && !['BAD_REQUEST', 'UNKNOWN_ERROR'].includes(possibleGroups)) {
                                const groupIds = possibleGroups.map(item => item.groupId);
                                connector.getGroupsByIds(groupIds).then(groups => {
                                    if (groups && !['BAD_REQUEST', 'UNKNOWN_ERROR'].includes(groups)) {
                                        this.setState({
                                            students: students.map(student => (Object.assign(student, {groupId: 0}))),
                                            test: test,
                                            groups: groups
                                        });
                                    } else {
                                        this.setState({requestFailed: true, failMessage: groups || 'UNKNOWN_ERROR'});
                                    }
                                });
                            } else {
                                this.setState({requestFailed: true, failMessage: possibleGroups || 'UNKNOWN_ERROR'});
                            }
                        });
                    } else {
                        this.setState({requestFailed: true, failMessage: students || 'UNKNOWN_ERROR'});
                    }
                });
            } else {
                this.setState({requestFailed: true, failMessage: test || 'UNKNOWN_ERROR'});
            }
        }).catch(e => {
            if (e === 'LOGOUT')
                this.props.history.push('/login');
        });
    }

    handleGroupClick = (student, groupId) => {
        const newStudents = this.state.students.map(item => {
            if (item.id !== student.id) return item;
            item.groupId = parseInt(groupId);
            return item;
        });
        this.setState({students: newStudents});
    };

    handleCancelClick = () => {
        const newStudents = this.state.students.map(student => Object.assign(student, {groupId: 0}));
        this.setState({students: newStudents});
    };

    handleSubmitClick = () => {
        const connector = Connector.getInstance();
        connector.setStudentsGroup(this.state.students).then(ok => {
            if (ok && !['UNKNOWN_ERROR', 'BAD_REQUEST'].includes(ok))
                connector.finishTest(this.props.location.state.testId).then(ok => {
                    if (ok && !['UNKNOWN_ERROR', 'BAD_REQUEST'].includes(ok))
                        this.props.history.push('/test');
                    else
                        this.setState({requestFailed: true, failMessage: ok || 'UNKNOWN_ERROR'});
                });
            else
                this.setState({requestFailed: true, failMessage: ok || 'UNKNOWN_ERROR'});
        }).catch(e => {
            console.error(e);
            this.props.history.push('/login');
        });
    };

    renderTableHeader = () => this.state.groups.map(group => (
        <TableCell className={this.props.classes.tableHeaderCell} key={group.id}>{group.name}</TableCell>));

    renderGroupCells = student => {
        return this.state.groups.map(group => <TableCell key={group.id} className={this.props.classes.tableCell}>
            <Radio value={group.id} checked={student.groupId === group.id} onClick={e => {
                this.handleGroupClick(student, e.target.value);
            }}/>
        </TableCell>);
    };

    renderRows = () => {
        const {classes} = this.props;
        return this.state.students.map(student => (
            <TableRow key={student.id} className={classes.tableRow}>
                <TableCell className={classes.tableCell}>{student.firstName} {student.lastName}</TableCell>
                {student.Subscription
                    ? <TableCell
                        className={classes.tableCell}>
                        {moment(student.Subscription.birthDate).format('DD/MM/YYYY')}
                    </TableCell>
                    : null}
                {this.renderGroupCells(student)}
                <TableCell className={classes.tableCell}>
                    <Radio checked={student.groupId === 0} onClick={() => {
                        this.handleGroupClick(student, 0);
                    }}/>
                </TableCell>
            </TableRow>
        ));
    };

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                <CssBaseline/>
                <AppBar login={this.props.login} onBurgerClick={() => {
                    this.setState({isMenuOpen: !this.state.menuOpen});
                }}/>
                <DrawerMenu isOpen={this.state.isMenuOpen}/>
                <div content="main" className={classes.content}>
                    <Typography variant="h2" className={classes.title}>
                        {i18n.t('START_TEST_SESSION_TITLE', {
                            date: moment(this.state.test.scheduledDate).format('DD/MM/YYYY')
                        })}
                    </Typography>
                    <form className={classes.form}>
                        <Table className={classes.studentTable}>
                            <TableHead className={classes.tableHeader}>
                                <TableRow className={classes.tableRow}>
                                    <TableCell className={classes.tableHeaderCell}>
                                        {i18n.t('STUDENT_LIST_HEADER')}
                                    </TableCell>
                                    <TableCell className={classes.tableHeaderCell}>
                                        {i18n.t('FORM_BIRTH_DATE')}
                                    </TableCell>
                                    {this.renderTableHeader()}
                                    <TableCell className={classes.tableHeaderCell}>
                                        {i18n.t('FAILED_TEST_HEADER')}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.renderRows()}
                            </TableBody>
                        </Table>
                        <div className={classes.buttonView}>
                            <Button
                                variant="contained"
                                color="default"
                                className={classes.button}
                                onClick={this.handleCancelClick}
                            >
                                {i18n.t('CANCEL_BUTTON')}
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={this.handleSubmitClick}
                            >
                                {i18n.t('SUBMIT_BUTTON')}
                            </Button>
                        </div>
                    </form>
                </div>
                <SnackBarMessage
                    content={i18n.t(`TEST_EDIT_FAILED_${this.state.failMessage}`)}
                    type={SnackBarType.ERROR}
                    isOpen={this.state.requestFailed}/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {token, login} = state.login;
    return {token, login};
};

export default withStyles(styles)(withRouter(connect(mapStateToProps, null)(TestResults)));
