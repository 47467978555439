import {Session} from '../redux/session/types';

export type Coach = {
    id: number;
    firstName: string;
    lastName: string;
};

export type RequestState = {
    requested: boolean;
    succeeded: boolean;
    failed: boolean;
    failMessage: string;
};

export type Student = {
    id: number;
    nextYearGroupId: number | null;
    firstName: string;
    lastName: string;
    shirtSize: Size | null;
    shortSize: Size | null;
    Subscription: {
        id: number;
        gender: string;
        birthDate: Date;
        state: SubscriptionState;
        instagramHandle?: string;
        school?: string;
        schoolLevel?: string;
        address: string;
        city: string;
        zipCode: string;
        country: string;
    };
    SubGroup: {
        id: number;
        name: string;
    } | null;
    contactInfo?: {
        email: string;
        phoneNumber: string;
    }
}


export type GroupDetailsResponse = {
    group: {
        id: number;
        name: string;
        price: number;
        reSubPrice: number;
        groupCode: string;
        Section: {
            id: number;
            name: string;
            SectionCode: string;
            Coach: Coach;
        };
        coaches: Coach[];
    };
    students: Student[];
};

export type CoachResponse = {
    id: number;
    login: string;
    firstName: string;
    lastName: string;
    roles: Role[];
}


export type AllGroupsResponse = {
    id: number;
    name: string;
    price: number;
    groupCode: string;
    Section: {
        id: number;
        name: string;
        sectionCode: string;
        Coach: Coach;
    },
    hasSubGroups: boolean;
    studentCount: number;
    coaches: Coach[];
};

export type AllGroupsBySection = {
    section: string;
    groups: AllGroupsResponse[]
};

export type Token = {
    access: string;
    refresh: string;
};

export type AppState = {
    login: {
        login: string;
        token: Token;
        isCoach: boolean;
        isAdmin: boolean;
        isSectionManager: boolean;
        roles:Role[];
    };
    session: Session;
};

export type User = {
    id: number;
    login: string;
    firstName: string;
    lastName: string;
    roles: Role[];
};

export type Role = {
    id: number;
    name: string;
};

export type Type = {
    id: number;
    label: string;
}

export enum SubscriptionState {
    STARTED = 'started',
    SCHEDULED = 'scheduled',
    DONE = 'done',
    MISSING_DOC = 'missingDoc',
}

export enum Gender {
    M = 'M',
    F = 'F',
}

export type Size = {
    id: number;
    label: string
}

export type SubscriptionDetailsType = {
    id: number;
    studentId: number;
    firstName: string;
    lastName: string;
    isNew: boolean;
    subscriptionState: SubscriptionState;
    birthDate: Date;
    gender: Gender;
    nationality: string;
    address: string;
    zipCode: string;
    city: string;
    country: string;
    shirtSize: Size | null;
    shortSize: Size | null;
    guardianFirstName: string;
    guardianLastName: string;
    guardianPhoneNumber: string;
    guardianEmail: string;
    guardianSecondaryPhoneNumber: string | null;
    guardianSecondaryEmail: string | null;
    instagramHandle: string;
    school: string;
    schoolLevel: string;
    covidClause: boolean;
    birthPlace: string | null;
    subscriptionForm: boolean;
    rules: boolean;
    idPhoto: boolean;
    asmPdf: boolean;
    codeOfConduct: boolean;
    guardianCodeOfConduct: boolean;
    medicalCertificate: boolean;
    payment: boolean;
    ffnLicense: boolean;
    processDate: Date | null;
};

export const errorMessages = ['BAD_REQUEST', 'NOT_FOUND', 'UNKNOWN_ERROR'];
