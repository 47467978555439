import React, {Component} from 'react';
import {get, set} from 'local-storage';
import {createStyles, Theme, withStyles} from '@material-ui/core/styles';
import {AppBar, IconButton, Toolbar, Typography} from '@material-ui/core';
import {Link} from 'react-router-dom';
import * as routes from './screens/routes';
import {appBar, drawer} from '../styles';
import theme from '../theme';
import {Menu} from '@material-ui/icons';
import i18n from '../i18n/i18n';
import fr from '../styles/icons/FR.svg';
import en from '../styles/icons/GB.svg';

const style = (theme: Theme) => createStyles({
    root: {
        zIndex: drawer.zIndex + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    link: {
        textDecoration: 'none',
        color: appBar.textColor,
    }
});

type Props = {
    currentRoute: string;
    classes: { [key: string]: string };
    onBurgerClick?: () => void;
    login?: string;
};

class CustomAppBar extends Component<Props> {
    constructor(props: Props) {
        super(props);
        this.state = {
            locale: i18n.currentLocale(),
        };
    }
    handleBurgerClick = () => {
        this.props.onBurgerClick && this.props.onBurgerClick();
    };

    handleSetFrenchLocaleClick = (): void => {
        if (get('locale') !== 'fr') {
            this.setState({locale: 'fr'}, () => {
                i18n.locale = 'fr';
                set('locale', 'fr');
                window.location.reload();
            });
        }
    }

    handleSetEnglishLocaleClick = (): void => {
        if (get('locale') !== 'en') {
            this.setState({locale: 'en'}, () => {
                i18n.locale = 'en';
                set('locale', 'en');
                window.location.reload();
            });
        }
    }

    render() {
        const {classes} = this.props;
        return (
            <AppBar className={classes.root} position="fixed" style={{
                color: theme.palette.primary.main
            }}>
                <Toolbar>
                    <IconButton style={{
                        color: 'white',
                        display: this.props.login ? 'block' : 'none'
                    }}
                    onClick={() => {
                        this.handleBurgerClick();
                    }}>
                        <Menu/>
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        <Link className={classes.link} to={routes.SUBSCRIBE_ROUTE}>ASM Natation</Link>
                    </Typography>
                    <div style={{

                    }}>
                        <img style={{
                            marginLeft: 15,
                            width: 35,
                            border: '1px solid black',
                            opacity: i18n.currentLocale() === 'fr' ? 0.7 : 1
                        }} src={fr} alt="French flag" onClick={this.handleSetFrenchLocaleClick} />
                        <img style={{
                            marginLeft: 15,
                            width: 35,
                            border: '1px solid black',
                            opacity: i18n.currentLocale() === 'en' ? 0.7 : 1
                        }} src={en} alt="British flag" onClick={this.handleSetEnglishLocaleClick} />
                    </div>
                </Toolbar>
            </AppBar>
        );
    }
}

export default withStyles(style)(CustomAppBar);
