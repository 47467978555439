import i18n from 'i18n-js';
import en from './en';
import fr from './fr';
import {get, set} from 'local-storage';

const storedLocale = get('locale');
i18n.defaultLocale = 'fr';
if (storedLocale) {
    i18n.locale = storedLocale as string;
}
else {
    i18n.locale = 'fr';
    set('locale', 'fr');
}

i18n.translations['en'] = en;
i18n.translations['fr'] = fr;

i18n.fallbacks = 'fr';
export default i18n;
