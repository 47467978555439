import React, {ChangeEvent, FunctionComponent, useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {
    Button,
    CircularProgress,
    Container,
    createStyles,
    CssBaseline,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Theme,
    withStyles
} from '@material-ui/core';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {appBar} from '../../styles';
import AppBar from '../AppBar';
import {SET_NEXT_YEAR_GROUPS} from './routes';
import DrawerMenu from '../DrawerMenu';
import {AppState, errorMessages, Token} from '../../helpers/types';
import {Connector} from '../../helpers/Connector';
import i18n from '../../i18n/i18n';
import {Delete, Edit, Warning} from '@material-ui/icons';
import {SnackBarMessage} from '../../components';
import {SnackBarType} from '../../components/SnackBarMessage';
import {ExportData, SubscriptionOverview} from '../../helpers/responseTypes';
import moment from 'moment';
import ConfirmModal from '../../components/ConfirmModal';
import * as FileSaver from 'file-saver';


import * as XLSX from 'xlsx';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    content: {
        flex: 1,
        width: '100%',
        marginTop: appBar.height,
        paddingTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
    },
});

type StateProps = {
    token: Token;
    login: string;
};

type Props = {
    classes: { [key: string]: string };
} & RouteComponentProps & StateProps;

const Subscriptions: FunctionComponent<Props> = ({classes, token, login, history}: Props) => {
    const [subscriptions, setSubscriptions] = useState<SubscriptionOverview[]>([]);
    const [isWorking, setIsWorking] = useState(false);
    const [requestFailed, setRequestFailed] = useState(false);
    const [failMessage, setFailMessage] = useState('');
    const [lastNameFilter, setLastNameFilter] = useState('');
    const [firstNameFilter, setFirstNameFilter] = useState('');
    const [subscriptionFilter, setSubscriptionFilter] = useState('');
    const [birthDateFilter, setBirthDateFilter] = useState('');
    const [groupFilter, setGroupFilter] = useState('');
    const [subGroupFilter, setSubGroupFilter] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [studentToDelete, setStudentToDelete] = useState(0);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        if (!token.access) {
            history.push('/login');
        }
    }, [history, token.access]);

    const fetchSubscriptions = useRef(() => {
        setIsWorking(true);
        setRequestFailed(false);
        setFailMessage('');
        Connector.getInstance().getAllSubscriptions()
            .then((subscriptionsResponse) => {
                if (Array.isArray(subscriptionsResponse as SubscriptionOverview[]))
                    setSubscriptions(subscriptionsResponse as SubscriptionOverview[]);
                else {
                    setFailMessage(subscriptionsResponse as string);
                    setRequestFailed(true);
                }
                setIsWorking(false);
            });
    });

    const fetchExportData = (): void => {
        setIsWorking(true);
        setRequestFailed(false);
        setFailMessage('');
        Connector.getInstance().fetchExportData()
            .then(response => {
                setIsWorking(false);
                if (response.length && !errorMessages.includes(response as string)) {
                    const responseData = response as ExportData[];

                    for (const student of responseData) {
                        student['Date de naissance'] = moment(student['Date de naissance']).format('DD/MM/YYYY');
                        student['Date de validation'] = moment(student['Date de validation']).format('DD/MM/YYYY');
                        student['Statut inscription'] = i18n.t(`SUBSCRIPTION_${student['Statut inscription']}`);
                    }

                    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                    const fileExtension = '.xlsx';

                    const ws = XLSX.utils.json_to_sheet(responseData);
                    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                    const data = new Blob([excelBuffer], {type: fileType});
                    FileSaver.saveAs(data, 'eleves' + fileExtension);
                }
            });
    };

    useEffect(() => {
        if (subscriptions.length === 0 && !isWorking) {
            fetchSubscriptions.current();
        }
    });

    const onEditClick = (id: number) => {
        history.push(`/subscription/${id}`);
    };

    const onProblemClick = (id: number) => {
        history.push(`/subscription/problem/${id}`, subscriptions.filter(sub => sub.studentId === id)[0]);
    };

    const onDeleteClick = (id: number) => {
        setStudentToDelete(id);
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setStudentToDelete(0);
    };

    const handleConfirmDelete = () => {
        setRequestFailed(false);
        setFailMessage('');
        Connector.getInstance().deleteSubscription(studentToDelete)
            .then((ok: boolean | string) => {
                if (!errorMessages.includes(ok as string))
                    setSubscriptions(subscriptions.filter(sub => sub.studentId !== studentToDelete));
                else {
                    setRequestFailed(true);
                    setFailMessage(ok as string);
                }
                handleModalClose();
            })
            .catch((e: Error): void => {
                e.message === 'LOGOUT' ? history.push('/login') : console.warn(e);
            });
    };

    const handleLastNameFilterChange = (e: ChangeEvent<HTMLInputElement>) => setLastNameFilter(e.currentTarget.value);

    const handleFirstNameFilterChange = (e: ChangeEvent<HTMLInputElement>) => setFirstNameFilter(e.currentTarget.value);

    const handleSubscriptionFilterChanged = (e: ChangeEvent<HTMLInputElement>) =>
        setSubscriptionFilter(e.currentTarget.value);

    const handleBirthDateFilterChanged = (e: ChangeEvent<HTMLInputElement>) =>
        setBirthDateFilter(e.currentTarget.value);

    const handleGroupFilterChanged = (e: ChangeEvent<HTMLInputElement>) =>
        setGroupFilter(e.currentTarget.value);

    const handleSubGroupFilterChanged = (e: ChangeEvent<HTMLInputElement>) =>
        setSubGroupFilter(e.currentTarget.value);

    const renderStudents = () =>
        <div>
            <Button variant="contained" color="default" onClick={fetchExportData}>
                {i18n.t('EXPORT_BUTTON')}
            </Button>
            <Paper>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <TextField
                                    className={classes.input}
                                    variant="outlined"
                                    id="lastName"
                                    label={i18n.t('LAST_NAME')}
                                    name="lastName"
                                    placeholder={i18n.t('LAST_NAME')}
                                    value={lastNameFilter}
                                    onChange={handleLastNameFilterChange}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    className={classes.input}
                                    variant="outlined"
                                    id="firstName"
                                    label={i18n.t('FIRST_NAME')}
                                    name="firstName"
                                    placeholder={i18n.t('FIRST_NAME')}
                                    value={firstNameFilter}
                                    onChange={handleFirstNameFilterChange}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    className={classes.input}
                                    variant="outlined"
                                    id="subscription"
                                    label={i18n.t('SUBSCRIPTION_STATE_DEFINITION')}
                                    name="subscription"
                                    placeholder={i18n.t('SUBSCRIPTION_STATE_DEFINITION')}
                                    value={subscriptionFilter}
                                    onChange={handleSubscriptionFilterChanged}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    className={classes.input}
                                    variant="outlined"
                                    id="birthDate"
                                    label={i18n.t('FORM_BIRTH_DATE')}
                                    name="birthdate"
                                    placeholder={i18n.t('FORM_BIRTH_DATE')}
                                    value={birthDateFilter}
                                    onChange={handleBirthDateFilterChanged}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    className={classes.input}
                                    variant="outlined"
                                    id="group"
                                    label={i18n.t('GROUP_TITLE')}
                                    name="group"
                                    placeholder={i18n.t('GROUP_TITLE')}
                                    value={groupFilter}
                                    onChange={handleGroupFilterChanged}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    className={classes.input}
                                    variant="outlined"
                                    id="group"
                                    label={i18n.t('SUB_GROUP_TITLE')}
                                    name="subGroup"
                                    placeholder={i18n.t('SUB_GROUP_TITLE')}
                                    value={subGroupFilter}
                                    onChange={handleSubGroupFilterChanged}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{i18n.t('LAST_NAME')}</TableCell>
                            <TableCell>{i18n.t('FIRST_NAME')}</TableCell>
                            <TableCell>{i18n.t('SUBSCRIPTION_STATE_DEFINITION')}</TableCell>
                            <TableCell>{i18n.t('FORM_BIRTH_DATE')}</TableCell>
                            <TableCell>{i18n.t('GROUP_TITLE')}</TableCell>
                            <TableCell>{i18n.t('SUB_GROUP_TITLE')}</TableCell>
                            <TableCell>{i18n.t('PROBLEM')}</TableCell>
                            <TableCell>{i18n.t('ACTIONS')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {subscriptions
                            .filter(subscription =>
                                subscription.lastName.toLowerCase().includes(lastNameFilter.toLowerCase()) &&
                                subscription.firstName.toLowerCase().includes(firstNameFilter.toLowerCase()) &&
                                i18n.t(`SUBSCRIPTION_${subscription.subscriptionState}`).toLowerCase()
                                    .includes(subscriptionFilter.toLowerCase()) &&
                                moment(subscription.birthDate).format('DD/MM/YYYY').toLowerCase()
                                    .includes(birthDateFilter.toLowerCase()) &&
                                (subscription.groupName || 'Aucun').toLowerCase().includes(groupFilter.toLowerCase())
                                && (subscription.subGroupName || 'Auncun')
                                    .toLowerCase().includes(subGroupFilter.toLowerCase())
                            )
                            .map(subscription =>
                                <TableRow key={subscription.studentId}>
                                    <TableCell>{subscription.lastName}</TableCell>
                                    <TableCell>{subscription.firstName}</TableCell>
                                    <TableCell>{i18n.t(`SUBSCRIPTION_${subscription.subscriptionState}`)}</TableCell>
                                    <TableCell>{moment(subscription.birthDate).format('DD/MM/YYYY')}</TableCell>
                                    <TableCell>{subscription.groupName || 'Aucun'}</TableCell>
                                    <TableCell>{subscription.subGroupName || 'Aucun'}</TableCell>
                                    <TableCell>{subscription.problem ? <IconButton onClick={() => {
                                        onProblemClick(subscription.studentId);
                                    }}><Warning/></IconButton> : null}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => {
                                            onEditClick(subscription.studentId);
                                        }}><Edit/></IconButton>
                                        <IconButton onClick={() => {
                                            onDeleteClick(subscription.studentId);
                                        }}><Delete/></IconButton>
                                    </TableCell>
                                </TableRow>
                            )}
                    </TableBody>
                </Table>
            </Paper>
        </div>;

    return <div className={classes.root}>
        <ConfirmModal
            isOpen={isModalOpen}
            onClose={handleModalClose}
            message={i18n.t('CONFIRM_STUDENT_DELETE')}
            onConfirm={handleConfirmDelete}
        />
        <CssBaseline/>
        <AppBar login={login} currentRoute={SET_NEXT_YEAR_GROUPS} onBurgerClick={() => {
            setIsMenuOpen(!isMenuOpen);
        }}/>
        <DrawerMenu isOpen={isMenuOpen}/>
        {isWorking
            ? <Container component="main" className={classes.content}>
                <CircularProgress/>
            </Container>
            : <Container component="main" className={classes.content}>
                {renderStudents()}
            </Container>
        }
        <SnackBarMessage content={i18n.t(failMessage)} type={SnackBarType.ERROR} isOpen={requestFailed}/>
    </div>;
};

const mapStateToProps = (state: AppState): StateProps => {
    const {token, login} = state.login;
    return {token, login};
};

export default withStyles(styles)(connect(mapStateToProps, null)(withRouter(Subscriptions)));
