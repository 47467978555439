import React, {FunctionComponent, useEffect, useState} from 'react';
import {AppState, Token} from '../../helpers/types';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {CssBaseline, Theme} from '@material-ui/core';
import {createStyles, withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {AppBar, DrawerMenu} from '..';
import {CREATE_TIME_SLOT_ROUTE, LOGIN_ROUTE} from './routes';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
    },
});


type StateProps = {
    token: Token;
    login: string;
    isAdmin: boolean;
    isCoach: boolean;
    isSectionManager: boolean;
}

type Props = {
    classes: {[key: string]: string};
} & StateProps & RouteComponentProps;

const NewTimeSlot: FunctionComponent<Props> = ({
    classes,
    token,
    login,
    history,
    isSectionManager,
    isAdmin,
    isCoach
}: Props) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    useEffect(() => {
        if (!token.access) {
            history.push(LOGIN_ROUTE);
        }
    }, [history, token.access]);
    return (
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar currentRoute={CREATE_TIME_SLOT_ROUTE} login={login} onBurgerClick={() => {
                setIsMenuOpen(!isMenuOpen);
            }}/>
            <DrawerMenu isOpen={isMenuOpen}/>

        </div>
    );
};

const mapStateToProps = (state: AppState): StateProps => {
    const {token, login, isAdmin, isCoach, isSectionManager} = state.login;
    return {token, login, isAdmin, isCoach, isSectionManager};
};

export default withStyles(styles)(withRouter(connect(mapStateToProps, null)(NewTimeSlot)));