import React, {FunctionComponent} from 'react';
import {Container, Grid, Typography, withStyles} from '@material-ui/core';
import {AllGroupsBySection} from '../helpers/types';
import GroupOverview from './GroupOverview';
import {createStyles} from '@material-ui/styles';

const styles = () => createStyles({
    root: {
        display: 'flex',
        flex: 1,
        marginTop: 20,
        marginBottom: 20,
    },
    sectionTitle: {
        flex: 0.2,
        width: '100%',
    }
});

type Props = {
    classes: { [key: string]: string };
    section: AllGroupsBySection,
    isAdmin: boolean;
    isSectionManager: boolean;
    goToGroupDetails: (id: number) => void;
    onDeleteClick: (id: number) => void;
    goToEditGroup: (id: number) => void;
    goToNewSession: (id: number) => void;
    goToMailSend: (id: number) => void;
    goToSubGroups: (id: number) => void;
    goToChooseSessionForSubGroup: (id: number) => void;
};

const SectionOverview: FunctionComponent<Props> = ({
    classes,
    section,
    isAdmin,
    isSectionManager,
    goToGroupDetails,
    onDeleteClick,
    goToEditGroup,
    goToMailSend,
    goToSubGroups,
    goToChooseSessionForSubGroup,
}: Props) => {
    const handleDetailsClick = (id: number) => goToGroupDetails(id);

    const handleDeleteClick = (id: number) => onDeleteClick(id);

    const handleEditClick = (id: number) => goToEditGroup(id);

    const handleNewSessionClick = (id: number) => {
        goToChooseSessionForSubGroup(id);
    };
    
    const handleMailClick = (id: number) => goToMailSend(id);

    const handleSubGroupClick = (id: number) => goToSubGroups(id);

    return (
        <div className={classes.root}>
            <Container>
                <Typography component="h3" variant="h6" className={classes.sectionTitle}>
                    {section.section}
                </Typography>
                <hr />
                <Grid container spacing={2}>
                    {section.groups.length > 0 ? section.groups.map(group =>
                        <GroupOverview
                            isAdmin={isAdmin}
                            isSectionManager={isSectionManager}
                            key={group.id}
                            group={group}
                            onDetailsClick={handleDetailsClick}
                            onDeleteClick={handleDeleteClick}
                            onEditClick={handleEditClick}
                            onNewSessionClick={handleNewSessionClick}
                            onMailClick={handleMailClick}
                            onSubGroupsClick={handleSubGroupClick}
                        />
                    ) : null}
                </Grid>
            </Container>
        </div>
    );
};

export default withStyles(styles)(SectionOverview);