import React, {Component, Fragment, MouseEvent} from 'react';
import {createStyles, withStyles} from '@material-ui/core/styles';
import i18n from '../i18n/i18n';
import {Fade, List, ListItem, ListItemIcon, ListItemText, Paper, Popper} from '@material-ui/core';
import {Assignment, Create, Search} from '@material-ui/icons';
import {ReferenceObject} from 'popper.js';

const styles = () => createStyles({

});

type Props = {
    onClick: (screen: string) => void;
    classes: {[key: string]: string};
    isReadOnly: boolean;
    onShowAllClick: () => void;
    onCreateSubGroupClick: () => void;
};

type State = {
    isOpen: boolean;
    anchor: ReferenceObject | null
};

class DrawerSubGroupItem extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            isOpen: false,
            anchor: null,
        };
    }

    closePopper = () => this.setState({isOpen: false});

    handleClick = (e: MouseEvent<HTMLElement>): void => {
        this.setState({isOpen: !this.state.isOpen, anchor: e.currentTarget});
        this.props.onClick('SUB_GROUP');
    };

    handleSubGroupCreateClick = (): void => {
        this.setState({isOpen: false});
        this.props.onCreateSubGroupClick();
    };

    handleShowAllClick = (): void => {
        this.setState({isOpen: false});
        this.props.onShowAllClick();
    };

    render = () => (
        <Fragment>
            <Popper open={this.state.isOpen} anchorEl={this.state.anchor} placement="right-start" transition>
                {({TransitionProps}) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper className={this.props.classes.paper}>
                            <List>
                                {this.props.isReadOnly ? null :
                                    <ListItem button onClick={this.handleSubGroupCreateClick}>
                                        <ListItemIcon><Create/></ListItemIcon>
                                        <ListItemText primary={i18n.t('SUB_GROUP_CREATION_TITLE')}/>
                                    </ListItem>
                                }
                                <ListItem button onClick={this.handleShowAllClick}>
                                    <ListItemIcon><Search/></ListItemIcon>
                                    <ListItemText primary={i18n.t('SHOW_ALL_SUB_GROUPS_TITLE')}/>
                                </ListItem>
                            </List>
                        </Paper>
                    </Fade>
                )}
            </Popper>
            <ListItem button onClick={this.handleClick}>
                <ListItemIcon><Assignment/></ListItemIcon>
                <ListItemText primary={i18n.t('SUB_GROUP_DRAWER_ITEM')}/>
            </ListItem>
        </Fragment>
    );
}
export default withStyles(styles)(DrawerSubGroupItem);