export type RequestState = {
    requested: boolean;
    succeeded: boolean;
    failed: boolean;
    failMessage: string;
}

export type SessionStudent = {
    id: number;
    isLate: boolean;
    isAbsent: boolean;
    firstName: string;
    lastName: string;
};

export type Session = {
    groupId: number | null;
    name: string;
    subGroupId: number | null;
    date: Date;
    students: SessionStudent[],
    request: RequestState;
    isTransmitted: boolean;
};

export enum SESSION_ACTIONS {
    ACTION_REQUESTED = 'SESSION_ACTIONS/ACTION_REQUESTED',
    ACTION_FAILED = 'SESSION_ACTIONS/ACTION_FAILED',
    ACTION_SUCCEEDED = 'SESSION_ACTIONS/ACTION_SUCCEEDED',
    CREATE_SESSION = 'SESSION_ACTIONS/CREATE_SESSION',
    ASSIGN_STUDENT_TO_SESSION = 'SESSION_ACTIONS/ASSIGN_STUDENT_TO_SESSION',
    BULK_ASSIGN_STUDENT_TO_SESSION = 'SESSION_ACTIONS/BULK_ASSIGN_STUDENT_TO_SESSION',
    EDIT_STUDENT_SESSION = 'SESSION_ACTIONS/EDIT_STUDENT_SESSION',
    SUBMIT_SESSION = 'SESSION_ACTIONS/SUBMIT_SESSION',
    RESET_SESSION = 'SESSION_ACTIONS/RESET_SESSION',
    SET_SESSION_GROUP = 'SESSION_ACTIONS/SET_SESSION_GROUP',
    CLEAR_STUDENTS = 'SESSION_ACTIONS/CLEAR_STUDENTS',
    SET_SESSION_DATE = 'SESSION_ACTIONS/SET_SESSION_DATE',
    SET_SESSION = 'SESSION_ACTIONS/SET_SESSION',
}

export type SessionActionRequested = {
    type: SESSION_ACTIONS;
};

export type SessionActionSucceeded = {
    type: SESSION_ACTIONS;
}

export type SessionActionFailed = {
    type: SESSION_ACTIONS;
    failMessage: string;
};

export type CreateSession = {
    type: SESSION_ACTIONS;
    groupId: number | null;
    name: string;
    subGroupId: number | null;
    date: Date;
}

export type AssignStudentToSession = {
    type: SESSION_ACTIONS;
    studentId: number;
    firstName: string;
    lastName: string;
    isLate: boolean;
    isAbsent: boolean;
}

export type EditStudentSession = {
    type: SESSION_ACTIONS;
    studentId: number;
    isLate: boolean;
    isAbsent: boolean;
}

export type SubmitSession = {
    type: SESSION_ACTIONS;
}

export type SetSessionGroup = {
    type: SESSION_ACTIONS;
    groupId: number;
    subGroupId: number;
    name: string;
}

export type ClearStudents = {
    type: SESSION_ACTIONS;
}

export type ResetSession = {
    type: SESSION_ACTIONS;
};

export type SetSessionDate = {
    type: SESSION_ACTIONS;
    date: Date;
}

export type BulkAssignStudentToSession = {
    type: SESSION_ACTIONS;
    students: SessionStudent[];
}

export type SetSession = {
    type: SESSION_ACTIONS;
    session: Session;
}

export type SessionActions =
    SessionActionRequested
    | SessionActionSucceeded
    | SessionActionFailed
    | CreateSession
    | AssignStudentToSession
    | EditStudentSession
    | SubmitSession
    | SetSessionGroup
    | ClearStudents
    | ResetSession
    | SetSessionDate
    | BulkAssignStudentToSession
    | SetSession;
