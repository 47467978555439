import React from 'react';
import {
    Container,
    CssBaseline,
    Grid,
    TextField,
    Typography,
    IconButton,
    Tooltip, CircularProgress
} from '@material-ui/core';
import {Add} from '@material-ui/icons';
import {withStyles} from '@material-ui/core/styles';
import ErrorView from './ErrorView';

import i18n from '../i18n/i18n';

const styles = theme => ({
    form: {
        width: '100%', // Fix IE 11 issue (apparently)
        marginBottom: theme.spacing(3)
    },
});

class StudentContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSecondEmail: false,
            guardianFname: this.props.guardianFname || '',
            guardianLname: this.props.guardianLname || '',
            guardianEmail: this.props.guardianEmail
            || this.props.data.guardian ? this.props.data.guardian.email : '',
            guardianPhone: this.props.guardianPhone
            || this.props.data.guardian ? this.props.data.guardian.phoneNumber : '',
            secondGuardianEmail: this.props.secondGuardianEmail
            || this.props.data.guardian ? this.props.data.guardian.secondaryEmail : '',
            secondGuardianPhone: this.props.secondGuardianPhone
            || this.props.data.guardian ? this.props.data.guardian.secondaryPhoneNumber : '',
            fnameError: false,
            lnameError: false,
            emailError: false,
            phoneError: false,
            secondEmailError: false,
            secondPhoneError: false,
            birthDate: this.props.birthDate ? this.props.birthDate : '',
            errorList: [],
            loaded: false,
        };
    }

    componentDidMount() {
        if (!this.state.loaded && Object.keys(this.props.data).length !== 0) {
            this.setState({
                guardianFname: this.state.guardianFname ? this.state.guardianFname
                    : this.props.data.guardian && this.props.data.guardian.firstName
                        ? this.props.data.guardian.firstName : '',
                guardianLname: this.state.guardianLname ? this.state.guardianLname
                    : this.props.data.guardian && this.props.data.guardian.lastName
                        ? this.props.data.guardian.lastName : '',
                guardianEmail: this.props.data.guardian && this.props.data.guardian.email
                    ? this.props.data.guardian.email : '',
                guardianPhone: this.props.data.guardian && this.props.data.guardian.phoneNumber
                    ? this.props.data.guardian.phoneNumber : '',
                secondGuardianEmail: this.props.data.guardian && this.props.data.guardian.secondaryEmail
                    ? this.props.data.guardian.secondaryEmail : '',
                secondGuardianPhone: this.props.data.guardian && this.props.data.guardian.secondaryPhoneNumber
                    ? this.props.data.guardian.secondaryPhoneNumber : '',
                birthDate: this.props.birthDate,
                loaded: true
            });
        }
    }

    handleFnameFocus = () => this.setState({fnameError: false});
    handleLnameFocus = () => this.setState({lnameError: false});
    handleEmailFocus = () => this.setState({emailError: false});
    handlePhoneFocus = () => this.setState({phoneError: false});
    handleSecondEmailFocus = () => this.setState({secondEmailError: false});
    handleSecondPhoneFocus = () => this.setState({secondPhoneError: false});

    handleFnameChange = e => this.setState({guardianFname: e.target.value});
    handleLnameChange = e => this.setState({guardianLname: e.target.value});
    handleEmailChange = e => this.setState({guardianEmail: e.target.value});
    handlePhoneChange = e => this.setState({guardianPhone: e.target.value});
    handleSecondEmailChange = e => this.setState({secondGuardianEmail: e.target.value});
    handleSecondPhoneChange = e => this.setState({secondGuardianPhone: e.target.value});

    handleShowSecondEmail = () => this.setState({showSecondEmail: true});

    isFnameValid = () =>
        !(this.state.guardianFname === ''
            || !(/^[a-zA-Z0-9\u00C0-\u024F\u1E00-\u1EFF \-'.(),]+$/g.test(this.state.guardianFname)));
    isLnameValid = () =>
        !(this.state.guardianLname === ''
            || !(/^[a-zA-Z0-9\u00C0-\u024F\u1E00-\u1EFF \-'.(),]+$/g.test(this.state.guardianLname)));
    isEmailValid = () => /^[^@!]+@[^@!]+\.[a-z]{2,64}$/.test(this.state.guardianEmail);
    isPhoneValid = () => /^(0|00|\+)?[0-9]{8,20}$/.test(this.state.guardianPhone);
    isSecondEmailValid = () =>
        /^[^@!]+@[^@!]+\.[a-z]{2,64}$/.test(this.state.secondGuardianEmail) || this.state.secondGuardianEmail === '';
    isSecondPhoneValid = () =>
        /^(0|00|\+)?[0-9]{8,20}$/.test(this.state.secondGuardianPhone) || this.state.secondGuardianPhone === '';

    submitContent = () => {
        const {
            guardianFname,
            guardianLname,
            guardianEmail,
            guardianPhone,
            secondGuardianEmail,
            secondGuardianPhone,
        } = this.state;
        let errorList = [], error = false;

        if (!this.isFnameValid()) {
            error = true;
            errorList.push('FNAME_ERROR');
            this.setState({fnameError: true});
        }
        if (!this.isLnameValid()) {
            error = true;
            errorList.push('LNAME_ERROR');
            this.setState({lnameError: true});
        }
        if (!this.isEmailValid()) {
            error = true;
            errorList.push('EMAIL_ERROR');
            this.setState({emailError: true});
        }
        if (!this.isPhoneValid()) {
            error = true;
            errorList.push('PHONE_ERROR');
            this.setState({phoneError: true});
        }
        if (this.state.showSecondEmail) {
            if (!this.isSecondPhoneValid()) {
                error = true;
                errorList.push('SECOND_PHONE_ERROR');
                this.setState({secondPhoneError: true});
            }
            if (!this.isSecondEmailValid()) {
                error = true;
                errorList.push('SECOND_EMAIL_ERROR');
                this.setState({secondEmailError: true});
            }
        }

        this.setState({errorList: errorList});
        return error ? null : {
            guardianFname,
            guardianLname,
            guardianEmail,
            guardianPhone,
            secondGuardianEmail: secondGuardianEmail || undefined,
            secondGuardianPhone: secondGuardianPhone || undefined
        };
    };

    isStudentMajor = birthDate => {
        const todayDate = new Date();
        const majorLimit = new Date();
        majorLimit.setFullYear(todayDate.getFullYear() - 18);
        return majorLimit > new Date(birthDate);
    };

    render() {
        const {showSecondEmail} = this.state;
        const {classes} = this.props;
        return (
            <Container component="main" maxWidth="sm">
                <CssBaseline/>
                <Typography component="h3" variant="h6">
                    {this.isStudentMajor(this.state.birthDate) ? i18n.t('CONTACT_LABEL')
                        : i18n.t('GUARDIAN_LABEL')}
                </Typography>
                {this.state.loaded ? <form className={classes.form} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="fname"
                                name="guardianFirstName"
                                required
                                fullWidth
                                id="guardianFirstName"
                                label={i18n.t('FIRST_NAME_FIELD')}
                                value={this.state.guardianFname}
                                onChange={this.handleFnameChange}
                                error={this.state.fnameError}
                                onBlur={() => this.setState({fnameError: !this.isFnameValid()})}
                                onFocus={this.handleFnameFocus}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="lname"
                                name="guardianLastName"
                                required
                                fullWidth
                                id="guardianLastName"
                                label={i18n.t('LAST_NAME_FIELD')}
                                value={this.state.guardianLname}
                                onChange={this.handleLnameChange}
                                error={this.state.lnameError}
                                onBlur={() => this.setState({lnameError: !this.isLnameValid()})}
                                onFocus={this.handleLnameFocus}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="email"
                                name="email"
                                required
                                fullWidth
                                id="email"
                                label={i18n.t('EMAIL_FIELD')}
                                value={this.state.guardianEmail}
                                onChange={this.handleEmailChange}
                                error={this.state.emailError}
                                onBlur={() => this.setState({emailError: !this.isEmailValid()})}
                                onFocus={this.handleEmailFocus}
                            />
                        </Grid>
                        <Grid item xs={showSecondEmail ? 12 : 11} sm={showSecondEmail ? 6 : 5}>
                            <TextField
                                autoComplete="phonenumber"
                                name="phoneNumber"
                                required
                                fullWidth
                                id="phoneNumber"
                                label={i18n.t('PHONE_NUMBER_FIELD')}
                                value={this.state.guardianPhone}
                                onChange={this.handlePhoneChange}
                                error={this.state.phoneError}
                                onBlur={() => this.setState({phoneError: !this.isPhoneValid()})}
                                onFocus={this.handlePhoneFocus}
                            />
                        </Grid>
                        {showSecondEmail ? (
                            <React.Fragment>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        autoComplete="email"
                                        name="secondEmail"
                                        fullWidth
                                        id="secondEmail"
                                        label={i18n.t('EMAIL_FIELD')}
                                        value={this.state.secondGuardianEmail}
                                        onChange={this.handleSecondEmailChange}
                                        error={this.state.secondEmailError}
                                        onBlur={() => this.setState({secondEmailError: !this.isSecondEmailValid()})}
                                        onFocus={this.handleSecondEmailFocus}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="secondPhoneNumber"
                                        fullWidth
                                        id="secondPhoneNumber"
                                        label={i18n.t('PHONE_NUMBER_FIELD')}
                                        value={this.state.secondGuardianPhone}
                                        onChange={this.handleSecondPhoneChange}
                                        error={this.state.secondPhoneError}
                                        onBlur={() => this.setState({secondPhoneError: !this.isSecondPhoneValid()})}
                                        onFocus={this.handleSecondPhoneFocus}
                                    />
                                </Grid>
                            </React.Fragment>
                        ) : (
                            <Grid item xs={1} sm={1}>
                                <Tooltip title={i18n.t('ADD_CONTACT_TOOLTIP')} placement="right">
                                    <IconButton onClick={this.handleShowSecondEmail}>
                                        <Add/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        )}
                    </Grid>
                    <ErrorView errorList={this.state.errorList}/>
                </form> : <CircularProgress/>}
            </Container>
        );
    }
}

export default withStyles(styles)(StudentContactForm);
