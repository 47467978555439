import {red} from '@material-ui/core/colors';

const error = {
    main: red.A400,
    background: 'rgba(255,0,0,0.27)'
};

const success = {
    background: 'rgba(7,255,49,0.27)',
};

const borders = {
    color: '#919191',
};

const drawer = {
    zIndex: 10,
};

const appBar = {
    zIndex: 2,
    color: '#da4444',
    textColor: '#fff',
    height: 75,
};

const menuItem = {
    display: 'flex',
    flexDirection: 'column',
};

const menuItemTitle = {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
};

const menuItemIcon = {
    flex: 0.2,
};

const menuItemText = {
    flex: 0.8
};

export {error, success, borders, drawer, appBar, menuItem, menuItemIcon, menuItemText, menuItemTitle, red};
