import React, {Fragment, FunctionComponent, useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import i18n from '../i18n/i18n';
import {List, ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import {Assignment, Search} from '@material-ui/icons';
import {menuItem, menuItemIcon, menuItemText, menuItemTitle} from '../styles';

const styles = (): object => ({
    menuItem: menuItem,
    menuItemTitle: menuItemTitle,
});

type Props = {
    classes: { [key: string]: string };
    isReadOnly?: boolean;
    onShowAllClick: () => void;
};

const DrawerSessionItem: FunctionComponent<Props> = ({classes, onShowAllClick}: Props) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = (): void => {
        setIsOpen(!isOpen);
    };

    const handleShowAllClick = (): void => {
        setIsOpen(false);
        onShowAllClick();
    };

    return (
        <Fragment>
            <ListItem button onClick={handleClick} className={classes.menuItem}>
                <div className={classes.menuItemTitle}>
                    <div style={menuItemIcon}>
                        <ListItemIcon><Assignment/></ListItemIcon>
                    </div>
                    <div style={menuItemText}>
                        <ListItemText primary={i18n.t('SESSION_DRAWER_ITEM')}/>
                    </div>
                </div>
                <div style={{
                    display: isOpen ? 'block' : 'none',
                }}>
                    <List>
                        <ListItem button onClick={handleShowAllClick}>
                            <ListItemIcon><Search/></ListItemIcon>
                            <ListItemText primary={i18n.t('SHOW_ALL_SESSIONS_TITLE')}/>
                        </ListItem>
                    </List>
                </div>
            </ListItem>
        </Fragment>
    );
};
export default withStyles(styles)(DrawerSessionItem);
