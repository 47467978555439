import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Paper, ListItem, List} from '@material-ui/core';
import i18n from '../i18n/i18n';
import {error} from '../styles';

const styles = theme => ({
    root: {
        display: 'flex',
        backgroundColor: error.background,
        width: '80%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    hidden: {
        display: 'none'
    }
});

class ErrorView extends Component {
    renderErrors = () => this.props.errorList.map((error, index) => <ListItem key={index}>{i18n.t(error)}</ListItem>);

    render() {
        const {classes} = this.props;
        return (
            <Paper className={`${classes.root} ${this.props.errorList.length === 0 ? classes.hidden : ''}`}>
                <List>
                    {this.renderErrors()}
                </List>
            </Paper>
        );
    }
}

export default withStyles(styles)(ErrorView);
