import React, {FunctionComponent, ReactElement, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {appBar} from '../../styles';
import {CircularProgress, Container, CssBaseline, Grid, IconButton, Paper, Theme, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {AppState, errorMessages, Token} from '../../helpers/types';
import {Section} from '../../helpers/responseTypes';
import AppBar from '../AppBar';
import DrawerMenu from '../DrawerMenu';
import {SHOW_ALL_GROUPS_ROUTE} from './routes';
import {Connector} from '../../helpers/Connector';
import i18n from '../../i18n/i18n';
import {createStyles} from '@material-ui/styles';
import {Delete, Edit} from '@material-ui/icons';
import SnackBarMessage, {SnackBarType} from '../../components/SnackBarMessage';
import ConfirmModal from '../../components/ConfirmModal';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
    },
    content: {
        flex: 1,
        width: '100%',
        marginTop: appBar.height,
        paddingTop: theme.spacing(2),
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        width: '100%',
        flex: 0.2,
        textAlign: 'center',
    },
    info: {
        flex: 0.1,
        width: '100%'
    },
    testInfo: {
        flex: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 0,
    },
    iconRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

type StateProps = {
    token: Token;
    login: string;
    isAdmin: boolean;
    isCoach: boolean;
    isSectionManager: boolean;
}

type Props = {
    classes: { [key: string]: string };
} & StateProps & RouteComponentProps;

const AllSections: FunctionComponent<Props> = ({
    classes,
    token,
    login,
    isAdmin,
    history,
}: Props) => {
    const [sections, setSections] = useState<Section[]>([]);
    const [isWorking, setIsWorking] = useState(false);
    const [failMessage, setFailMessage] = useState('');
    const [requestFailed, setRequestFailed] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [sectionToDelete, setSectionToDelete] = useState(0);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        if (!token.access) {
            history.push('/login');
        }
    }, [history, token.access]);

    useEffect(() => {
        if (sections.length === 0 && !requestFailed) {
            setIsWorking(true);
            Connector.getInstance().getSections()
                .then(sections => {
                    if (!errorMessages.includes(sections as string)) {
                        setSections(sections as Section[]);
                    } else {
                        setRequestFailed(true);
                        setFailMessage(sections as string);
                    }
                    setIsWorking(false);
                })
                .catch(error => {
                    setRequestFailed(true);
                    if (error && errorMessages.includes(error)) {
                        setFailMessage(error as string);
                    } else {
                        setFailMessage('UNKNOWN_ERROR');
                    }
                    setIsWorking(false);
                });
        }
    }, [requestFailed, sections.length]);

    const handleDeleteClick = (id: number): void => {
        setSectionToDelete(id);
        setIsModalOpen(true);
    };

    const handleEditClick = (id: number): void => {
        history.push(`${id}`);
    };

    const handleModalClose = (): void => {
        setSectionToDelete(0);
        setIsModalOpen(false);
    };

    const handleConfirmDelete = (): void => {
        if (sectionToDelete > 0) {
            setIsWorking(true);
            setRequestFailed(false);
            setFailMessage('');
            Connector.getInstance().deleteSection(sectionToDelete)
                .then(() => {
                    setIsModalOpen(false);
                    setIsWorking(false);
                    setSections(sections.filter(section => section.id !== sectionToDelete));
                    setSectionToDelete(0);
                })
                .catch(ok => {
                    setFailMessage(ok as string);
                    setRequestFailed(true);
                    setSectionToDelete(0);
                    setIsModalOpen(false);
                    setIsWorking(false);
                });
        } else {
            setSectionToDelete(0);
            setIsModalOpen(false);
        }
    };

    const renderSectionOverViews = (): ReactElement[] => sections.map(section =>
        <Grid item sm={4} xs={3} key={section.id}>
            <React.Fragment>
                <CssBaseline/>
                <Paper className={classes.root}>
                    <div className={classes.testInfo}>
                        <Typography component="h3" variant="h6" className={classes.title}>
                            {section.name}
                        </Typography>
                        <Typography paragraph className={classes.info}>
                            {i18n.t('SECTION_MANAGER_DEFINITION', {
                                sectionManager: section.Coach
                                    ? `${section.Coach.firstName} ${section.Coach.lastName}` : '',
                            })}
                        </Typography>
                        <Typography paragraph className={classes.info}>
                            {i18n.t('SECTION_GROUPS_DEFINITION', {
                                groupList: section.groups.map(group => group.name).join(', '),
                            })}
                        </Typography>
                        <div className={classes.iconRow}>
                            {isAdmin && section.name !== 'Aucune'
                                ? <IconButton onClick={() => {
                                    handleDeleteClick(section.id);
                                }}>
                                    <Delete/>
                                </IconButton> : null}
                            <IconButton onClick={() => {
                                handleEditClick(section.id);
                            }}>
                                <Edit/>
                            </IconButton>
                        </div>
                    </div>
                </Paper>
            </React.Fragment>
        </Grid>);

    return (
        <div className={classes.root}>
            <ConfirmModal
                isOpen={isModalOpen}
                onClose={handleModalClose}
                message={i18n.t('CONFIRM_SECTION_DELETE')}
                onConfirm={handleConfirmDelete}
            />
            <CssBaseline/>
            <AppBar currentRoute={SHOW_ALL_GROUPS_ROUTE} login={login} onBurgerClick={() => {
                setIsMenuOpen(!isMenuOpen);
            }}/>
            <DrawerMenu isOpen={isMenuOpen}/>
            {isWorking
                ? <Container component="main" className={classes.content}>
                    <CircularProgress/>
                </Container>
                : <Container component="main" className={classes.content}>
                    <Grid container spacing={2}>
                        {renderSectionOverViews()}
                    </Grid>
                </Container>}
            <SnackBarMessage content={i18n.t(failMessage)} type={SnackBarType.ERROR} isOpen={requestFailed} />
        </div>
    );
};

const mapStateToProps = (state: AppState): StateProps => {
    const {token, login, isAdmin, isCoach, isSectionManager} = state.login;
    return {token, login, isAdmin, isCoach, isSectionManager};
};
export default withStyles(styles)(withRouter(connect(mapStateToProps, null)(AllSections)));
