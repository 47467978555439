import {
    SUBSCRIBE_ACTION,
    SubscribeActions,
    SubscribeState,
    SubscriptionFailure,
    SubscriptionRequest,
    SubscriptionSuccess
} from './types';

const initialState: SubscribeState = {
    fname: '',
    lname: '',
    gender: 'M',
    birthDate: '',
    birthPlace: '',
    nationality: '',
    guardianFname: '',
    guardianLname: '',
    guardianEmail: '',
    guardianPhone: '',
    instagramHandle: '',
    secondGuardianEmail: '',
    secondGuardianPhone: '',
    address: '',
    city: '',
    zipCode: '',
    country: '',
    request: {
        requested: false,
        succeeded: false,
        failed: false,
        failMessage: '',
    },
    id: 0,
};

export const subscribe = (state = initialState, action: SubscribeActions): SubscribeState => {
    switch (action.type) {
    case SUBSCRIBE_ACTION.SUBSCRIPTION_REQUEST: {
        const {formContent} = action as SubscriptionRequest;
        return Object.assign({}, state, {
            fname: formContent.fname,
            lname: formContent.lname,
            gender: formContent.gender,
            birthDate: formContent.birthDate,
            birthPlace: formContent.birthPlace,
            nationality: formContent.nationality,
            guardianFname: formContent.guardianFname,
            guardianLname: formContent.guardianLname,
            guardianEmail: formContent.guardianEmail,
            guardianPhone: formContent.guardianPhone,
            instagramHandle: formContent.instagramHandle,
            secondGuardianEmail: formContent.secondGuardianEmail,
            secondGuardianPhone: formContent.secondGuardianPhone,
            address: formContent.address,
            city: formContent.city,
            zipCode: formContent.zipCode,
            country: formContent.country,
            shirtSizeId: formContent.shirtSizeId,
            shortSizeId: formContent.shortSizeId,
            request: {
                requested: true,
                succeeded: false,
                failed: false,
                failMessage: '',
            }
        });
    }
    case SUBSCRIBE_ACTION.SUBSCRIPTION_SUCCESS: {
        const {id} = action as SubscriptionSuccess;
        return Object.assign({}, state, {
            id: id,
            request: {
                requested: false,
                succeeded: true,
                failed: false,
                failMessage: ''
            }
        }); 
    }
    case SUBSCRIBE_ACTION.SUBSCRIPTION_FAILURE: {
        const {failMessage} = action as SubscriptionFailure;
        return Object.assign({}, state, {
            request: {
                requested: false,
                succeeded: false,
                failed: true,
                failMessage: failMessage || ''
            }
        });
    }
    default:
        return state;
    }
};