import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {LoginForm, AppBar} from '../';
import ls from 'local-storage';

class Login extends Component {
    componentDidMount() {
        if (ls('access')) this.handleLoggedIn();
    }

    handleLoggedIn = () => {
        this.props.history.push('/home');
    };

    render() {
        return (
            <Fragment>
                <AppBar currentRoute={this.props.location.pathname}/>
                <LoginForm
                    handleLoggedIn={this.handleLoggedIn}
                />
            </Fragment>
        );
    }
}

export default withRouter(connect(null, null)(Login));
