import {
    AssignStudentToSession,
    BulkAssignStudentToSession,
    ClearStudents,
    CreateSession,
    EditStudentSession,
    ResetSession,
    Session,
    SESSION_ACTIONS,
    SessionActionFailed,
    SessionActionRequested,
    SessionActions,
    SessionActionSucceeded,
    SessionStudent,
    SetSession,
    SetSessionDate,
    SetSessionGroup,
    SubmitSession
} from './types';
import {ThunkAction, ThunkDispatch} from 'redux-thunk';
import {Connector} from '../../helpers/Connector';

export const actionRequested = (): SessionActionRequested => ({
    type: SESSION_ACTIONS.ACTION_REQUESTED,
});

export const actionSucceeded = (): SessionActionSucceeded => ({
    type: SESSION_ACTIONS.ACTION_SUCCEEDED,
});

export const actionFailed = (failMessage: string): SessionActionFailed => ({
    type: SESSION_ACTIONS.ACTION_FAILED,
    failMessage,
});

export const setSession = (session: Session): SetSession => ({
    type: SESSION_ACTIONS.SET_SESSION,
    session,
});

export const createSessionAction = (
    date: Date,
    name: string,
    groupId: number | null,
    subGroupId: number | null,
): CreateSession => ({
    type: SESSION_ACTIONS.CREATE_SESSION,
    name,
    groupId,
    subGroupId,
    date,
});

export const setSessionGroupAction = (groupId: number, subGroupId: number, name: string): SetSessionGroup => ({
    type: SESSION_ACTIONS.SET_SESSION_GROUP,
    groupId,
    subGroupId,
    name,
});

export const assignStudentToSessionAction = (
    studentId: number,
    isLate: boolean,
    isAbsent: boolean,
    firstName: string,
    lastName: string,
): AssignStudentToSession => ({
    type: SESSION_ACTIONS.ASSIGN_STUDENT_TO_SESSION,
    studentId,
    isLate,
    isAbsent,
    firstName,
    lastName,
});

export const editStudentSessionAction = (
    studentId: number,
    isLate: boolean,
    isAbsent: boolean,
): EditStudentSession => ({
    type: SESSION_ACTIONS.EDIT_STUDENT_SESSION,
    studentId,
    isLate,
    isAbsent,
});

export const clearStudentsAction = (): ClearStudents => ({
    type: SESSION_ACTIONS.CLEAR_STUDENTS,
});

export const resetSession = (): ResetSession => ({
    type: SESSION_ACTIONS.RESET_SESSION,
});

export const setSessionDate = (date: Date): SetSessionDate => ({
    type: SESSION_ACTIONS.SET_SESSION_DATE,
    date,
});

export const bulkAssignStudentToSession = (students: SessionStudent[]): BulkAssignStudentToSession => ({
    type: SESSION_ACTIONS.BULK_ASSIGN_STUDENT_TO_SESSION,
    students,
});

export const submitSessionAction = (): SubmitSession => ({
    type: SESSION_ACTIONS.SUBMIT_SESSION,
});

export const submitSession = (session: Session, edit = false): ThunkAction<void, unknown, unknown, SessionActions> =>
    (dispatch: ThunkDispatch<unknown, unknown, SessionActions>): void => {
        dispatch(actionRequested);
        if (!edit) {
            Connector.getInstance().submitSession(session)
                .then(ok => {
                    if (typeof ok === 'boolean') {
                        dispatch(submitSessionAction());
                    } else {
                        dispatch(actionFailed('UNKNOWN_ERROR'));
                    }
                })
                .catch(error => {
                    dispatch(actionFailed(error));
                });
        } else {
            Connector.getInstance().editSession(session)
                .then(ok => {
                    if (typeof ok === 'boolean') {
                        dispatch(submitSessionAction());
                    } else {
                        dispatch(actionFailed('UNKNOWN_ERROR'));
                    }
                })
                .catch(error => {
                    dispatch(actionFailed(error));
                });
        }
    };
