import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import Alert from '@material-ui/lab/Alert';
import {Slide, SlideProps, Snackbar} from '@material-ui/core';

export enum SnackBarType {
    SUCCESS = 'success',
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
}

type Props = {
    content: string,
    type: SnackBarType,
    isOpen: boolean,
};

const SnackBarMessage: FunctionComponent<Props> = ({content, type, isOpen}: Props) => {
    const [open, setOpen] = useState(isOpen);
    const SlideUp = (props: SlideProps) => <Slide {...props} direction="up" />;

    const prevOpenRef = useRef(isOpen);

    useEffect(() => {
        prevOpenRef.current = isOpen;
    });

    const prevOpen = prevOpenRef.current;

    useEffect(() => {
        if (isOpen !== prevOpen) {
            setOpen(isOpen);
        }
    }, [isOpen, open, prevOpen]);

    const handleClose = (event: React.SyntheticEvent,
        reason: string): void => {
        if (reason === 'clickaway') return;
        setOpen(false);
    };

    return <Snackbar open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        TransitionComponent={SlideUp}>
        <Alert severity={type} elevation={6} variant="filled">
            {content}
        </Alert>
    </Snackbar>;
};

export default SnackBarMessage;
