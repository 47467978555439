import React, {ChangeEvent, FunctionComponent, ReactElement, useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {appBar} from '../../styles';
import {
    Button,
    CircularProgress,
    Container,
    CssBaseline,
    IconButton,
    List,
    MenuItem,
    Modal,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Theme,
    Tooltip,
    Typography
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {AppState, errorMessages, Token} from '../../helpers/types';
import {CoachOverView, SubGroupDetails} from '../../helpers/responseTypes';
import AppBar from '../AppBar';
import DrawerMenu, {drawerWidth} from '../DrawerMenu';
import {SHOW_ALL_GROUPS_ROUTE} from './routes';
import {Connector} from '../../helpers/Connector';
import i18n from '../../i18n/i18n';
import {createStyles} from '@material-ui/styles';
import moment from 'moment';
import {Add, AddCircleOutlineOutlined, Delete, RemoveCircleOutlineOutlined} from '@material-ui/icons';
import {ConfirmModal, SnackBarMessage} from '../../components';
import {SnackBarType} from '../../components/SnackBarMessage';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        height: window.innerHeight,
    },
    content: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        minWidth: window.innerWidth - drawerWidth,
        marginTop: appBar.height,
        paddingTop: theme.spacing(2),
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        height: window.innerHeight - appBar.height,
        maxHeight: window.innerHeight - appBar.height,
        marginLeft: 0,
        marginRight: 0,
    },
    groupStudents: {
        flex: 0.5,
        maxHeight: window.innerHeight - appBar.height - theme.spacing(2),
        height: window.innerHeight - appBar.height - theme.spacing(2),
    },
    subGroupStudents: {
        flex: 0.3,
        maxHeight: window.innerHeight - appBar.height - theme.spacing(2),
        height: window.innerHeight - appBar.height - theme.spacing(2),
    },
    subGroupSelectView: {
        flex: 0.2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    subGroupSelect: {
        width: '100%',
        marginTop: theme.spacing(1),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        textAlign: 'center',
        border: '1px solid black',
    },
    subGroupSelectItem: {
        textAlign: 'center',
        textAlignVertical: 'center',
    },
    iconRow: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    noSubGroupContent: {
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    modalRoot: {
        position: 'absolute',
        width: 1000,
        height: 500,
        top: '50%',
        marginTop: -250,
        left: '50%',
        marginLeft: -500,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 4),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        outline: 0,
    },
    coachList: {
        flex: 0.3,
        flexDirection: 'column',
        width: '90%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        textAlignVertical: 'center',
    },
    coachName: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        textAlignVertical: 'center',
    },
    addIcon: {
        flex: 0.2
    },
    addCoachDiv: {
        flex: 0.2,
        width: '90%',
        display: 'flex',
        flexDirection: 'row',
    },
    coachSelect: {
        flex: 0.8,
    },
    buttonView: {
        width: '70%',
        flex: 0.2,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    newSubGroupName: {
        width: '80%',
        flex: 0.1,
    },
    modalButton: {
        flex: 0.4,
        height: '70%',
    }
});

type StateProps = {
    token: Token;
    login: string;
    isAdmin: boolean;
    isCoach: boolean;
    isSectionManager: boolean;
};

type Props = {
    classes: { [key: string]: string };
} & StateProps & RouteComponentProps;

const AllSubGroups: FunctionComponent<Props> = ({
    classes,
    token,
    login,
    location,
    history,
}: Props) => {
    const [subGroupDetails, setSubGroupDetails] = useState<SubGroupDetails | null>(null);
    const [isWorking, setIsWorking] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [requestFailed, setRequestFailed] = useState(false);
    const [failMessage, setFailMessage] = useState('');    
    const [selectedSubGroup, setSelectedSubGroup] = useState(0);
    const [subGroups, setSubGroups] = useState<{id: number; name: string}[]>([]);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [newSubGroupName, setNewSubGroupName] = useState('');
    const [newSubGroupNameError, setNewSubGroupNameError] = useState(false);
    const [newSubGroup, setNewSubGroup] = useState<{
        id: number;
        name: string;
        coaches: CoachOverView[];
    }>({id: 0, name: newSubGroupName, coaches: []});
    const [selectedNewCoach, setSelectedNewCoach] = useState(0);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        if (!token.access) {
            history.push('/login');
        }
    }, [history, token.access]);

    const fetchSubGroupDetails = useRef(() => {
        setIsWorking(true);
        Connector.getInstance().getSubGroupsDetails(
            parseInt(location.pathname.replace(/\/group\/([0-9]+)\/subgroups/, '$1')))
            .then(group => {
                if (!errorMessages.includes(group as string)) {
                    setSubGroupDetails(group as SubGroupDetails);
                    setSubGroups((group as SubGroupDetails).subGroups);
                    if ((group as SubGroupDetails).subGroups.length)
                        setSelectedSubGroup((group as SubGroupDetails).subGroups[0].id);
                    setSelectedNewCoach((group as SubGroupDetails).coaches[0].id);
                    setIsWorking(false);
                } else {
                    setRequestFailed(true);
                    setFailMessage(group as string);
                }
            })
            .catch(error => {
                setRequestFailed(true);
                if ((error as Error).message === 'LOGOUT') history.push('/login');
                if (errorMessages.includes(error as string)) setFailMessage(error as string);
            });
    });

    useEffect(() => {
        if (!isWorking && subGroupDetails === null) {
            fetchSubGroupDetails.current();
        }
    });

    const handleSubGroupChange = (e: ChangeEvent<{ name?: string | undefined; value: unknown; }>): void =>
        setSelectedSubGroup(e.target.value as number);

    const handleGroupStudentClick = (id: number) => {
        if (subGroupDetails) {
            Connector.getInstance().changeSubGroup(id, selectedSubGroup)
                .then(ok => {
                    if (!errorMessages.includes(ok as string)) {
                        setSubGroupDetails(Object.assign({}, subGroupDetails, {
                            students: subGroupDetails.students.map(student => {
                                if (student.id === id) {
                                    student.subGroupId = selectedSubGroup;
                                }
                                return student;
                            })
                        }));
                    } else {
                        setRequestFailed(true);
                        setFailMessage(ok as string);
                    }
                }).catch(error => {
                    setRequestFailed(true);
                    if ((error as Error).message === 'LOGOUT') history.push('/login');
                    if (errorMessages.includes(error as string)) setFailMessage(error as string);
                });
        }
    };

    const handleSubGroupStudentClick = (id: number) => {
        if (subGroupDetails) {
            Connector.getInstance().changeSubGroup(id, null)
                .then(ok => {
                    if (!errorMessages.includes(ok as string)) {
                        setSubGroupDetails(Object.assign({}, subGroupDetails, {
                            students: subGroupDetails.students.map(student => {
                                if (student.id === id) {
                                    student.subGroupId = null;
                                }
                                return student;
                            })
                        }));
                    } else {
                        setRequestFailed(true);
                        setFailMessage(ok as string);
                    }
                }).catch(error => {
                    setRequestFailed(true);
                    if ((error as Error).message === 'LOGOUT') history.push('/login');
                    if (errorMessages.includes(error as string)) setFailMessage(error as string);
                });
        }
    };

    const handleCreateClick = (): void => {
        setIsCreateModalOpen(true);
    };

    const handleDeleteClick = (): void => {
        setIsDeleteModalOpen(true);
    };

    const handleCreateModalClose = (): void => setIsCreateModalOpen(false);

    const handleDeleteModalClose = (): void  => setIsDeleteModalOpen(false);

    const handleNewSubGroupNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setNewSubGroupName(e.currentTarget.value);
        setNewSubGroup(Object.assign({}, newSubGroup, {
            name: e.currentTarget.value,
        }));
    };

    const handleNewSubGroupNameBlur = () => setNewSubGroupNameError(newSubGroupName === '');

    const handleNewSubGroupNameFocus = () => setNewSubGroupNameError(false);

    const handleRemoveClick = (id: number) => {
        setNewSubGroup(Object.assign({}, newSubGroup, {
            coaches: newSubGroup.coaches.filter(coach => coach.id !== id),
        }));
    };

    const handleNewCoachChange = (e: ChangeEvent<{ name?: string | undefined; value: unknown; }>): void => {
        setSelectedNewCoach(e.target.value as number);
    };

    useEffect(() => {
        if (subGroupDetails)
        {
            const newCoach = subGroupDetails.coaches
                .filter(coach => !newSubGroup.coaches.map(coach => coach.id).includes(coach.id))[0];
            setSelectedNewCoach(newCoach ? newCoach.id : 0);
        }
    }, [newSubGroup.coaches, subGroupDetails]);

    const handleAddClick = () => {
        if (subGroupDetails) {
            setNewSubGroup(Object.assign({}, newSubGroup, {
                coaches: newSubGroup.coaches
                    .concat(subGroupDetails.coaches.filter(coach => coach.id === selectedNewCoach)),
            }));
        }
    };

    const handleConfirmCreate = () => {
        if (newSubGroup.name && newSubGroup.coaches.length > 0) {
            setIsWorking(true);
            Connector.getInstance().createSubGroup(
                parseInt(location.pathname.replace(/\/group\/([0-9]+)\/subgroups/, '$1')),
                newSubGroup.name,
                newSubGroup.coaches.map(coach => coach.id),
            ).then(ok => {
                if (!errorMessages.includes(ok as string)) {
                    setIsCreateModalOpen(false);
                    setSubGroupDetails(null);
                    setSuccessMessage('SUB_GROUP_CREATE_SUCCESS');
                    fetchSubGroupDetails.current();
                } else {
                    setIsCreateModalOpen(false);
                    setRequestFailed(true);
                    setFailMessage(ok as string);
                }
            }).catch(error => {
                setRequestFailed(true);
                setIsCreateModalOpen(false);
                if ((error as Error).message === 'LOGOUT') history.push('/login');
                if (errorMessages.includes(error as string)) setFailMessage(error as string);
            });
        }
    };

    const handleConfirmDelete = (): void => {
        if (selectedSubGroup) {
            setIsWorking(true);
            Connector.getInstance().deleteSubGroup(selectedSubGroup)
                .then(ok => {
                    if (!errorMessages.includes(ok as string)) {
                        setIsDeleteModalOpen(false);
                        setSubGroupDetails(null);
                        setSuccessMessage('SUB_GROUP_DELETE_SUCCESS');
                        fetchSubGroupDetails.current();
                    } else {
                        setRequestFailed(true);
                        setFailMessage(ok as string);
                        setIsDeleteModalOpen(false);
                    }
                }).catch(error => {
                    setRequestFailed(true);
                    setIsDeleteModalOpen(false);
                    if ((error as Error).message === 'LOGOUT') history.push('/login');
                    if (errorMessages.includes(error as string)) setFailMessage(error as string);
                });
        }
    };

    const renderCreateModal = (): ReactElement => (
        <Modal open={isCreateModalOpen} onClose={handleCreateModalClose}>
            {isWorking || subGroupDetails === null ?
                <div className={classes.modalRoot}>
                    <CircularProgress />
                </div> :
                <div className={classes.modalRoot}>
                    <Typography variant="h4" component="h6">{i18n.t('SUB_GROUP_CREATION_TITLE')}</Typography>
                    <TextField
                        className={classes.newSubGroupName}
                        required
                        name="newSubGroupName"
                        label={i18n.t('SUB_GROUP_NAME_FIELD')}
                        value={newSubGroupName}
                        onChange={handleNewSubGroupNameChange}
                        onBlur={handleNewSubGroupNameBlur}
                        onFocus={handleNewSubGroupNameFocus}
                        error={newSubGroupNameError}
                    />
                    <List className={classes.coachList}>
                        {newSubGroup.coaches.map(coach =>
                            <div className={classes.coachName} key={coach.id}>
                                <Typography>
                                    {coach.firstName} {coach.lastName}
                                </Typography>
                                <IconButton onClick={() => {
                                    handleRemoveClick(coach.id);
                                }}>
                                    <RemoveCircleOutlineOutlined/>
                                </IconButton>
                            </div>
                        )}
                    </List>
                    {selectedNewCoach !== 0 ?
                        <div className={classes.addCoachDiv}>
                            <Select
                                className={classes.coachSelect}
                                value={selectedNewCoach}
                                onChange={handleNewCoachChange}
                            >
                                {subGroupDetails.coaches
                                    .filter(coach => !newSubGroup.coaches.map(coach => coach.id).includes(coach.id))
                                    .map(coach => <MenuItem key={coach.id} value={coach.id}>
                                        {coach.firstName} {coach.lastName}
                                    </MenuItem>)}
                            </Select>
                        
                            <IconButton className={classes.addIcon} onClick={handleAddClick}>
                                <AddCircleOutlineOutlined/>
                            </IconButton>
                        </div> : null }
                    <div className={classes.buttonView}>
                        <Button className={classes.modalButton}
                            variant="contained"
                            color="default"
                            onClick={handleCreateModalClose}>
                            {i18n.t('CANCEL_BUTTON')}
                        </Button>
                        <Button className={classes.modalButton}
                            variant="contained"
                            color="primary"
                            onClick={handleConfirmCreate}>
                            {i18n.t('SUBMIT_BUTTON')}
                        </Button>
                    </div>
                </div>}
        </Modal>
    );

    return (
        <div className={classes.root}>
            {renderCreateModal()}
            <ConfirmModal
                onClose={handleDeleteModalClose}
                isOpen={isDeleteModalOpen}
                message={i18n.t('CONFIRM_DELETE_SUB_GROUP_MESSAGE')}
                onConfirm={handleConfirmDelete}
            />
            <CssBaseline/>
            <AppBar currentRoute={SHOW_ALL_GROUPS_ROUTE} login={login} onBurgerClick={() => {
                setIsMenuOpen(!isMenuOpen);
            }}/>
            <DrawerMenu isOpen={isMenuOpen}/>
            {isWorking || !subGroupDetails ?
                <Container component="main" className={`${classes.content} ${classes.noSubGroupContent}`}>
                    <CircularProgress />
                </Container>
                : subGroupDetails.subGroups.length ?
                    <Container className={classes.content}>
                        <TableContainer className={classes.groupStudents}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{i18n.t('FIRST_NAME_FIELD')}</TableCell>
                                        <TableCell>{i18n.t('LAST_NAME_FIELD')}</TableCell>
                                        <TableCell>{i18n.t('FORM_BIRTH_DATE')}</TableCell>
                                        <TableCell>{i18n.t('CURRENT_SUB_GROUP')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {subGroupDetails.students
                                        .filter(student =>
                                            !student.subGroupId || student.subGroupId !== selectedSubGroup)
                                        .sort((a, b) =>
                                            a.lastName < b.lastName
                                                ? - 1
                                                : a.lastName > b.lastName
                                                    ? 1 : 0)
                                        .map(student =>
                                            <TableRow key={student.id} onClick={() => {
                                                handleGroupStudentClick(student.id);
                                            }}>
                                                <TableCell>{student.firstName}</TableCell>
                                                <TableCell>{student.lastName}</TableCell>
                                                <TableCell>
                                                    {moment(student.birthDate).format('DD/MM/YYYY')}
                                                </TableCell>
                                                <TableCell>{
                                                    subGroups.filter(subGroup =>
                                                        subGroup.id === student.subGroupId)[0] ?
                                                        subGroups.filter(subGroup =>
                                                            subGroup.id === student.subGroupId
                                                        )[0].name :
                                                        i18n.t('NONE_M')
                                                }</TableCell>
                                            </TableRow>
                                        )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className={classes.subGroupSelectView}>
                            <Typography variant="h4" component="h6">{subGroupDetails.name}</Typography>
                            <Select value={selectedSubGroup}
                                onChange={handleSubGroupChange}
                                className={classes.subGroupSelect}>
                                {
                                    subGroupDetails.subGroups.map(subGroup =>
                                        <MenuItem className={classes.subGroupSelectItem}
                                            key={subGroup.id}
                                            value={subGroup.id}
                                        >
                                            {subGroup.name}
                                        </MenuItem>)
                                }
                            </Select>
                            <div className={classes.iconRow}>
                                <Tooltip title={i18n.t('CREATE_TOOLTIP')}>
                                    <IconButton onClick={() => {
                                        handleCreateClick();
                                    }}>
                                        <Add />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={i18n.t('DELETE_TOOLTIP')}>
                                    <IconButton onClick={() => {
                                        handleDeleteClick();
                                    }}>
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                        <TableContainer className={classes.subGroupStudents}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{i18n.t('FIRST_NAME_FIELD')}</TableCell>
                                        <TableCell>{i18n.t('LAST_NAME_FIELD')}</TableCell>
                                        <TableCell>{i18n.t('FORM_BIRTH_DATE')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {subGroupDetails.students
                                        .sort((a, b) =>
                                            a.lastName < b.lastName
                                                ? - 1
                                                : a.lastName > b.lastName
                                                    ? 1 : 0)
                                        .filter(student => student.subGroupId === selectedSubGroup)
                                        .map(student =>
                                            
                                            <TableRow key={student.id} onClick={() => {
                                                handleSubGroupStudentClick(student.id);
                                            }}>
                                                <TableCell>{student.firstName}</TableCell>
                                                <TableCell>{student.lastName}</TableCell>
                                                <TableCell>
                                                    {moment(student.birthDate).format('DD/MM/YYYY')}
                                                </TableCell>
                                            </TableRow>
                                        )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Container> :
                    <Container className={`${classes.content} ${classes.noSubGroupContent}`}>
                        <Typography>
                            {i18n.t('NO_SUB_GROUP')}
                        </Typography>
                        <div className={classes.iconRow}>
                            <Tooltip title={i18n.t('CREATE_TOOLTIP')}>
                                <IconButton onClick={() => {
                                    handleCreateClick();
                                }}>
                                    <Add />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </Container>
            }
            <SnackBarMessage isOpen={successMessage !== '' || failMessage !== ''}
                type={requestFailed ? SnackBarType.ERROR : SnackBarType.SUCCESS}
                content={successMessage ? i18n.t(successMessage) : failMessage ? i18n.t(failMessage) : ''}
            />
        </div>
    );
};

const mapStateToProps = (state: AppState): StateProps => {
    const {token, login, isAdmin, isCoach, isSectionManager} = state.login;
    return {token, login, isAdmin, isCoach, isSectionManager};
};

export default withStyles(styles)(withRouter(connect(mapStateToProps, null)(AllSubGroups)));
