import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import {AppBar, DrawerMenu} from '../';
import {CssBaseline} from '@material-ui/core';

const styles = () => ({
    root: {
        display: 'flex',
    },
});

class CoachArea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuOpen: true,
        };
    }
    componentDidMount() {
        if (!this.props.token.access) {
            this.props.history.push('/login');
        }
    }

    handleBurgerClick = () => {
        this.setState({menuOpen: !this.state.menuOpen});
    }

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                <CssBaseline/>
                <AppBar
                    currentRoute={this.props.location.pathname}
                    login={this.props.login}
                    onBurgerClick={this.handleBurgerClick}/>
                <DrawerMenu
                    isOpen={this.state.menuOpen}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {token, login} = state.login;
    return {token, login};
};

export default withRouter(connect(mapStateToProps, null)(withStyles(styles)(CoachArea)));
