import React, {ChangeEvent, FunctionComponent, ReactNode, useCallback, useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {
    Button,
    Checkbox,
    CircularProgress,
    Container,
    createStyles,
    CssBaseline,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    Link,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Theme,
    Tooltip,
    Typography,
    withStyles
} from '@material-ui/core';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {
    AppState,
    errorMessages,
    Gender,
    SubscriptionDetailsType,
    SubscriptionState,
    Token
} from '../../helpers/types';
import AppBar from '../AppBar';
import {SUBSCRIPTION_DETAILS_ROUTE} from './routes';
import DrawerMenu from '../DrawerMenu';
import {appBar} from '../../styles';
import {Connector} from '../../helpers/Connector';
import i18n from '../../i18n/i18n';
import {KeyboardDatePicker} from '@material-ui/pickers';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';
import {SelectProps} from '@material-ui/core/Select';
import {Add, CheckBoxOutlineBlank} from '@material-ui/icons';
import {SnackBarMessage} from '../../components';
import {SnackBarType} from '../../components/SnackBarMessage';
import moment from 'moment';
import {countries} from '../../helpers/countries.json';
import {Size} from '../../helpers/types';
import {isStudentMajor} from '../../helpers/utils';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    content: {
        flex: 1,
        width: '100%',
        marginTop: appBar.height,
        paddingTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
    },
    paper: {
        padding: theme.spacing(2)
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'row'
    },
    radioItem: {
        flex: 0.5,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    formItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
    },
    formPartTitle: {
        marginTop: '15px',
        marginBottom: '15px',
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: theme.spacing(2),
    },
    stepButtons: {
        marginRight: theme.spacing(2)
    }
});

type StateProps = {
    token: Token;
    login: string;
};

type Props = {
    classes: { [key: string]: string };
} & RouteComponentProps & StateProps;

const SubscriptionDetails: FunctionComponent<Props> = ({classes, history, token, login}: Props) => {
    const [isWorking, setIsWorking] = useState(false);
    const [studentId] = useState(parseInt(history.location.pathname.replace(/^\/subscription\/([0-9]+)$/, '$1')));
    const [subscription, setSubscription] = useState<SubscriptionDetailsType | null>(null);
    const [submitted, setSubmitted] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    // Subscription Fields
    const [firstName, setFirstName] = useState('');
    const [firstNameError, setFirstNameError] = useState(false);

    const [lastName, setLastName] = useState('');
    const [lastNameError, setLastNameError] = useState(false);

    const [isNew, setIsNew] = useState(false);

    const [subscriptionState, setSubscriptionState] = useState<SubscriptionState>(SubscriptionState.SCHEDULED);

    const [birthDate, setBirthDate] = useState(new Date());
    const [birthDateError, setBirthDateError] = useState(false);

    const [birthPlace, setBirthPlace] = useState('');
    const [birthPlaceError, setBirthPlaceError] = useState(false);

    const [gender, setGender] = useState<Gender>(Gender.M);

    const [nationality, setNationality] = useState('');

    const [address, setAddress] = useState('');
    const [addressError, setAddressError] = useState(false);

    const [zipCode, setZipCode] = useState('');
    const [zipCodeError, setZipCodeError] = useState(false);

    const [city, setCity] = useState('');
    const [cityError, setCityError] = useState(false);

    const [country, setCountry] = useState('');
    const [countryError, setCountryError] = useState(false);

    const [shirtSize, setShirtSize] = useState<Size | null>(null);
    const [shirtSizesArray, setShirtSizesArray] = useState<Size[]>([]);

    const [shortSize, setShortSize] = useState<Size | null>(null);
    const [shortSizesArray, setShortSizesArray] = useState<Size[]>([]);

    const [guardianFirstName, setGuardianFirstName] = useState('');
    const [guardianFirstNameError, setGuardianFirstNameError] = useState(false);

    const [guardianLastName, setGuardianLastName] = useState('');
    const [guardianLastNameError, setGuardianLastNameError] = useState(false);

    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState(false);

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);

    const [secondaryPhoneNumber, setSecondaryPhoneNumber] = useState<string>('');
    const [secondaryPhoneNumberError, setSecondaryPhoneNumberError] = useState(false);

    const [secondaryEmail, setSecondaryEmail] = useState<string>('');
    const [secondaryEmailError, setSecondaryEmailError] = useState(false);

    const [nationalityArray, setNationalityArray] = useState<string[]>([]);

    const [isSecondEmailShown, setIsSecondEmailShown] = useState(false);

    const [instagramHandle, setInstagramHandle] = useState('');
    const [school, setSchool] = useState('');
    const [schoolLevel, setSchoolLevel] = useState('');

    // Documents
    const [subscriptionForm, setSubscriptionForm] = useState(false);
    const [rules, setRules] = useState(false);
    const [codeOfConduct, setCodeOfConduct] = useState(false);
    const [guardianCodeOfConduct, setGuardianCodeOfConduct] = useState(false);
    const [idPhoto, setIdPhoto] = useState(false);
    const [asmPdf, setAsmPdf] = useState(false);
    const [medicalCertificate, setMedicalCertificate] = useState(false);
    const [payment, setPayment] = useState(false);
    const [ffnLicense, setFfnLicense] = useState(false);
    const [processDate, setProcessDate] = useState<Date | null>(null);
    const [processDateError, setProcessDateError] = useState(false);

    const [requestFailed, setRequestFailed] = useState(false);
    const [requestSucceeded, setRequestSucceeded] = useState(false);

    const [failMessage, setFailMessage] = useState('');

    useEffect(() => {
        if (!token.access) {
            history.push('/login');
        }
    }, [history, token.access]);

    const formError = (): boolean => firstNameError
        || lastNameError
        || birthDateError
        || birthPlaceError
        || addressError
        || zipCodeError
        || cityError
        || countryError
        || guardianFirstNameError
        || guardianLastNameError
        || phoneNumberError
        || emailError
        || secondaryPhoneNumberError
        || secondaryEmailError;


    const [subscriptionStates] = useState<SubscriptionState[]>([
        SubscriptionState.SCHEDULED,
        SubscriptionState.DONE,
        SubscriptionState.MISSING_DOC,
        SubscriptionState.STARTED]);

    useEffect(() => {
        setNationalityArray(countries);
        fetchDetails.current();
    }, []);

    const isStringValueValid = (value: string): boolean => value === null || value === ''
        || /^[a-zA-Z0-9\u00C0-\u024F\u1E00-\u1EFF \-'.(),]+$/g.test(value);

    const isEmailValid = (value: string | null): boolean =>
        value === null || /^[^@!]+@[^@!]+\.[a-z]{2,64}$/.test(value);

    const isPhoneNumberValid = (value: string | null): boolean =>
        value === null || /^(0|00|\+)?[0-9]{8,20}$/.test(value);

    const handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>): void =>
        setFirstName(e.currentTarget.value);

    const handleFirstNameBlur = useCallback(() => setFirstNameError(!isStringValueValid(firstName)), [firstName]);

    const handleFirstNameFocus = (): void =>
        setFirstNameError(false);

    const handleLastNameChange = (e: ChangeEvent<HTMLInputElement>): void =>
        setLastName(e.currentTarget.value);

    const handleLastNameBlur = useCallback(() =>
        setLastNameError(!isStringValueValid(lastName)), [lastName]);

    const handleLastNameFocus = (): void =>
        setLastNameError(false);

    const handleBirthDateChange = (date: MaterialUiPickersDate | null): void =>
        setBirthDate(date ? new Date(date.format()) : new Date());

    const handleBirthDateFocus = () =>
        setBirthDateError(false);

    const handleBirthPlaceChange = (e: ChangeEvent<HTMLInputElement>): void =>
        setBirthPlace(e.currentTarget.value);

    const handleBirthPlaceBlur = useCallback(() => setBirthPlaceError(!isStringValueValid(birthPlace)), [birthPlace]);

    const handleBirthPlaceFocus = (): void =>
        setBirthPlaceError(false);

    const handleNationalityChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) =>
        setNationality(event.target.value as string);

    const isProcessDateValid = () =>
        !processDate || moment(processDate).isValid();

    const handleProcessDateBlur = (): void =>
        setProcessDateError(!isProcessDateValid());

    const handleProcessDateChange = (date: MaterialUiPickersDate | null): void =>
        setProcessDate(date ? new Date(date.format()) : null);

    const handleProcessDateFocus = (): void =>
        setProcessDateError(false);

    const renderNationality = (value: SelectProps['value']): ReactNode =>
        <Typography>{value ? value as string : i18n.t('NATIONALITY_FIELD')}</Typography>;

    const renderShirtSize = (value: SelectProps['value']): ReactNode =>
        <Typography>
            {value
                ? shirtSizesArray.filter(size => size.id === value)[0].label
                : ''}
        </Typography>;

    const renderShortSize = (value: SelectProps['value']): ReactNode =>
        <Typography>
            {value
                ? shortSizesArray.filter(size => size.id === value)[0].label
                : ''}
        </Typography>;

    const handleGuardianFirstNameChange = (e: ChangeEvent<HTMLInputElement>): void =>
        setGuardianFirstName(e.currentTarget.value);

    const handleGuardianFirstNameBlur = useCallback(() =>
        setGuardianFirstNameError(!isStringValueValid(guardianFirstName)), [guardianFirstName]);

    const handleGuardianFirstNameFocus = (): void =>
        setGuardianFirstNameError(false);

    const handleGuardianLastNameChange = (e: ChangeEvent<HTMLInputElement>): void =>
        setGuardianLastName(e.currentTarget.value);

    const handleGuardianLastNameBlur = useCallback(() =>
        setGuardianLastNameError(!isStringValueValid(guardianLastName)), [guardianLastName]);

    const handleGuardianLastNameFocus = (): void =>
        setGuardianLastNameError(false);

    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>): void =>
        setEmail(e.currentTarget.value);

    const handleEmailBlur = useCallback(() =>
        setEmailError(!isEmailValid(email)), [email]);

    const handleEmailFocus = (): void =>
        setEmailError(false);

    const handlePhoneNumberChange = (e: ChangeEvent<HTMLInputElement>): void =>
        setPhoneNumber(e.currentTarget.value);

    const handlePhoneNumberBlur = useCallback(() =>
        setPhoneNumberError(!isPhoneNumberValid(phoneNumber)), [phoneNumber]);

    const handlePhoneNumberFocus = (): void =>
        setPhoneNumberError(false);

    const handleSecondaryEmailChange = (e: ChangeEvent<HTMLInputElement>): void =>
        setSecondaryEmail(e.currentTarget.value);

    const handleSecondaryEmailBlur = useCallback(() =>
        setSecondaryEmailError(secondaryEmail !== ''
            && !isEmailValid(secondaryEmail === '' ? null : secondaryEmail)), [secondaryEmail]);

    const handleSecondaryEmailFocus = (): void =>
        setSecondaryEmailError(false);

    const handleSecondaryPhoneNumberChange = (e: ChangeEvent<HTMLInputElement>): void =>
        setSecondaryPhoneNumber(e.currentTarget.value);

    const handleInstagramHandleChange = (e: ChangeEvent<HTMLInputElement>): void =>
        setInstagramHandle(e.currentTarget.value);

    const handleSchoolChange = (e: ChangeEvent<HTMLInputElement>): void =>
        setSchool(e.currentTarget.value);

    const handleSchoolLevelChangge = (e: ChangeEvent<HTMLInputElement>): void =>
        setSchoolLevel(e.currentTarget.value);

    const handleSecondaryPhoneNumberBlur = useCallback(() =>
        setSecondaryPhoneNumberError(secondaryPhoneNumber !== ''
            && !isPhoneNumberValid(secondaryPhoneNumber === '' ? null : secondaryPhoneNumber)), [secondaryPhoneNumber]);

    const handleSecondaryPhoneNumberFocus = (): void =>
        setSecondaryEmailError(false);

    const handleShowSecondContact = () => setIsSecondEmailShown(true);

    const handleAddressChange = (e: ChangeEvent<HTMLInputElement>): void =>
        setAddress(e.currentTarget.value);

    const handleAddressBlur = useCallback(() =>
        setAddressError(!isStringValueValid(address)), [address]);

    const handleAddressFocus = (): void =>
        setAddressError(false);

    const handleCityChange = (e: ChangeEvent<HTMLInputElement>): void =>
        setCity(e.currentTarget.value);

    const handleCityBlur = useCallback(() =>
        setCityError(!isStringValueValid(city)), [city]);

    const handleCityFocus = (): void =>
        setCityError(false);

    const handleZipCodeChange = (e: ChangeEvent<HTMLInputElement>): void =>
        setZipCode(e.currentTarget.value);

    const handleZipCodeBlur = useCallback(() =>
        setZipCodeError(zipCode !== null && !/^[0-9]{5}$/.test(zipCode)), [zipCode]);

    const handleZipCodeFocus = (): void =>
        setZipCodeError(false);

    const handleCountryChange = (e: ChangeEvent<HTMLInputElement>): void =>
        setCountry(e.currentTarget.value);

    const handleCountryBlur = useCallback(() =>
        setCountryError(!isStringValueValid(country)), [country]);

    const handleCountryFocus = (): void =>
        setCountryError(false);

    const handleIsNewChange = (): void =>
        setIsNew(!isNew);

    const handleSubscriptionFormChange = (): void =>
        setSubscriptionForm(!subscriptionForm);

    const handleRulesChange = (): void =>
        setRules(!rules);

    const handleCodeOfConductChange = (): void =>
        setCodeOfConduct(!codeOfConduct);
    
    const handleGuardianCodeOfConductChange = (): void =>
        setGuardianCodeOfConduct(!guardianCodeOfConduct);

    const handleIdPhotoChange = (): void =>
        setIdPhoto(!idPhoto);

    const handleAsmPdfChange = (): void =>
        setAsmPdf(!asmPdf);

    const handleMedicalCertificateChange = (): void =>
        setMedicalCertificate(!medicalCertificate);

    const handlePaymentChange = (): void =>
        setPayment(!payment);

    const handleFfnLicenseChange = (): void =>
        setFfnLicense(!ffnLicense);

    const handleBirthDateBlur = useCallback((): void =>
        setBirthDateError(!(process.env.REACT_APP_MAX_BIRTH_DATE
                && (moment(birthDate).year() > 1940
                    && moment(birthDate).year() <= parseInt(process.env.REACT_APP_MAX_BIRTH_DATE))))
    , [birthDate]);

    const handleSubscriptionStateChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) =>
        setSubscriptionState(event.target.value as SubscriptionState);

    const handleShirtSizeChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        setShirtSize(shirtSizesArray.filter(size => size.id === event.target.value)[0]);
    };

    const handleShortSizeChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        setShortSize(shortSizesArray.filter(size => size.id === event.target.value)[0]);
    };

    const renderSubscriptionState = (value: SelectProps['value']): ReactNode =>
        <Typography>
            {value ? i18n.t(`SUBSCRIPTION_${value as string}`) : i18n.t('SUBSCRIPTION_STATE_TITLE')}
        </Typography>;

    const handleBack = (): void => history.goBack();

    useEffect(() => {
        setRequestFailed(false);
        setRequestSucceeded(false);
        setFailMessage('');
        if (subscription && submitted) {
            Connector.getInstance()
                .updateSubscription(studentId, subscription)
                .then((ok: boolean | string) => {
                    if (!errorMessages.includes(ok as string))
                        history.goBack();
                    else {
                        setRequestFailed(true);
                        setFailMessage(ok as string);
                    }
                })
                .catch((e: Error): void => {
                    e.message === 'LOGOUT' ? history.push('/login') : console.warn(e);
                });
        }
    }, [subscription, submitted, history, studentId]);

    const handleSubmit = (): void => {
        if (subscription) {
            setSubscription(Object.assign({}, subscription, {
                firstName,
                lastName,
                isNew,
                subscriptionState,
                birthDate,
                birthPlace,
                gender,
                nationality,
                address,
                zipCode,
                city,
                country,
                shirtSize,
                shortSize,
                guardianFirstName,
                guardianLastName,
                guardianPhoneNumber: phoneNumber,
                guardianEmail: email,
                guardianSecondaryPhoneNumber: secondaryPhoneNumber === '' ? null : secondaryPhoneNumber,
                guardianSecondaryEmail: secondaryEmail === '' ? null : secondaryEmail,
                instagramHandle,
                school,
                schoolLevel,
                subscriptionForm,
                rules,
                idPhoto,
                asmPdf,
                medicalCertificate,
                payment,
                ffnLicense,
                codeOfConduct,
                guardianCodeOfConduct,
                processDate: processDate ? processDate : null,
            }));
            setSubmitted(true);
        }
    };

    useEffect(() => {
        if (!isWorking) {
            handleFirstNameBlur();
            handleLastNameBlur();
            handleAddressBlur();
            handleZipCodeBlur();
            handleCityBlur();
            handleCountryBlur();
            handleGuardianFirstNameBlur();
            handleGuardianLastNameBlur();
            handlePhoneNumberBlur();
            handleEmailBlur();
            handleSecondaryEmailBlur();
            handleSecondaryPhoneNumberBlur();
            handleBirthPlaceBlur();
            handleBirthDateBlur();
        }
    }, [
        isWorking,
        handleFirstNameBlur,
        handleLastNameBlur,
        handleAddressBlur,
        handleZipCodeBlur,
        handleCityBlur,
        handleCountryBlur,
        handleGuardianFirstNameBlur,
        handleGuardianLastNameBlur,
        handlePhoneNumberBlur,
        handleEmailBlur,
        handleSecondaryEmailBlur,
        handleSecondaryPhoneNumberBlur,
        handleBirthPlaceBlur,
        handleBirthDateBlur
    ]);
    const fetchDetails = useRef(() => {
        setIsWorking(true);
        setRequestFailed(false);
        setRequestSucceeded(false);
        setFailMessage('');
        Connector.getInstance().getSubscriptionDetails(studentId)
            .then((sub: SubscriptionDetailsType | string) => {
                if ((sub as SubscriptionDetailsType).firstName) {
                    sub = sub as SubscriptionDetailsType;
                    setSubscription(sub);
                    setFirstName(sub.firstName);
                    setLastName(sub.lastName);
                    setIsNew(sub.isNew);
                    setSubscriptionState(sub.subscriptionState);
                    setBirthDate(sub.birthDate);
                    setGender(sub.gender);
                    setNationality(sub.nationality);
                    setAddress(sub.address);
                    setZipCode(sub.zipCode);
                    setCity(sub.city);
                    setCountry(sub.country);
                    setShirtSize(sub.shirtSize);
                    setShortSize(sub.shortSize);
                    setGuardianFirstName(sub.guardianFirstName);
                    setGuardianLastName(sub.guardianLastName);
                    setPhoneNumber(sub.guardianPhoneNumber);
                    setEmail(sub.guardianEmail);
                    setSecondaryPhoneNumber(sub.guardianSecondaryPhoneNumber || '');
                    setSecondaryEmail(sub.guardianSecondaryEmail || '');
                    setIsSecondEmailShown(sub.guardianSecondaryEmail !== null
                        || sub.guardianSecondaryPhoneNumber !== null);
                    setInstagramHandle(sub.instagramHandle);
                    setSchool(sub.school);
                    setSchoolLevel(sub.schoolLevel);
                    setBirthPlace(sub.birthPlace ?? '');
                    setRules(sub.rules);
                    setIdPhoto(sub.idPhoto);
                    setAsmPdf(sub.asmPdf);
                    setMedicalCertificate(sub.medicalCertificate);
                    setPayment(sub.payment);
                    setFfnLicense(sub.ffnLicense);
                    setGuardianCodeOfConduct(sub.guardianCodeOfConduct);
                    setCodeOfConduct(sub.codeOfConduct);
                    setProcessDate(sub.processDate);
                    setSubscriptionForm(sub.subscriptionForm);
                    setIsWorking(false);

                    Connector.getInstance().getShirtSizes()
                        .then(shirtSizes => {
                            if (!errorMessages.includes(shirtSizes as string)) {
                                setShirtSizesArray(shirtSizes as Size[]);
                            }
                        });
                    Connector.getInstance().getShortSizes()
                        .then(shortSizes => {
                            if (!errorMessages.includes(shortSizes as string)) {
                                setShortSizesArray(shortSizes as Size[]);
                            }
                        });
                } else {
                    setRequestFailed(true);
                    setFailMessage(sub as string);
                }
            })
            .catch((e: Error): void => {
                e.message === 'LOGOUT' ? history.push('/login') : console.warn(e);
            });
    });

    const handleReSend = (): void => {
        setRequestSucceeded(false);
        setRequestFailed(false);
        setFailMessage('');
        Connector.getInstance().sendSubEmail(studentId, {email, secondaryEmail, isNew})
            .then((ok: boolean | string) => {
                if (!errorMessages.includes(ok as string))
                    setRequestSucceeded(true);
                else {
                    setRequestFailed(true);
                    setFailMessage(ok as string);
                }
            });
    };

    const handleSendInvoice = (): void => {
        setRequestSucceeded(false);
        setRequestFailed(false);
        setFailMessage('');
        if (subscription?.id) {
            Connector.getInstance().sendInvoice(subscription.id, {email, secondaryEmail})
                .then((ok: boolean |string) => {
                    if (!errorMessages.includes(ok as string))
                        setRequestSucceeded(true);
                    else {
                        setRequestFailed(true);
                        setFailMessage(ok as string);
                    }
                });
        }
    };

    return <div className={classes.root}>
        <CssBaseline/>
        <AppBar login={login} currentRoute={SUBSCRIPTION_DETAILS_ROUTE} onBurgerClick={() => {
            setIsMenuOpen(!isMenuOpen);
        }}/>
        <DrawerMenu isOpen={isMenuOpen}/>
        <Container component="main" className={classes.content}>
            {subscription === null ? <CircularProgress/> :
                <Paper className={classes.paper}>
                    <Typography component="h3" variant="h6" className={classes.formPartTitle}>
                        {i18n.t('STUDENT_INFO_TITLE')}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="fname"
                                name="firstName"
                                required
                                fullWidth
                                id="firstName"
                                label={i18n.t('FIRST_NAME_FIELD')}
                                value={firstName}
                                onChange={handleFirstNameChange}
                                onBlur={handleFirstNameBlur}
                                error={firstNameError}
                                onFocus={handleFirstNameFocus}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="lname"
                                name="lastName"
                                required
                                fullWidth
                                id="lastName"
                                label={i18n.t('LAST_NAME_FIELD')}
                                value={lastName}
                                onChange={handleLastNameChange}
                                onBlur={handleLastNameBlur}
                                error={lastNameError}
                                onFocus={handleLastNameFocus}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <RadioGroup
                                aria-label={i18n.t('FORM_GENDER_TITLE')}
                                name="gender"
                                value={gender}
                                className={classes.radioGroup}
                                onChange={(e: ChangeEvent<unknown>,
                                    value: string) =>
                                    setGender(value === 'M' ? Gender.M : Gender.F)}
                            >
                                <FormControlLabel
                                    className={classes.radioItem}
                                    value="M"
                                    control={<Radio/>}
                                    label={i18n.t('GENDER_RADIO_M')}
                                />

                                <FormControlLabel
                                    className={classes.radioItem}
                                    value="F"
                                    control={<Radio/>}
                                    label={i18n.t('GENDER_RADIO_F')}
                                />
                            </RadioGroup>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            {shirtSizesArray.length
                                ? <FormControl fullWidth>
                                    <InputLabel>{i18n.t('SHIRT_SIZE_FIELD')}</InputLabel>
                                    <Select
                                        fullWidth
                                        value={shirtSize ? shirtSize.id : ''}
                                        name="shirtSize"
                                        id="shirtSize"
                                        displayEmpty
                                        onChange={handleShirtSizeChange}
                                        renderValue={renderShirtSize}
                                    >
                                        {shirtSizesArray.map(shirtSize =>
                                            <MenuItem key={shirtSize.id.toString()}
                                                value={shirtSize.id}>{shirtSize.label}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl> : null
                            }
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {shortSizesArray.length
                                ? <FormControl fullWidth>
                                    <InputLabel>{i18n.t('SHORT_SIZE_FIELD')}</InputLabel>
                                    <Select
                                        fullWidth
                                        value={shortSize ? shortSize.id : ''}
                                        name="shortSize"
                                        id="shortSize"
                                        displayEmpty
                                        onChange={handleShortSizeChange}
                                        renderValue={renderShortSize}
                                    >
                                        {shortSizesArray.map(shortSize =>
                                            <MenuItem key={shortSize.id.toString()}
                                                value={shortSize.id}>{shortSize.label}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl> : null
                            }
                        </Grid>


                        <Grid item xs={12} sm={6}>
                            <KeyboardDatePicker
                                required
                                fullWidth
                                id="birthDate"
                                label={i18n.t('FORM_BIRTH_DATE')}
                                value={birthDate}
                                onChange={handleBirthDateChange}
                                error={birthDateError}
                                format="DD/MM/YYYY"
                                onFocus={handleBirthDateFocus}
                                onBlur={handleBirthDateBlur}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.formItem}>
                            <FormControl fullWidth>
                                <InputLabel>{i18n.t('NATIONALITY_FIELD')}</InputLabel>
                                <Select
                                    fullWidth
                                    value={nationality}
                                    onChange={handleNationalityChange}
                                    name="nationality"
                                    id="nationality"
                                    displayEmpty
                                    renderValue={renderNationality}
                                >
                                    {nationalityArray ? nationalityArray.map((country,
                                        index) =>
                                        <MenuItem key={index.toString()} value={country}>{country}</MenuItem>) : null}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.formItem}>
                            <TextField
                                autoComplete="birthPlace"
                                name="birthPlace"
                                fullWidth
                                id="birthPlace"
                                label={i18n.t('BIRTH_PLACE')}
                                value={birthPlace}
                                onChange={handleBirthPlaceChange}
                                onBlur={handleBirthPlaceBlur}
                                error={birthPlaceError}
                                onFocus={handleBirthPlaceFocus}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.formItem}>
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.formItem}>
                            <TextField
                                autoComplete="school"
                                name="school"
                                fullWidth
                                id="school"
                                label={i18n.t('SCHOOL_FIELD')}
                                value={school}
                                onChange={handleSchoolChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.formItem}>
                            <TextField
                                autoComplete="schoolLevel"
                                name="schoolLevel"
                                fullWidth
                                id="schoolLevel"
                                label={i18n.t('SCHOOL_LEVEL_FIELD')}
                                value={schoolLevel}
                                onChange={handleSchoolLevelChangge}
                            />
                        </Grid>
                    </Grid>
                    <Typography component="h3" variant="h6" className={classes.formPartTitle}>
                        {i18n.t('STUDENT_CONTACT_TITLE')}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="fname"
                                name="guardianFirstName"
                                fullWidth
                                id="guardianFirstName"
                                label={i18n.t('FIRST_NAME_FIELD')}
                                value={guardianFirstName}
                                onChange={handleGuardianFirstNameChange}
                                error={guardianFirstNameError}
                                onBlur={handleGuardianFirstNameBlur}
                                onFocus={handleGuardianFirstNameFocus}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="lname"
                                name="guardianLastName"
                                fullWidth
                                id="guardianLastName"
                                label={i18n.t('LAST_NAME_FIELD')}
                                value={guardianLastName}
                                onChange={handleGuardianLastNameChange}
                                error={guardianLastNameError}
                                onBlur={handleGuardianLastNameBlur}
                                onFocus={handleGuardianLastNameFocus}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="email"
                                name="email"
                                fullWidth
                                id="email"
                                label={i18n.t('EMAIL_FIELD')}
                                value={email}
                                onChange={handleEmailChange}
                                error={emailError}
                                onBlur={handleEmailBlur}
                                onFocus={handleEmailFocus}
                            />
                        </Grid>
                        <Grid item xs={isSecondEmailShown ? 12 : 11} sm={isSecondEmailShown ? 6 : 5}>
                            <TextField
                                autoComplete="phonenumber"
                                name="phoneNumber"
                                fullWidth
                                id="phoneNumber"
                                label={i18n.t('PHONE_NUMBER_FIELD')}
                                value={phoneNumber}
                                onChange={handlePhoneNumberChange}
                                error={phoneNumberError}
                                onBlur={handlePhoneNumberBlur}
                                onFocus={handlePhoneNumberFocus}
                            />
                        </Grid>
                        {isSecondEmailShown ? (
                            <React.Fragment>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        autoComplete="email"
                                        name="secondEmail"
                                        fullWidth
                                        id="secondEmail"
                                        label={i18n.t('EMAIL_FIELD')}
                                        value={secondaryEmail}
                                        onChange={handleSecondaryEmailChange}
                                        error={secondaryEmailError}
                                        onBlur={handleSecondaryEmailBlur}
                                        onFocus={handleSecondaryEmailFocus}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="secondPhoneNumber"
                                        fullWidth
                                        id="secondPhoneNumber"
                                        label={i18n.t('PHONE_NUMBER_FIELD')}
                                        value={secondaryPhoneNumber}
                                        onChange={handleSecondaryPhoneNumberChange}
                                        error={secondaryPhoneNumberError}
                                        onBlur={handleSecondaryPhoneNumberBlur}
                                        onFocus={handleSecondaryPhoneNumberFocus}
                                    />
                                </Grid>
                            </React.Fragment>
                        ) : (
                            <Grid item xs={1} sm={1}>
                                <Tooltip title={i18n.t('ADD_CONTACT_TOOLTIP')} placement="right">
                                    <IconButton onClick={handleShowSecondContact}>
                                        <Add/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        )}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="instagramHandle"
                                fullWidth
                                id="instagramHandle"
                                label={i18n.t('INSTAGRAM_HANDLE_FIELD')}
                                value={instagramHandle}
                                onChange={handleInstagramHandleChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Typography component="h3" variant="h6" className={classes.formPartTitle}>
                            {i18n.t('STUDENT_ADDRESS_TITLE')}
                        </Typography>
                        <Grid item xs={12}>
                            <TextField
                                name="addressLine1"
                                fullWidth
                                id="addressLine1"
                                label={i18n.t('ADDRESS_FIELD')}
                                value={address}
                                onChange={handleAddressChange}
                                onBlur={handleAddressBlur}
                                error={addressError}
                                onFocus={handleAddressFocus}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="city"
                                fullWidth
                                id="city"
                                label={i18n.t('CITY_FIELD')}
                                value={city}
                                onChange={handleCityChange}
                                onBlur={handleCityBlur}
                                error={cityError}
                                onFocus={handleCityFocus}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                name="zipCode"
                                fullWidth
                                id="zipCode"
                                label={i18n.t('ZIPCODE_FIELD')}
                                value={zipCode}
                                onChange={handleZipCodeChange}
                                onBlur={handleZipCodeBlur}
                                error={zipCodeError}
                                onFocus={handleZipCodeFocus}
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <TextField
                                name="country"
                                fullWidth
                                id="country"
                                label={i18n.t('COUNTRY_FIELD')}
                                value={country}
                                onChange={handleCountryChange}
                                onBlur={handleCountryBlur}
                                error={countryError}
                                onFocus={handleCountryFocus}
                            />
                        </Grid>
                    </Grid>
                    <Typography component="h3" variant="h6" className={classes.formPartTitle}>
                        {i18n.t('SUBSCRIPTION_STATE_TITLE')}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={<Checkbox checked={isNew} value={isNew}
                                    icon={<CheckBoxOutlineBlank color="primary"/>}
                                    color="primary" onChange={handleIsNewChange}/>}
                                label={i18n.t('IS_NEW_LABEL')}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Typography>{i18n.t('SUBSCRIPTION_STATE_LABEL')}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Select
                                fullWidth
                                value={subscriptionState}
                                onChange={handleSubscriptionStateChange}
                                name="subscriptionState"
                                id="subscriptionState"
                                renderValue={renderSubscriptionState}
                            >
                                {subscriptionStates ? subscriptionStates.map((state,
                                    index) =>
                                    <MenuItem key={index.toString()}
                                        value={state}>{i18n.t(`SUBSCRIPTION_${state}`)}</MenuItem>) : null}
                            </Select>
                        </Grid>
                    </Grid>
                    <Typography component="h3" variant="h6" className={classes.formPartTitle}>
                        {i18n.t('DOCUMENTS_STATE')}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={3}>
                            <FormControlLabel
                                control={<Checkbox checked={subscriptionForm} value={subscriptionForm}
                                    icon={<CheckBoxOutlineBlank color="primary"/>}
                                    color="primary" onChange={handleSubscriptionFormChange}/>}
                                label={i18n.t('SUBSCRIPTION_FORM_LABEL')}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FormControlLabel
                                control={<Checkbox checked={asmPdf} value={asmPdf}
                                    icon={<CheckBoxOutlineBlank color="primary"/>}
                                    color="primary" onChange={handleAsmPdfChange}/>}
                                label={i18n.t('ASM_SUBSCRIPTION_FORM')}
                            />
                        </Grid>                        
                        <Grid item xs={6} sm={3}>
                            <FormControlLabel
                                control={<Checkbox checked={rules} value={rules}
                                    icon={<CheckBoxOutlineBlank color="primary"/>}
                                    color="primary" onChange={handleRulesChange}/>}
                                label={i18n.t('RULES_LABEL')}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FormControlLabel
                                control={<Checkbox checked={codeOfConduct} value={codeOfConduct}
                                    icon={<CheckBoxOutlineBlank color="primary"/>}
                                    color="primary" onChange={handleCodeOfConductChange}/>}
                                label={i18n.t('CODE_OF_CONDUCT_LABEL')}
                            />
                        </Grid>
                        {isStudentMajor(birthDate) ? null :
                            <Grid item xs={6} sm={3}>
                                <FormControlLabel
                                    control={<Checkbox checked={guardianCodeOfConduct} value={guardianCodeOfConduct}
                                        icon={<CheckBoxOutlineBlank color="primary"/>}
                                        color="primary" onChange={handleGuardianCodeOfConductChange}/>}
                                    label={i18n.t('PARENT_CODE_OF_CONDUCT_LABEL')}
                                />
                            </Grid>}
                        <Grid item xs={6} sm={3}>
                            <FormControlLabel
                                control={<Checkbox checked={idPhoto} value={idPhoto}
                                    icon={<CheckBoxOutlineBlank color="primary"/>}
                                    color="primary" onChange={handleIdPhotoChange}/>}
                                label={i18n.t('ID_PHOTO_LABEL')}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FormControlLabel
                                control={<Checkbox checked={medicalCertificate} value={medicalCertificate}
                                    icon={<CheckBoxOutlineBlank color="primary"/>}
                                    color="primary" onChange={handleMedicalCertificateChange}/>}
                                label={i18n.t('MEDICAL_CERTIFICATE_LABEL')}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FormControlLabel
                                control={<Checkbox checked={payment} value={payment}
                                    icon={<CheckBoxOutlineBlank color="primary"/>}
                                    color="primary" onChange={handlePaymentChange}/>}
                                label={i18n.t('PAYMENT_LABEL')}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FormControlLabel
                                control={<Checkbox checked={ffnLicense} value={ffnLicense}
                                    icon={<CheckBoxOutlineBlank color="primary"/>}
                                    color="primary" onChange={handleFfnLicenseChange}/>}
                                label={i18n.t('FFN_LICENSE_LABEL')}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <KeyboardDatePicker
                                fullWidth
                                id="processDate"
                                label={i18n.t('PROCESS_DATE_LABEL')}
                                value={processDate}
                                onChange={handleProcessDateChange}
                                error={processDateError}
                                format="DD/MM/YYYY"
                                onFocus={handleProcessDateFocus}
                                onBlur={handleProcessDateBlur}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} className={classes.buttonContainer}>
                                <Link
                                    target="_blank"
                                    download="Formulaire d'inscription ASM Natation"
                                    href={
                                        `${process.env.REACT_APP_BACKEND_URL}/subscription/${
                                            subscription.id}/Formulaire d'inscription ASM Natation`}>
                                    {i18n.t('SUBSCRIPTION_FORM')}
                                </Link>
                                <Link
                                    target="_blank"
                                    href={`${process.env.REACT_APP_BACKEND_URL}/subscription/${
                                        subscription.id}/Demande d'admission ASM`}>
                                    {i18n.t('ASM_SUBSCRIPTION_FORM')}
                                </Link>
                                <Link
                                    target="_blank"
                                    href={`${process.env.REACT_APP_BACKEND_URL
                                    }/group/Horaires Natation 2024-2025`}>
                                    {i18n.t('SCHEDULE_DOCUMENT')}
                                </Link>
                                <Link
                                    target="_blank"
                                    download="Attestation d'inscription"
                                    href={
                                        `${process.env.REACT_APP_BACKEND_URL}/subscription/${
                                            subscription.id}/attestation/download`}>
                                    {i18n.t('INVOICE')}
                                </Link>
                                <Link
                                    target="_blank"
                                    href={`${process.env.REACT_APP_BACKEND_URL}/subscription/${
                                        isStudentMajor(birthDate)
                                            ? 'Formulaire de licence FFN - MAJEURS'
                                            : 'Formulaire de licence FFN - MINEURS'
                                    }`}>
                                    {i18n.t(isStudentMajor(birthDate)
                                        ? 'FFN_LICENCE_MAJOR'
                                        : 'FFN_LICENCE_MINOR')}
                                </Link>
                            </Grid>
                            <Grid item xs={12} sm={12} className={classes.buttonContainer}>
                                <Button
                                    variant="contained"
                                    onClick={handleReSend}
                                    className={classes.stepButtons}
                                >
                                    {i18n.t('RESEND_MAIL')}
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={handleSendInvoice}
                                    className={classes.stepButtons}
                                >
                                    {i18n.t('SEND_INVOICE')}
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={handleBack}
                                    className={classes.stepButtons}
                                >
                                    {i18n.t('BACK_BUTTON')}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmit}
                                    className={classes.stepButtons}
                                    disabled={(() => formError())()}
                                >
                                    {i18n.t('SUBMIT_BUTTON')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>}
            <SnackBarMessage
                content={requestSucceeded
                    ? i18n.t('SUB_MAIL_SENT')
                    : i18n.t(failMessage)} type={requestSucceeded
                    ? SnackBarType.SUCCESS : SnackBarType.ERROR
                } isOpen={requestSucceeded || requestFailed}/>
        </Container>
    </div>;
};

const mapStateToProps = (state: AppState): StateProps => {
    const {token, login} = state.login;
    return {token, login};
};

export default withStyles(styles)(connect(mapStateToProps, null)(withRouter(SubscriptionDetails)));
