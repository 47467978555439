import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Connector} from '../helpers/Connector';
import {Grid, List, ListItem} from '@material-ui/core';
import {errorMessages} from '../helpers/types';

const styles = theme => ({
    roleList: {
        display: 'flex',
        flexDirection: 'row',
        maxWidth: '100%',
        overflow: 'scroll',
    },
    roleName: {
        minWidth: '25%',
        marginRight: theme.spacing(1),
        height: '100%',
        minHeight: '100%',
        padding: 'auto',
        textAlign: 'center',
        border: '1px solid black',
        borderRadius: 4,
    },
});

class RoleList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            roles: [],
            selectedRoles: [],
        };
    }

    componentDidMount() {
        const connector = Connector.getInstance();
        connector.getRoles().then(roles => {
            if (roles && !errorMessages.includes(roles))
                this.setState({roles: roles});
            else
                this.props.onRequestFailed(roles || 'UNKNOWN_ERROR');
        }).catch(e => e.message === 'LOGOUT' && this.props.logout());
    }

    renderRoles = () =>
        this.state.roles.map(role =>
            <ListItem
                button
                key={role.id}
                selected={this.state.selectedRoles.includes(role.id)}
                className={this.props.classes.roleName}
                onClick={() => {
                    this.toggleSelected(role.id);
                }}
            >
                {role.name}
            </ListItem>
        );

    toggleSelected = id => {
        let newSelectedRoles;
        if (this.state.selectedRoles.includes(id)) {
            newSelectedRoles = this.state.selectedRoles.filter(item => id !== item);
        } else {
            newSelectedRoles = this.state.selectedRoles.concat([id]);
        }

        this.setState({selectedRoles: newSelectedRoles});
    };

    getSelectedRoles = () => this.state.selectedRoles;

    render() {
        const {classes} = this.props;
        return (
            <Grid item xs={12} sm={12}>
                <List className={classes.roleList}>
                    {this.renderRoles()}
                </List>
            </Grid>
        );
    }
}

export default withStyles(styles)(RoleList);
