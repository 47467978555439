import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Paper, Typography} from '@material-ui/core';
import i18n from '../i18n/i18n';
import {success} from '../styles';

const styles = theme => ({
    root: {
        display: 'flex',
        backgroundColor: success.background,
        width: '80%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1),
    },
    hidden: {
        display: 'none'
    }
});

class SuccessView extends Component {
    render() {
        const {classes, coach, subscribed, shown, success} = this.props;
        return (
            <Paper className={`${classes.root} ${!shown ? classes.hidden : ''}`}>
                <Typography paragraph>
                    {coach ? i18n.t('CREATED_COACH_EXPLANATION', {
                        firstName: coach.firstName,
                        lastName: coach.lastName
                    }) : null}
                </Typography>
                {subscribed ?
                    <Typography paragraph>
                        {i18n.t('CREATED_SUBSCRIPTION_EXPLANATION')}
                    </Typography> : null}
                {success ? this.props.successExplanation : null}
            </Paper>
        );

    }
}

export default withStyles(styles)(SuccessView);
