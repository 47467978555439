import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import {CssBaseline, Paper, Typography} from '@material-ui/core';
import {AppBar, DrawerMenu} from '..';
import {GroupCreationForm, SnackBarMessage} from '../../components';
import i18n from '../../i18n/i18n';
import {COACH_HOME_ROUTE} from './routes';
import {appBar} from '../../styles';
import {SnackBarType} from '../../components/SnackBarMessage';

const styles = theme => ({
    root: {
        display: 'flex',
    },
    content: {
        marginTop: theme.spacing(3),
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    paper: {
        width: '80%',
        marginTop: appBar.height,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2),
    },
});

class GroupCreation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            requestFailed: false,
            failMessage: '',
            isMenuOpen: false,
        };
    }

    componentDidMount() {
        if (!this.props.token.access) {
            this.props.history.push('/login');
        }
    }

    handleCancelClick = () => {
        this.props.history.push(COACH_HOME_ROUTE);
    };
    handleCreated = groupId => {
        this.props.history.push(`/group/${groupId}`);
    };

    handleLogout = () => this.props.history.push('/login');

    handleRequestFailed = (message) => this.setState({requestFailed: true, failMessage: message});

    render() {
        const {classes, login} = this.props;
        return (
            <div className={classes.root}>
                <CssBaseline/>
                <AppBar login={login} onBurgerClick={() => {
                    this.setState({isMenuOpen: !this.state.isMenuOpen});
                }}/>
                <DrawerMenu isOpen={this.state.isMenuOpen}/>
                <main className={classes.content}>
                    <Paper className={classes.paper}>
                        <Typography component="h3" variant="h6">
                            {i18n.t('GROUP_CREATION_TITLE')}
                        </Typography>
                        <GroupCreationForm
                            onCancelClick={this.handleCancelClick}
                            onCreated={this.handleCreated}
                            logout={this.handleLogout}
                            onRequestFailed={this.handleRequestFailed}
                        />
                    </Paper>
                </main>
                <SnackBarMessage
                    content={i18n.t(this.state.failMessage)}
                    type={SnackBarType.ERROR}
                    isOpen={this.state.requestFailed}/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {token, login} = state.login;
    return {token, login};
};

export default withStyles(styles)(withRouter(connect(mapStateToProps, null)(GroupCreation)));
