import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {FormControl, InputLabel, MenuItem, Select} from '@material-ui/core';
import i18n from '../i18n/i18n';
import {Connector} from '../helpers/Connector';

const styles = () => ({
    formSelect: {
        width: '100%'
    }
});

class CoachSelect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            coachList: [],
            selectedCoach: 0
        };
    }

    componentDidMount() {
        const connector = Connector.getInstance();
        connector.getAllCoaches().then(coaches => {
            if (coaches && !['BAD_REQUEST', 'UNKNOWN_ERROR'].includes(coaches))
                this.setState({coachList: coaches, selectedCoach: coaches[0].id});
            else
                this.props.onRequestFailed(coaches || 'UNKNOWN_ERROR');
        }).catch(e => e.message === 'LOGOUT' && this.props.logout);
    }

    getSelected = () => this.state.coachList.filter(coach => coach.id === this.state.selectedCoach)[0];

    renderCoaches = () =>
        this.state.coachList.map(coach => <MenuItem value={coach.id}
            key={coach.id}>{coach.firstName} {coach.lastName}</MenuItem>);

    handleCoachChange = e => this.setState({selectedCoach: e.target.value});

    render() {
        const {classes} = this.props;
        return (
            <FormControl className={classes.formSelect}>
                <InputLabel htmlFor="maxBirthYear">{i18n.t('COACH_LABEL')}</InputLabel>
                <Select
                    value={this.state.selectedCoach}
                    onChange={this.handleCoachChange}
                    inputProps={{
                        name: 'coach',
                        id: 'coach'
                    }}
                    fullWidth
                >
                    {this.renderCoaches()}
                </Select>
            </FormControl>
        );
    }
}

export default withStyles(styles)(CoachSelect);
