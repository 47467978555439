import React, {FunctionComponent, useEffect, useState} from 'react';
import {createStyles, Theme, withStyles} from '@material-ui/core/styles';
import {appBar} from '../../styles';
import {RouteComponentProps} from 'react-router-dom';
import {
    Button,
    CircularProgress,
    Container,
    CssBaseline,
    Paper,
    Radio,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@material-ui/core';
import AppBar from '../AppBar';
import {SET_NEXT_YEAR_GROUPS} from './routes';
import DrawerMenu from '../DrawerMenu';
import {AppState, errorMessages, Student, Token} from '../../helpers/types';
import {PossibleGroup} from '../../helpers/responseTypes';
import {connect} from 'react-redux';
import moment from 'moment';
import i18n from '../../i18n/i18n';
import {Connector} from '../../helpers/Connector';
import {withRouter} from 'react-router-dom';
import {SnackBarMessage} from '../../components';
import {SnackBarType} from '../../components/SnackBarMessage';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        width: '100%',
    },
    content: {
        flex: 1,
        width: '100%',
        marginTop: appBar.height,
        paddingTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
    },
    groupDetails: {
        flex: 0.8,
        height: '100%',
    },
    possibleNextGroupsView: {
        flex: 0.2,
        display: 'flex',
        flexDirection: 'column',
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'row',
    },
    assignButton: {
        height: 55,
    },
});

type StateProps = {
    token: Token;
    login: string;
}

type Props = {
    classes: { [key: string]: string }
} & RouteComponentProps & StateProps;

type LocationState = {
    id: number;
    students: Student[];
    possibleGroups: PossibleGroup[];
}

const NextYearGroupAssign: FunctionComponent<Props> = ({token, login, classes, location, history}: Props) => {
    const possibleGroupList: PossibleGroup[] = (location.state as LocationState).possibleGroups;

    const [isWorking, setIsWorking] = useState(false);
    const [studentList, setStudentList] = useState<(Student)[]>((() => {
        const students: (Student)[] = [];
        for (const student of (location.state as LocationState).students) {
            students.push(Object.assign({}, student, {
                nextGroupId: possibleGroupList.filter(group => group.name === 'Aucune')[0].id,
            }));
        }

        return students;
    })());
    const [failMessage, setFailMessage] = useState('');
    const [requestFailed, setRequestFailed] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        if (!token.access) {
            history.push('/login');
        }
    }, [history, token.access]);

    const handleSelectedGroupChanged = (studentId: number,
        groupId: number) => {
        setStudentList(studentList.map(student => {
            if (student.id === studentId) {
                student.nextYearGroupId = student.nextYearGroupId === groupId
                    ? possibleGroupList.filter(group => group.name === 'Aucune')[0].id
                    : groupId;
            }

            return student;
        }));
    };

    const assignStudents = () => {
        setIsWorking(true);
        Connector.getInstance().setNextYearGroups((location.state as LocationState).id, studentList)
            .then((ok: boolean | string) => {
                if (errorMessages.includes(ok as string)) {
                    setFailMessage(ok as string);
                    setRequestFailed(true);
                    setIsWorking(false);
                } else {
                    setIsWorking(false);
                    history.goBack();
                }
            }).catch((e: Error): void => {
                e.message === 'LOGOUT' && history.push('/login');
            });
    };

    const renderStudents = () => {
        return (<div>
            <Paper>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{i18n.t('FIRST_NAME_FIELD')}</TableCell>
                            <TableCell>{i18n.t('LAST_NAME_FIELD')}</TableCell>
                            <TableCell>{i18n.t('FORM_BIRTH_DATE')}</TableCell>
                            {possibleGroupList.map(possibleGroup =>
                                <TableCell key={possibleGroup.id}>{possibleGroup.name}</TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {studentList.map(student =>
                            <TableRow key={student.id}>
                                <TableCell>{student.firstName}</TableCell>
                                <TableCell>{student.lastName}</TableCell>
                                <TableCell>{moment(student.Subscription.birthDate).format('DD/MM/YYYY')}</TableCell>
                                {possibleGroupList.map(possibleGroup =>
                                    <TableCell key={possibleGroup.id}>
                                        <Radio value={possibleGroup.id}
                                            checked={student.nextYearGroupId === possibleGroup.id} onClick={() => {
                                                handleSelectedGroupChanged(student.id, possibleGroup.id);
                                            }}/>
                                    </TableCell>
                                )}
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Paper>
        </div>);
    };

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar login={login} currentRoute={SET_NEXT_YEAR_GROUPS} onBurgerClick={() => {
                setIsMenuOpen(!isMenuOpen);
            }}/>
            <DrawerMenu isOpen={isMenuOpen}/>
            {isWorking
                ? <Container component="main" className={classes.content}>
                    <CircularProgress/>
                </Container>
                : <Container component="main" className={classes.content}>
                    {renderStudents()}
                    <Button className={classes.assignButton} variant="contained" color="primary" fullWidth
                        onClick={assignStudents}>
                        {i18n.t('ASSIGN_NEXT_YEAR_GROUP')}
                    </Button>
                </Container>
            }
            <SnackBarMessage content={i18n.t(failMessage)} type={SnackBarType.ERROR} isOpen={requestFailed}/>
        </div>
    );
};

const mapStateToProps = (state: AppState): StateProps => {
    const {token, login} = state.login;
    return {token, login};
};

export default withStyles(styles)(connect(mapStateToProps, null)(withRouter(NextYearGroupAssign)));
