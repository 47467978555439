import {LOGIN_ACTIONS, LoginActions, LoginFailure, LoginRequest, LoginState, LoginSuccess} from './types';

const initialState: LoginState = {
    login: '',
    password: '',
    isAdmin: false,
    isCoach: false,
    isSectionManager: false,
    token: {
        access: '',
        refresh: ''
    },
    roles: [],
    request: {
        requested: false,
        succeeded: false,
        failed: false,
        failMessage: ''
    }
};

export const login = (state = initialState, action: LoginActions): LoginState => {
    switch (action.type) {
    case LOGIN_ACTIONS.LOGIN_REQUEST: {
        const {login, password} = action as LoginRequest;
        return Object.assign({}, state, {
            login: login,
            password: password,
            request: {
                requested: true,
                succeeded: false,
                failed: false,
                failMessage: '',
            },
        });
    }
    case LOGIN_ACTIONS.LOGIN_SUCCESS: {
        const {token, roles} = action as LoginSuccess;
        return Object.assign({}, state, {
            password: '',
            token: {
                access: token.access,
                refresh: token.refresh
            },
            isAdmin: roles.filter(role => role.name === 'ADMIN').length > 0,
            isCoach: roles.filter(role => role.name === 'COACH').length > 0,
            isSectionManager: roles.filter(role => role.name === 'SECTION_MANAGER').length > 0,
            roles: roles,
            request: {
                requested: false,
                succeeded: true,
                failed: false,
                failMessage: ''
            }
        });
    }
    case LOGIN_ACTIONS.LOGIN_FAILURE: {
        const {failMessage} = action as LoginFailure;
        return Object.assign({}, state, {
            request: {
                requested: false,
                succeeded: false,
                failed: true,
                failMessage: failMessage
            }
        });
    }
    case LOGIN_ACTIONS.LOGOUT:
        return initialState;
    default:
        return state;
    }
};