import {
    FormContent,
    SUBSCRIBE_ACTION,
    SubscribeActions,
    SubscriptionFailure,
    SubscriptionRequest,
    SubscriptionSuccess
} from './types';
import {ThunkAction, ThunkDispatch} from 'redux-thunk';
import {Connector} from '../../helpers/Connector';
import {errorMessages} from '../../helpers/types';

export const requestSubscription = (formContent: FormContent): SubscriptionRequest => ({
    type: SUBSCRIBE_ACTION.SUBSCRIPTION_REQUEST,
    formContent,
});

export const subscriptionSuccess = (id: number): SubscriptionSuccess => ({
    type: SUBSCRIBE_ACTION.SUBSCRIPTION_SUCCESS,
    id,
});

export const subscriptionFailure = (failMessage: string): SubscriptionFailure => ({
    type: SUBSCRIBE_ACTION.SUBSCRIPTION_FAILURE,
    failMessage
});

export const subscribe = (
    studentId: number,
    subscriptionId: number,
    formContent: FormContent,
    covidClause: boolean
): ThunkAction<void, unknown, unknown, SubscribeActions> =>
    (dispatch: ThunkDispatch<unknown, unknown, SubscribeActions>): void => {
        const connector = Connector.getInstance();
        // Make sure all elements are here and valid
        if (Object.keys(formContent).length !== 22) {
            dispatch(subscriptionFailure('MISSING_DATA'));
            return;
        }

        dispatch(requestSubscription(formContent));
        try {
            connector.subscribe(studentId, subscriptionId, Object.assign({}, formContent, {covidClause}))
                .then(response => {
                    if (response && !errorMessages.includes(response as string)) {
                        dispatch(subscriptionSuccess(response as number));
                    } else {
                        dispatch(subscriptionFailure('BAD_REQUEST'));
                    }
                });

        } catch (e) {
            dispatch(subscriptionFailure('UNKNOWN_ERROR'));
        }
    };