import React, {FunctionComponent} from 'react';
import {CssBaseline, Grid, IconButton, Paper, Theme, Typography} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import i18n from '../i18n/i18n';
import {Add, Delete} from '@material-ui/icons';
import {Coach} from '../helpers/responseTypes';

const styles = (theme: Theme): object => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(0.5),
        padding: 0,
        flex: 0.3,
        minWidth: '24%'
    },
    title: {
        width: '100%',
        flex: 0.2,
        textAlign: 'center',
    },
    roles: {
        flex: 0.8,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    roleLabels: {
        flex: 0.8,
        height: '100%',
        textAlignVertical: 'center',
        margin: 0,
    },
    icon: {
        flex: 0.2,
    }
});

type Props = {
    classes: { [key: string]: string };
    user: Coach,
    manageRoles: (user: Coach) => void;
    onDeleteClick: (id: number) => void;
}

const UserOverview: FunctionComponent<Props> = ({
    classes,
    user,
    manageRoles,
    onDeleteClick,
}: Props) => {
    const handleAddRoleClick = (user: Coach) => {
        manageRoles(user);
    };

    const handleDeleteClick = (id: number): void => {
        onDeleteClick(id);
    };

    return (
        <Grid item sm={4} xs={3}>
            <React.Fragment>
                <CssBaseline/>
                <Paper className={classes.root}>
                    <div className={classes.testInfo}>
                        <Typography component="h3" variant="h6" className={classes.title}>
                            {user.firstName} {user.lastName}
                        </Typography>
                        <div className={classes.roles}>
                            <Typography paragraph className={classes.roleLabels}>
                                {i18n.t('ROLE_LIST', {
                                    roleList: user.roles.map(role => i18n.t(role.name)).join(', '),
                                })}
                            </Typography>
                            <IconButton className={classes.icon} onClick={() => {
                                handleAddRoleClick(user);
                            }}><Add/></IconButton>
                            <IconButton className={classes.icon} onClick={() => {
                                handleDeleteClick(user.id);
                            }}>
                                <Delete/>
                            </IconButton>
                        </div>
                    </div>
                </Paper>
            </React.Fragment>
        </Grid>
    );
};

export default withStyles(styles)(UserOverview);
