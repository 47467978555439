import React, {FunctionComponent, ReactElement, useEffect, useRef, useState} from 'react';
import {
    Button,
    CircularProgress,
    Container,
    createStyles,
    CssBaseline,
    List,
    ListItem,
    Modal,
    Theme,
    Typography,
    withStyles
} from '@material-ui/core';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import AppBar from '../AppBar';
import {SHOW_ALL_GROUPS_ROUTE} from './routes';
import DrawerMenu from '../DrawerMenu';
import {AppState, errorMessages, Role, Token, User} from '../../helpers/types';
import {connect} from 'react-redux';
import {appBar} from '../../styles';
import {Connector} from '../../helpers/Connector';
import {ConfirmModal, SnackBarMessage, UserOverview} from '../../components';
import i18n from '../../i18n/i18n';
import {SnackBarType} from '../../components/SnackBarMessage';
import {Coach} from '../../helpers/responseTypes';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flex: 1,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    content: {
        marginTop: appBar.height,
        paddingTop: theme.spacing(2),
        display: 'flex',
        flex: 1,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    userItem: {
        width: '25%',
    },
    modalRoot: {
        position: 'absolute',
        width: 500,
        height: 250,
        top: '50%',
        marginTop: -125,
        left: '50%',
        marginLeft: -250,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 4),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        outline: 0,
    },
    modalContent: {
        display: 'flex',
        width: '100%',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    roleList: {
        display: 'flex',
        flex: 0.5,
        flexDirection: 'row',
        width: '100%',
    },
    roleName: {
        flex: 0.5,
        marginRight: theme.spacing(1),
        height: '100%',
        minHeight: '100%',
        padding: 'auto',
        textAlign: 'center',
        border: '1px solid black',
        borderRadius: 4,
    },
    buttonView: {
        width: '100%',
        flex: 0.3,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
});

type StateProps = {
    token: Token;
    login: string;
};

type Props = {
    classes: { [key: string]: string };
} & StateProps & RouteComponentProps;

const AllUsers: FunctionComponent<Props> = ({classes, token, login, history}: Props) => {
    const [users, setUsers] = useState<Coach[]>([]);
    const [isRequestInProgress, setIsRequestInProgress] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState<Coach | null>(null);
    const [roles, setRoles] = useState<Role[]>([]);
    const [selectedUserRoles, setSelectedUserRoles] = useState<Role[]>([]);
    const [userToDelete, setUserToDelete] = useState(0);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [requestFailed, setRequestFailed] = useState(false);
    const [failMessage, setFailMessage] = useState('');
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        if (!token.access) {
            history.push('/login');
        }
    }, [history, token.access]);

    const fetchUsers = useRef(() => {
        setIsRequestInProgress(true);
        setFailMessage('');
        setRequestFailed(false);
        Connector.getInstance().getAllUsers()
            .then((userList) => {
                if (Array.isArray(userList as Coach[])) {
                    setUsers(userList as Coach[]);
                    setIsRequestInProgress(false);
                    Connector.getInstance().getRoles()
                        .then((rolesResponse: Role[] | string | undefined) => {
                            if (Array.isArray(rolesResponse as Role[]))
                                setRoles(rolesResponse as Role[]);
                            else {
                                setRequestFailed(true);
                                setFailMessage(rolesResponse === undefined ? 'UNKNOWN_ERROR' : rolesResponse as string);
                            }
                            setIsRequestInProgress(false);
                        });
                } else {
                    setRequestFailed(true);
                    setFailMessage(userList as string);
                }
            })
            .catch((e: Error): void => {
                e.message === 'LOGOUT' && history.push('/login');
            });
    });

    useEffect(() => {
        fetchUsers.current();
    }, [history]);

    useEffect(() => {
        if (selectedUser) {
            setIsModalOpen(true);
        }
    }, [selectedUser]);

    const manageRoles = (user: User): void => {
        setSelectedUser(user);
        setSelectedUserRoles(user.roles);
    };

    const handleDeleteClick = (id: number): void => {
        setUserToDelete(id);
        setIsConfirmModalOpen(true);
    };

    const renderUserList = (): ReactElement[] =>
        users.map(user => <UserOverview onDeleteClick={handleDeleteClick} manageRoles={manageRoles} key={user.id}
            user={user}/>);
    const handleModalClose = () => {
        setIsModalOpen(false);
        setIsConfirmModalOpen(false);
        setSelectedUser(null);
        setUserToDelete(0);
        setSelectedUserRoles([]);
    };

    const toggleRole = (roleId: number) => {
        if (selectedUser) {
            if (selectedUserRoles.map(selectedUserRoles => selectedUserRoles.id).includes(roleId)) {
                setSelectedUserRoles(selectedUserRoles.filter(role => role.id !== roleId));
            } else {
                setSelectedUserRoles(selectedUserRoles.concat(roles.filter(role => role.id === roleId)));
            }
        }
    };

    const renderRoleList = (): ReactElement[] => roles.map(role =>
        <ListItem
            button
            key={role.id}
            selected={selectedUserRoles.map(selectedUserRole =>
                selectedUserRole.id).includes(role.id)
            }
            className={classes.roleName}
            onClick={() => {
                if (selectedUser)
                    toggleRole(role.id);
            }}
        >
            {i18n.t(role.name)}
        </ListItem>);

    const updateUsers = () => {
        setRequestFailed(false);
        setFailMessage('');
        Connector.getInstance().getAllUsers()
            .then((userList: User[] | string) => {
                if (Array.isArray(userList as User[])) {
                    setUsers(userList as User[]);
                    Connector.getInstance().getRoles()
                        .then((rolesResponse: Role[] | string | undefined) => {
                            if (Array.isArray(rolesResponse as Role[]))
                                setRoles(rolesResponse as Role[]);
                            else {
                                setRequestFailed(true);
                                setFailMessage(rolesResponse === undefined ? 'UNKNOWN_ERROR' : rolesResponse as string);
                            }
                            setIsRequestInProgress(false);
                            handleModalClose();
                        });
                } else {
                    setRequestFailed(true);
                    setFailMessage(userList as string);
                }
            }).catch((e: string): void => {
                e === 'LOGOUT' && history.push('/login');
            });
    };

    const updateRoles = (userId: number,
        roles: Role[]) => {
        setIsRequestInProgress(true);
        setFailMessage('');
        setRequestFailed(false);
        Connector.getInstance().updateUserRoles(userId, roles)
            .then((ok: boolean | string) => {
                if (!errorMessages.includes(ok as string)) {
                    updateUsers();
                } else {
                    setRequestFailed(true);
                    setFailMessage(ok as string);
                }
            })
            .catch((e: string): void => {
                e === 'LOGOUT' && history.push('/login');
            });
    };

    const renderModalContent = () => selectedUser ?
        <div className={classes.modalContent}>
            <Typography variant="h3" component="h6">
                {selectedUser.firstName} {selectedUser.lastName}
            </Typography>
            <List className={classes.roleList}>
                {renderRoleList()}
            </List>

            <div className={classes.buttonView}>
                <Button variant="contained" color="default" onClick={handleModalClose} fullWidth>
                    {i18n.t('CANCEL_BUTTON')}
                </Button>

                <Button variant="contained" color="primary" onClick={() => {
                    if (selectedUser)
                        updateRoles(selectedUser.id, selectedUserRoles);
                }} fullWidth>
                    {i18n.t('SUBMIT_BUTTON')}
                </Button>
            </div>
        </div> : null;

    const renderModal = () => selectedUser ?
        <Modal open={isModalOpen} onClose={handleModalClose}>
            <div className={classes.modalRoot}>
                {isRequestInProgress
                    ? <CircularProgress className={classes.userItem}/>
                    : renderModalContent()}
            </div>
        </Modal> : null;

    const handleConfirmDelete = () => {
        setIsRequestInProgress(true);
        setRequestFailed(false);
        setFailMessage('');
        Connector.getInstance().deleteUser(userToDelete)
            .then((ok: boolean | string) => {
                if (!errorMessages.includes(ok as string)) {
                    fetchUsers.current();
                    handleModalClose();
                } else {
                    setRequestFailed(true);
                    setFailMessage(ok as string);
                }
            })
            .catch((e: Error): void => {
                e.message === 'LOGOUT' && history.push('/login');
            });
    };

    return (
        <div className={classes.root}>
            {isRequestInProgress ? null : <ConfirmModal
                isOpen={isConfirmModalOpen}
                onClose={handleModalClose}
                message={i18n.t('CONFIRM_USER_DELETE')}
                onConfirm={handleConfirmDelete}
            />}
            {renderModal()}
            <CssBaseline/>
            <AppBar currentRoute={SHOW_ALL_GROUPS_ROUTE} login={login} onBurgerClick={() => {
                setIsMenuOpen(!isMenuOpen);
            }}/>
            <DrawerMenu isOpen={isMenuOpen}/>
            <Container component="main" className={classes.content}>
                {isRequestInProgress ? <CircularProgress className={classes.userItem}/> : renderUserList()}
            </Container>
            <SnackBarMessage content={i18n.t(failMessage)} type={SnackBarType.ERROR} isOpen={requestFailed}/>
        </div>
    );
};

const mapStateToProps = (state: AppState): StateProps => {
    const {token, login} = state.login;
    return {token, login};
};

export default connect(mapStateToProps, null)(withRouter(withStyles(styles)(AllUsers)));
