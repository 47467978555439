import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import {AppBar, DrawerMenu} from '..';
import {EDIT_STUDENT_ASSIGNATION_ROUTE} from './routes';

const styles = () => ({
    root: {
        display: 'flex',
    },

});

class EditTestAssign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMenuOpen: false,
        };
    }
    componentDidMount() {
        if (!this.props.token.access) {
            this.props.history.push('/login');
        }
    }

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                <AppBar login={this.props.login} currentRoute={EDIT_STUDENT_ASSIGNATION_ROUTE} onBurgerClick={() => {
                    this.setState({isMenuOpen: !this.state.isMenuOpen});
                }}/>
                <DrawerMenu isOpen={this.state.isMenuOpen}/>
                <div className={classes.content}>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => {
    const {token, login} = state.login;
    return {token, login};
};

export default withStyles(styles)(withRouter(connect(mapStateToProps, null)(EditTestAssign)));
