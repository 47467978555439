import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {ListItem, ListItemText, ListItemIcon} from '@material-ui/core';
import {SettingsApplications} from '@material-ui/icons';
import i18n from '../i18n/i18n';

const styles = () => ({

});

class DrawerPreferencesItem extends Component {
    render() {
        return (
            <ListItem button onClick={this.props.onClick}>
                <ListItemIcon><SettingsApplications/></ListItemIcon>
                <ListItemText primary={i18n.t('SETTINGS_ITEM_TITLE')}/>
            </ListItem>
        );
    }
}

export default withStyles(styles)(DrawerPreferencesItem);
