const isStudentMajor = (birthDate: Date | string): boolean => {
    const todayDate = new Date();
    const majorLimit = new Date();
    majorLimit.setFullYear(todayDate.getFullYear() - 18);
    const majorLimitValue = majorLimit > new Date(birthDate);
    console.log(majorLimitValue);
    return majorLimitValue;
};

export {
    isStudentMajor
};
