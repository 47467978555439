import React, {Component} from 'react';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {
    AllGroups,
    AllSections,
    AllSessions,
    AllSubGroups,
    AllTests,
    AllUsers,
    ChooseSubGroupSession,
    CoachArea,
    EditSession,
    EditTestAssign,
    GroupCreation,
    GroupDetails,
    GroupEdit,
    GuardianCreation,
    Login,
    MailSend,
    NextYearGroupAssign,
    SectionCreation,
    SectionEdit,
    Settings,
    StudentCreation,
    Subscription,
    SubscriptionDetails,
    SubscriptionResolution,
    Subscriptions,
    TestCreation,
    TestEdit,
    TestResults,
    UserCreation,
    NewTimeSlot,
} from './containers/screens';
import theme from './theme';
import {ThemeProvider} from '@material-ui/core/styles';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import * as routes from './containers/screens/routes';
import {connect} from 'react-redux';
import {clear, get} from 'local-storage';
import {loginRequested, loginSuccess} from './redux/login/actions';
import {Connector} from './helpers/Connector';
import NewSession from './containers/screens/NewSession';

class App extends Component {
    componentDidMount() {
        document.body.style.marginTop = '0';
        document.body.style.marginLeft = '0';
        document.body.style.marginRight = '0';
        if (get('access')) {
            this.props.login(get('login'), {access: get('access'), refresh: get('refresh')}, get('roles'));
            const connector = Connector.getInstance();
            connector.token = {
                access: get('access'),
                refresh: get('refresh')
            };
            connector.refreshLogin()
                .then(result => {
                    if (!result) {
                        clear();
                    }
                })
                .catch(() => {
                    clear();
                });
        }
    }

    render() {
        return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <ThemeProvider theme={theme}>
                    <Router>
                        <Switch>
                            <Route exact path="/" component={Login}/>
                            <Route exact path={routes.SUBSCRIBE_ROUTE} component={Subscription}/>
                            <Route exact path={routes.LOGIN_ROUTE} component={Login}/>
                            <Route exact path={routes.COACH_HOME_ROUTE} component={CoachArea}/>
                            <Route exact path={routes.TEST_CREATION_ROUTE} component={TestCreation}/>
                            <Route exact path={routes.TEST_EDIT_ROUTE} component={TestEdit}/>
                            <Route exact path={routes.TEST_SHOW_ALL_ROUTE} component={AllTests}/>
                            <Route exact path={routes.STUDENT_CREATION_ROUTE} component={StudentCreation}/>
                            <Route exact path={routes.EDIT_STUDENT_ASSIGNATION_ROUTE}
                                component={EditTestAssign}/>
                            <Route exact path={routes.START_TEST_ROUTE} component={TestResults}/>
                            <Route exact path={routes.CREATE_GROUP_ROUTE} component={GroupCreation}/>
                            <Route exact path={routes.CREATE_SECTION_ROUTE} component={SectionCreation}/>
                            <Route exact path={routes.CREATE_USER_ROUTE} component={UserCreation}/>
                            <Route exact path={routes.USER_PREFERENCES_ROUTE} component={Settings}/>
                            <Route exact path={routes.SHOW_ALL_GROUPS_ROUTE} component={AllGroups}/>
                            <Route exact path={routes.SHOW_GROUP} component={GroupDetails}/>
                            <Route exact path={routes.SHOW_ALL_USERS_ROUTE} component={AllUsers}/>
                            <Route exact
                                path={routes.SET_NEXT_YEAR_GROUPS} component={NextYearGroupAssign}/>
                            <Route exact
                                path={routes.EDIT_GROUP_ROUTE} component={GroupEdit}/>
                            <Route exact
                                path={routes.SHOW_ALL_SUBSCRIPTIONS_ROUTE} component={Subscriptions}/>
                            <Route exact
                                path={routes.SUBSCRIPTION_DETAILS_ROUTE} component={SubscriptionDetails}
                            />
                            <Route exact
                                path={routes.SUBSCRIPTION_RESOLUTION_ROUTE}
                                component={SubscriptionResolution}
                            />
                            <Route exact path={routes.CREATE_GUARDIAN_ROUTE} component={GuardianCreation}/>
                            <Route exact path={routes.NEW_SESSION_ROUTE} component={NewSession}/>
                            <Route exact path={routes.MAILS_SEND_ROUTE} component={MailSend}/>
                            <Route exact path={routes.SHOW_ALL_SECTIONS_ROUTE} component={AllSections}/>
                            <Route exact path={routes.SECTION_EDIT_ROUTE} component={SectionEdit}/>
                            <Route exact path={routes.SHOW_ALL_SUB_GROUPS_ROUTE} component={AllSubGroups}/>
                            <Route exact path={routes.CHOOSE_SUB_GROUP_SESSION}
                                component={ChooseSubGroupSession}/>
                            <Route exact path={routes.SHOW_ALL_SESSIONS_ROUTE} component={AllSessions}/>
                            <Route exact path={routes.EDIT_SESSION_ROUTE} component={EditSession}/>
                            <Route exact path={routes.CREATE_TIME_SLOT_ROUTE} component={NewTimeSlot}/>
                            <Redirect to="/"/>
                        </Switch>
                    </Router>
                </ThemeProvider>
            </MuiPickersUtilsProvider>
        );
    }
}

const mapStateToProps = state => {
    const {token} = state.login;
    return {token};
};

const mapDispatchToProps = dispatch => ({
    login: (login, token, roles) => {
        dispatch(loginRequested(login, ''));
        dispatch(loginSuccess(token, roles));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
