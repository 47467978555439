import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import {AppBar, DrawerMenu} from '..';
import {TEST_CREATION_ROUTE, TEST_SHOW_ALL_ROUTE} from './routes';
import {Connector} from '../../helpers/Connector';
import {Container, CssBaseline, Grid, Typography} from '@material-ui/core';
import {SnackBarMessage, TestSessionOverview} from '../../components';
import i18n from '../../i18n/i18n';
import moment from 'moment';
import {appBar} from '../../styles';
import {SnackBarType} from '../../components/SnackBarMessage';
import {errorMessages} from '../../helpers/types';

const styles = theme => ({
    root: {
        display: 'flex'
    },
    content: {
        marginTop: appBar.height,
        paddingTop: theme.spacing(2)
    }
});

class AllTests extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tests: [],
            requestFailed: false,
            failMessage: '',
            types: [],
            isMenuOpen: false,
        };
    }

    componentDidMount() {
        if (!this.props.token.access) {
            this.props.history.push('/login');
        }

        this.fetchTests();
    }

    handleLogout = () => {
        this.props.history.push('/login');
    };

    fetchTests = async () => {
        Connector.getInstance().getAllTests()
            .then(tests => {
                if (!tests || tests === 'UNKNOWN_ERROR')
                    this.setState({requestFailed: true, tests: []});
                else
                    this.setState({tests: tests}, () => {
                        Connector.getInstance().getAllTypes()
                            .then(types => {
                                if (types.length && !errorMessages.includes(types)) {
                                    this.setState({types: types});
                                }
                            });
                    });
            })
            .catch(e => e.message === 'LOGOUT' && this.props.history.push('/login'));
    };

    handleEditClicked = id => {
        this.props.history.push(`/test/${id}`, {testId: id});
    };

    handleStartTestClick = id => {
        this.props.history.push(`/test/${id}/start`, {testId: id});
    };

    renderNoTests = () => (
        <div className={this.props.classes.content}>
            <Typography component="h3" variant="h6">{i18n.t('NO_TESTS_TITLE')}</Typography>
            <Link to={TEST_CREATION_ROUTE}>{i18n.t('TEST_CREATION_TITLE')}</Link>
        </div>
    );

    handleRequestFailed = (message) => this.setState({requestFailed: true, failMessage: message});

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                <CssBaseline/>
                <AppBar currentRoute={TEST_SHOW_ALL_ROUTE} login={this.props.login}
                    onBurgerClick={() => this.setState({isMenuOpen: !this.state.isMenuOpen})}/>
                <DrawerMenu isOpen={this.state.isMenuOpen}/>
                <Container component="main" className={classes.content}>
                    <Grid container spacing={2}>
                        {this.state.tests.length === 0
                            ? this.renderNoTests()
                            : this.state.tests.map(({
                                id,
                                scheduledDate,
                                capacity,
                                minBirthYear,
                                maxBirthYear,
                                remaining,
                                typeId,
                            }) => {
                                return (
                                    <Grid item sm={4} xs={3} key={id}>
                                        <TestSessionOverview
                                            logout={this.handleLogout}
                                            testId={id}
                                            onEditClicked={this.handleEditClicked}
                                            date={moment(scheduledDate).format('DD/MM/YYYY')}
                                            time={moment(scheduledDate).format('HH:mm')}
                                            capacity={capacity}
                                            remaining={remaining}
                                            minBirthYear={minBirthYear}
                                            maxBirthYear={maxBirthYear}
                                            onUpdate={this.fetchTests}
                                            onStartTestClick={this.handleStartTestClick}
                                            onRequestFailed={this.handleRequestFailed}
                                            type={this.state.types.find(type => type.id === typeId)}
                                        />
                                    </Grid>
                                );
                            })}
                    </Grid>
                </Container>
                <SnackBarMessage
                    content={i18n.t(this.state.failMessage || 'UNKNOWN_ERROR')}
                    type={SnackBarType.ERROR}
                    isOpen={this.state.requestFailed}/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {token, login} = state.login;
    return {token, login};
};

export default withStyles(styles)(withRouter(connect(mapStateToProps, null)(AllTests)));
