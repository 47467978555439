export const LOGIN_ROUTE = '/login';
export const SUBSCRIBE_ROUTE = '/subscribe';
export const COACH_HOME_ROUTE = '/home';
export const TEST_CREATION_ROUTE = '/test/create';
export const TEST_EDIT_ROUTE = '/test/:id([0-9]+)';
export const TEST_SHOW_ALL_ROUTE = '/test/';
export const STUDENT_CREATION_ROUTE = '/student/';
export const EDIT_STUDENT_ASSIGNATION_ROUTE = '/student/test';
export const START_TEST_ROUTE = '/test/:id([0-9]+)/start';
export const CREATE_GROUP_ROUTE = '/group/create';
export const SHOW_ALL_GROUPS_ROUTE = '/group/';
export const SHOW_GROUP = '/group/:id([0-9]+)';
export const CREATE_SECTION_ROUTE = '/section/create';
export const SHOW_ALL_SECTIONS_ROUTE = '/section/';
export const CREATE_USER_ROUTE = '/user/create';
export const SHOW_ALL_USERS_ROUTE = '/user/';
export const USER_PREFERENCES_ROUTE = '/settings';
export const SET_NEXT_YEAR_GROUPS = '/group/:id([0-9]+)/next';
export const EDIT_GROUP_ROUTE = '/group/:id([0-9]+)/edit';
export const SHOW_ALL_SUBSCRIPTIONS_ROUTE = '/subscription/';
export const SUBSCRIPTION_DETAILS_ROUTE = '/subscription/:id([0-9]+)';
export const SUBSCRIPTION_RESOLUTION_ROUTE = '/subscription/problem/:id([0-9]+)';
export const CREATE_GUARDIAN_ROUTE = '/guardian/new';
export const NEW_SESSION_ROUTE = '/session/new';
export const MAILS_SEND_ROUTE = '/group/:id([0-9]+)/mail';
export const SECTION_EDIT_ROUTE = '/section/:id([0-9]+)';
export const SHOW_ALL_SUB_GROUPS_ROUTE = '/group/:id([0-9]+)/subgroups/';
export const CREATE_SUB_GROUP_ROUTE = '/group/:id([0-9]+)/subgroups/create';
export const CREATE_SESSION_ROUTE = '/session/create';
export const SHOW_ALL_SESSIONS_ROUTE = '/session/';
export const CHOOSE_SUB_GROUP_SESSION = '/group/:id([0-9]+)/subgroup/session/new';
export const EDIT_SESSION_ROUTE = '/session/edit';
export const CREATE_TIME_SLOT_ROUTE = '/timeslot/new';
export const SHOW_ALL_TIME_SLOTS_ROUTE = '/timeslot/';
